import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';
import {Intervenant} from 'src/app/models/intervenant';
import {LiteIntervenant} from 'src/app/models/lite-intervenant';
import {IntervenantService} from 'src/app/services/intervenant.service';
import {Cours} from "../../../models/cours";
import {CoursService} from "../../../services/cours.service";

@Component({
  selector: 'app-cours-change-teacher',
  templateUrl: './cours-change-teacher.component.html',
  styleUrls: ['./cours-change-teacher.component.scss']
})
export class CoursChangeTeacherComponent implements OnInit {

  public onClose: Subject<boolean>;

  currentCours: Cours;

  teachers: LiteIntervenant[];

  selectedTeacher: LiteIntervenant;

  constructor(
    private coursService: CoursService,
    private teacherService: IntervenantService,
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit() {
    this.onClose = new Subject();
    this.teacherService.getAll().subscribe($teachers => {
      this.teachers = this.removeSelectedTeacher($teachers);
    })
  }

  removeSelectedTeacher($teachers: Intervenant[]): Intervenant[] {
    return $teachers.filter(o => {
      return o.id !== this.currentCours.seances[0].animations[0].intervenant.id;
    });
  }

  onEditCoursTeacher() {
    this.coursService.editTeacher(this.currentCours.id, this.selectedTeacher.id).subscribe(cours => {
      this.closeModal();
    });
  }

  closeModal() {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

  onTeacherChange($event: LiteIntervenant) {

  }

}
