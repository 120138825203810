<div class="custom-flex-display">
  <span (mouseover)="showSortButtons()" (mouseleave)="hideSortButtons()">
    {{ columnTranslationKey | translate }}
  </span>
  <ng-container *ngIf="currentFieldToSort == fieldToSort; else sortableIcons">
    <span *ngIf="sortingAsc; else sortDesc">
      <em class="far fa-sort-up fa-fw"></em>
    </span>
    <ng-template #sortDesc>
      <span>
        <em class="far fa-sort-down fa-fw"></em>
      </span>
    </ng-template>
  </ng-container>
  <ng-template #sortableIcons>
    <em *ngIf="sortButtonsVisible; else nbsp" class="far fa-sort fa-fw"></em>
    <ng-template #nbsp><em class="far fa-fw"></em></ng-template>
  </ng-template>
</div>
