import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';
import {Seance} from 'src/app/models/seance';
import {TranslateService} from '@ngx-translate/core';
import {Participant} from 'src/app/models/participant';
import {PricingMethod} from 'src/app/models/enums/pricing-method.enum';
import {Config} from 'src/app/models/config';
import {ConfigService} from 'src/app/services/config.service';
import {InscriptionService} from 'src/app/services/inscription.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-register-trial-student',
  templateUrl: './register-trial-student.component.html',
  styleUrls: ['./register-trial-student.component.scss']
})
export class RegisterTrialStudentComponent implements OnInit {
  onClose: Subject<boolean>;
  currentSeance: Seance;
  currentParticipant: Participant;
  selectedPricingMethod: PricingMethod;
  PricingMethod = PricingMethod;

  config: Config;

  constructor(
    public bsModalRef: BsModalRef,
    private configService: ConfigService,
    private inscriptionService: InscriptionService,
    private toasterService: ToastrService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.configService.getConfig().subscribe(config => this.config = config);
    this.onClose = new Subject();
  }

  onRegisterTrial() {
    this.inscriptionService.registerTrialForNextSeances(this.currentSeance.id, this.currentParticipant.id, this.selectedPricingMethod).subscribe(cours => {
      if (cours != null) {
        this.toasterService.success(this.currentParticipant.lastname + ' ' + this.currentParticipant.firstname + this.translateService.instant('toasters.success.registerTrial'));
        this._closeModal();
      }
    });
  }

  private _closeModal() {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }
}
