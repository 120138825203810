import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

const swal = require('sweetalert2');


@Injectable({
  providedIn: 'root'
})
export class SwalService {

  constructor(private translateService: TranslateService) {
  }

  warning(onConfirm: () => void, titleKey?: string, textKey?: string, cancelKey?: string, confirmKey?: string, params?: any[], onCancel?: () => void) {
    let title = this.translateService.instant(titleKey ? titleKey : 'global.warningModal.title');
    let text = this.translateService.instant(textKey ? textKey : 'global.warningModal.text');
    let cancelText = this.translateService.instant(cancelKey ? cancelKey : 'global.cancel');
    let confirmText = this.translateService.instant(confirmKey ? confirmKey : 'global.warningModal.confirmText');
    if (params) {
      // retranslate the message if some params have to be interpollated
      // (most messages doesn't require parameters to interpollate)
      for (let param of params) {
        // !! each text (title, text, confirmText & cancelText) can only contain 1 param !!
        let paramToAdd = {};
        paramToAdd[param.key] = param.value;
        switch (param.in) {
          case 'title':
            title = this.translateService.instant(titleKey ? titleKey : 'global.warningModal.title', paramToAdd);
            break;
          case 'text':
            text = this.translateService.instant(textKey ? textKey : 'global.warningModal.text', paramToAdd);
            break;
          case 'cancelText':
            cancelText = this.translateService.instant(cancelKey ? cancelKey : 'global.cancel', paramToAdd);
            break;
          case 'confirmText':
            confirmText = this.translateService.instant(confirmKey ? confirmKey : 'global.warningModal.confirmText', paramToAdd);
            break;
        }
      }
    }

    swal.fire({
      title: title,
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: confirmText,
      cancelButtonText: cancelText,
      reverseButtons: true,
      customClass: {
        cancelButton: 'mr-1 mb-1 btn btn-outline-secondary custom-margin-right',
        confirmButton: 'mr-1 btn btn-success mb-1'
      },
      buttonsStyling: false
    }).then(result => {
      if (result.isConfirmed) {
        onConfirm();
      } else {
        if (typeof onCancel === 'function') {
          onCancel();
        }
      }
    });
  }
}
