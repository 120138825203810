import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {CoursType} from 'src/app/models/cours-type';
import {LiteLocation} from 'src/app/models/lite-location';
import {CoursTypeService} from 'src/app/services/cours-type.service';
import {LocationService} from 'src/app/services/location.service';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ConfigService} from 'src/app/services/config.service';
import {Config} from 'src/app/models/config';
import {ToastrService} from 'ngx-toastr';
import {IntervenantService} from "../../../services/intervenant.service";
import {LiteIntervenant} from "../../../models/lite-intervenant";
import {Cours} from "../../../models/cours";
import {CoursService} from "../../../services/cours.service";

@Component({
  selector: 'app-cours-edit',
  templateUrl: './cours-edit.component.html',
  styleUrls: ['./cours-edit.component.scss']
})
export class CoursEditComponent implements OnInit {
  cours: Cours;
  coursCopy: Cours;

  years: number[] = [];

  coursTypes: CoursType[] = [];
  intervenants: LiteIntervenant[] = [];
  locations: LiteLocation[] = [];


  public onClose: Subject<boolean>;

  config: Config;

  constructor(
    private coursService: CoursService,
    private locationService: LocationService,
    private coursTypeService: CoursTypeService,
    private modalService: BsModalService,
    private activatedRoute: ActivatedRoute,
    public bsModalRef: BsModalRef,
    private teacherService: IntervenantService,
    private toasterService: ToastrService,
    private translateService: TranslateService,
    private configService: ConfigService
  ) { }

  ngOnInit() {
    this.configService.getConfig().subscribe(config => this.config = config);
    this.coursTypeService.getAll().subscribe((coursTypes: CoursType[]) => {
      this.coursTypes = coursTypes;
    });

    this.locationService.getAll().subscribe((locations: LiteLocation[]) => {
      this.locations = locations;
    });
    this.teacherService.getAll().subscribe((intervenants: LiteIntervenant[]) => {
      this.intervenants = intervenants;
    });
    this.onClose = new Subject();

    this.coursCopy = JSON.parse(JSON.stringify(this.cours));
    this.initYears();
  }

  onCreate() {
  }

  saveCours() {
    this.coursService
      .saveCours(this.coursCopy)
      .subscribe((cours: Cours) => {
        if (cours != null) {
          this._closeModal();
          this.toasterService.success(this.translateService.instant('toasters.success.coursUpdated') + ' (n°' + this.cours.number + ')');
        }
      });
  }

  _closeModal() {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

  initYears() {
    let currentYear = new Date().getFullYear();

    // get the next 4 years so the user can select it easily
    for (let index = 0; index < 5; index++) {
      this.years.push(currentYear);
      currentYear++;
    }
  }

  isCurrentLocation(location: LiteLocation): string {
    if (this.coursCopy.location.id === location.id) {
      return 'selected';
    }
    return null;
  }

  isCurrentCoursType(coursType: CoursType): string {
    if (this.coursCopy.coursType.id === coursType.id) {
      return 'selected';
    }
    return null;
  }

  isCurrentTeacher(intervenant: LiteIntervenant) {
    if (this.coursCopy.intervenant.id === intervenant.id) {
      return 'selected';
    }
    return null;
  }
}
