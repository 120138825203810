import {Component, OnInit} from '@angular/core';
import {BsModalRef, BsModalService,} from 'ngx-bootstrap/modal';
import {BsLocaleService} from 'ngx-bootstrap/datepicker';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {CoursService} from "../../../services/cours.service";

@Component({
  selector: 'app-cours',
  templateUrl: './cours-archive.component.html',
  styleUrls: ['./cours-archive.component.scss']
})
export class CoursArchiveComponent implements OnInit {

  onClose: Subject<boolean>;
  selectedDate: Date = new Date();

  // datepicker theme for styling
  datepickerConfig = {
    containerClass: 'theme-angle',
    dateInputFormat: 'DD.MM.YYYY'
  };

  constructor(
    private coursService: CoursService,
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private localeService: BsLocaleService,
    private toasterService: ToastrService,
    private translateService: TranslateService
  ) {

  }

  ngOnInit() {
    this.onClose = new Subject();
    this.localeService.use('fr');
  }

  archiveAll() {
    this.coursService.archiveAll(this.selectedDate).subscribe((cours) => {
      this._closeModal();
      if (cours.length == 0) {
        this.toasterService.warning(this.translateService.instant('toasters.warning.noCoursFoundSpecifiedDate'));
      } else {
        this.toasterService.success(cours.length + ' ' + this.translateService.instant('toasters.success.coursSuccessfullyArchived'));
      }
    });
  }

  onDateChange($event: Date) {
    if ($event != null) {
      $event.setMilliseconds(0);
      $event.setSeconds(0);
      $event.setMinutes(0);
      $event.setHours(0);
      this.selectedDate = $event;
    }
  }

  private _closeModal() {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

}
