import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Seance} from 'src/app/models/seance';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {SeanceStatus} from '../models/enums/seance-status.enum';

const API_ROUTE = '/api/seance';

@Injectable({
  providedIn: 'root'
})
export class SeanceService {

  constructor(private readonly http: HttpClient) {
  }

  // sort seances by startDate
  public static sortSeances(seances: Seance[]) {
    seances.sort((a: Seance, b: Seance) => {
      return a.startDate.getTime() - b.startDate.getTime();
    });
  }

  editSeance(seance: Seance) {
    return this.http.put(`${API_ROUTE}/${seance.id}`, seance).pipe(map(Seance.fromJson));
  }

  /**
   * This method can be used without any parameters, or with a startDate AND an endDate.
   */
  getAll(startDate?: Date, endDate?: Date): Observable<Seance[]> {

    let baseReq = `${API_ROUTE}`;
    let params = ``;
    params = params.concat(startDate == null ? `` : `?startDate=${startDate.getTime()}`);
    params = params.concat(endDate == null ? `` : `&endDate=${endDate.getTime()}`);
    return this.http.get(baseReq.concat(params)).pipe(map((values: any) => values.map(Seance.fromJson)));
  }

  setStatus(seanceId: number, status: SeanceStatus) {
    return this.http.patch(`${API_ROUTE}/${seanceId}/setStatus`, status).pipe(map(Seance.fromJson));
  }

  togglePaid(seanceIds: number[]): Observable<Seance[]> {
    return this.http.patch(`${API_ROUTE}/togglePaid`, seanceIds).pipe(map((values: any) => values.map(Seance.fromJson)));
  }

  setStartEndDates(seanceId: number, startDate: Date, endDate: Date): Observable<Seance> {
    let body: any = {
      startDate: startDate.getTime(),
      endDate: endDate.getTime()
    };
    return this.http.patch(`${API_ROUTE}/${seanceId}/setStartEndDates`, body)
      .pipe(map(Seance.fromJson));
  }

  getSeanceById(seanceId: number): Observable<Seance> {
    return this.http.get(`${API_ROUTE}/${seanceId}`)
      .pipe(map(Seance.fromJson));
  }

  getSeancessByLocationFromTo(locationId: number, startDate: Date, endDate: Date, coursId: number): Observable<Seance[]> {
    const coursQueryParam = coursId ? `&coursId=${coursId}` : '';
    return this.http.get(`${API_ROUTE}/fromLocation/${locationId}?startDate=${startDate.getTime()}&endDate=${endDate.getTime()}${coursQueryParam}`)
      .pipe(map((values: any) => values.map(Seance.fromJson)));
  }

  deleteById(seanceId: number) {
    return this.http.delete(`${API_ROUTE}` + '/' + seanceId, {observe: 'response'});
  }

  addParticipation(seanceId: number, participantId: number, isTrial: boolean): Observable<Seance> {
    return this.http.post(`${API_ROUTE}/${seanceId}/participation`, {
      participantId: participantId,
      isTrial: isTrial
    }).pipe(map(Seance.fromJson));
  }

  addAnimation(seanceId: number, intervenantId: number): Observable<Seance> {
    return this.http.post(`${API_ROUTE}/${seanceId}/animation`, intervenantId).pipe(map(Seance.fromJson));
  }

  deleteParticipation(seanceId: number, participationId: number) {
    return this.http.delete(`${API_ROUTE}/${seanceId}/participation/${participationId} `, {observe: 'response'});
  }

  deleteAnimation(seanceId: number, animationId: number) {
    return this.http.delete(`${API_ROUTE}/${seanceId}/animation/${animationId} `, {observe: 'response'});
  }

}
