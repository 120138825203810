<div class="alert alert-info" role="alert">
  <i class="fal fa-info-square"></i>
  {{ 'form.indicatesRequiredFields' | translate }}
</div>
<div class="form-group row">
  <label for="trimester" class="col-xl-4 col-form-label required"
  >{{ 'cours.trimester' | translate }}/{{ 'global.year' | translate }}</label
  >
  <div class="col-xl-2">
    <input
      #trimester="ngModel"
      [(ngModel)]="cours.trimester"
      class="form-control"
      id="trimester"
      name="trimester"
      type="number"
      [placeholder]="'cours.trimester' | translate"
      min="1"
      max="3"
      required
    />
    <div
      *ngIf="trimester.invalid && (trimester.dirty || trimester.touched)"
      class="text-danger"
    >
      {{ 'form.mandatoryField' | translate }}
    </div>
  </div>
  <div class="col-xl-2">
    <select
      #year="ngModel"
      [(ngModel)]="cours.year"
      class="form-control custom-select"
      id="year"
      name="year"
    >
      <option [ngValue]="cours.year" selected>{{ cours.year }} </option>
      <option *ngFor="let year of years" [ngValue]="year">{{ year }}</option>
    </select>
    <div
      *ngIf="year.invalid && (year.dirty || year.touched)"
      class="text-danger"
    >
      {{ 'form.mandatoryField' | translate }}
    </div>
  </div>
  <div class="col-xl-4"></div>
</div>

<div class="form-group row">
  <label class="col-xl-4 col-form-label required" for="location">{{
    'cours.location' | translate
    }}</label>
  <div class="col-xl-8">
    <select
      #location="ngModel"
      [(ngModel)]="cours.location"
      class="form-control custom-select"
      id="location"
      name="location"
    >
      <option *ngFor="let location of locations" [ngValue]="location">{{
        location.name
        }}</option>
    </select>
    <div
      *ngIf="location.invalid && (location.dirty || location.touched)"
      class="text-danger"
    >
      {{ 'form.mandatoryField' | translate }}
    </div>
  </div>
</div>
<div class="form-group row">
  <label class="col-xl-4 col-form-label required" for="coursType">{{
    'cours.type' | translate
    }}</label>
  <div class="col-xl-8">
    <select
      #coursType="ngModel"
      [(ngModel)]="cours.coursType"
      class="form-control custom-select"
      id="coursType"
      name="coursType"
    >
      <option *ngFor="let coursType of coursTypes" [ngValue]="coursType">{{
        coursType.name
        }}</option>
    </select>
    <div
      *ngIf="coursType.invalid && (coursType.dirty || coursType.touched)"
      class="text-danger"
    >
      {{ 'form.mandatoryField' | translate }}
    </div>
  </div>
</div>
<div class="form-group row">
  <label class="col-xl-4 col-form-label required" for="location">Intervenant principal</label>
  <div class="col-xl-8">
    <span *ngIf="teachers.length == 0">
      <em class="fa fa-spin fa-spinner"></em> Chargement
    </span>

    <ng-container *ngIf="teachers.length > 0">
      <select
        #teacher="ngModel"
        [(ngModel)]="cours.intervenant"
        class="form-control custom-select"
        id="teacher"
        name="teacher"
      >
        <option
          *ngFor="let teacher of teachers"
          [ngValue]="teacher"
        >{{teacher.lastname}} {{teacher.firstname}}</option
        >
      </select>
      <div
        *ngIf="teacher.invalid && (teacher.dirty || teacher.touched)"
        class="text-danger"
      >
        {{ 'form.mandatoryField' | translate }}
      </div>
    </ng-container>
  </div>
</div>
<div class="form-group row">
  <label for="nbPlaces" class="col-xl-4 col-form-label required">{{
    'cours.create.nbPlacesMax' | translate
  }}</label>
  <div class="col-xl-2">
    <input
      #nbPlaces="ngModel"
      [(ngModel)]="cours.nbPlaces"
      class="form-control"
      id="nbPlaces"
      name="nbPlaces"
      type="number"
      [placeholder]="'global.number' | translate"
      required
    />
    <div
      *ngIf="nbPlaces.invalid && (nbPlaces.dirty || nbPlaces.touched)"
      class="text-danger"
    >
      {{ 'form.mandatoryField' | translate }}
    </div>
  </div>
  <div class="col-xl-6"></div>
</div>
<div class="form-group row">
  <label class="col-xl-4 col-form-label required" for="nbSeances">{{
    'cours.create.nbSeanceToCreate' | translate
    }}</label>
  <div class="col-xl-2">
    <input
      #nbSeances="ngModel"
      (change)="onNbSeanceChange($event)"
      class="form-control"
      [(ngModel)]="nbSeancesToCreate"
      id="nbSeances"
      type="number"
      [placeholder]="'global.number' | translate"
      min="1"
      required
      name="nbSeances"
    />
    <div
      *ngIf="nbSeances.invalid && (nbSeances.dirty || nbSeances.touched)"
      class="text-danger"
    >
      {{ 'form.mandatoryField' | translate }}
    </div>
  </div>
  <div class="col-xl-6"></div>
</div>
<div *ngFor="let pricing of cours.pricings" class="form-group row">
  <label for="normalPrice" class="col-xl-4 col-form-label required"
  >{{ 'cours.create.rate' | translate }} {{ 'global.enums.pricingMethod.' + pricing.method | translate }}
    {{ 'cours.create.perSeance' | translate }}</label
  >
  <div class="col-xl-8">
    <div class="custom-flex-row col-xl-12 custom-no-padding-left">
      <div>
        <app-price-input [mandatory]="true" [pricing]="pricing"
                         placeholderTxt="{{ 'cours.create.rate' | translate }} {{ 'cours.create.rate' | translate }} {{ 'global.enums.pricingMethod.' + pricing.method | translate }}"></app-price-input>
      </div>
      <div class="checkbox c-checkbox">
        <label class="custom-no-margin-bottom custom-margin-left">
          <input type="checkbox" [checked]="pricing.taxIncluded" disabled/>
          <span class="far fa-check"></span
          >{{ 'cours.create.taxIncluded' | translate }}</label
        >
      </div>
    </div>
  </div>
</div>

<div class="form-group row">
  <label for="shortDescription" class="col-xl-4 col-form-label">{{
    'cours.shortDescription' | translate
  }}</label>
  <div class="col-xl-8">
    <input
      #shortDescription="ngModel"
      [(ngModel)]="cours.shortDescription"
      class="form-control"
      id="shortDescription"
      name="shortDescription"
      type="text"
      [placeholder]="'cours.create.shortDescriptionPlcHldr' | translate"
    />
  </div>
</div>

<div class="form-group row">
  <label for="longDescription" class="col-xl-4 col-form-label">{{
    'cours.longDescription' | translate
    }}</label>
  <div class="col-xl-8">
    <input
      #longDescription="ngModel"
      [(ngModel)]="cours.longDescription"
      class="form-control"
      id="longDescription"
      name="longDescription"
      type="text"
      [placeholder]="'cours.create.longDescriptionPlcHldr' | translate"
    />
  </div>
</div>
