export enum CoursStatus {
  PLANNED = 'PLANNED',
  REGISTRATIONS_OPENED = 'REGISTRATIONS_OPENED',
  IN_PROGRESS = 'IN_PROGRESS',
  CLOSED = 'CLOSED',
  FINISHED = 'FINISHED',
  ARCHIVED = 'ARCHIVED',
  CANCELLED = 'CANCELLED',
  COPIED = 'COPIED'
}
