<div class="modal-header">
  <h4 class="modal-title pull-left">
      {{ 'global.register' | translate }} {{ currentParticipant.firstname }}
      {{ currentParticipant.lastname }}
  </h4>
  <button
    (click)="close()"
    aria-label="Close"
    class="close pull-right"
    type="button"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div *ngIf="config" class="modal-body">
  <fieldset>
    <div class="row">
      <!-- LEFT CONTENT -->
      <div class="col-xl-6">
        <div class="col-xl-12">
          <div>
            <label class="col-form-label">{{
              'person.lastname' | translate
            }}</label>
            <input
                    class="form-control"
                    type="text"
                    [placeholder]="'person.lastname' | translate"
                    disabled
                    [value]="currentParticipant.lastname"
            />
          </div>
          <div>
            <label class="col-form-label">{{
              'person.firstname' | translate
            }}</label>
            <input
                    class="form-control"
                    type="text"
                    [placeholder]="'person.firstname' | translate"
                    disabled
                    [value]="currentParticipant.firstname"
            />
          </div>
          <div>
            <label class="col-form-label">{{
              'person.birthDate' | translate
            }}</label>
            <input
                    class="form-control"
                    type="text"
                    [placeholder]="'person.birthDate' | translate"
                    disabled
                    [value]="currentParticipant.birthDate | date: 'dd.MM.yyyy'"
            />
          </div>
          <div>
            <label class="col-form-label">{{
              'person.privateAddress' | translate
            }}</label>
            <input
              class="form-control"
              type="text"
              [placeholder]="'person.privateAddress' | translate"
              disabled
              [value]="currentParticipant.address + ', ' + currentParticipant.zip + ' ' + currentParticipant.locality"
            />
          </div>
        </div>
      </div>

      <!-- RIGHT CONTENT-->
      <div class="col-xl-6">
        <div class="col-xl-12">
          <label class="col-form-label">{{
            'person.client.register.setCoursType' | translate
          }}</label>
          <select
            #coursTypeSelect
            (change)="onCoursTypeChange(coursTypeSelect.value)"
            [(ngModel)]="selectedCoursType"
            class="custom-select"
          >
            <option *ngIf="selectedCoursType == null" [ngValue]="null">{{
              'person.client.register.coursTypeDefault' | translate
              }}</option>
            <ng-container *ngFor="let coursType of coursTypes">
              <option [ngValue]="coursType">{{ coursType.name }}</option>
            </ng-container>
          </select>
        </div>
        <div *ngIf="selectedCoursType != null" class="col-xl-12">
          <label class="col-form-label">{{
            'person.client.register.setLocation' | translate
            }}</label>
          <select
            #locationSelect
            (change)="onLocationChange(locationSelect.value)"
            [(ngModel)]="selectedLocation"
            class="custom-select"
          >
            <option *ngIf="selectedLocation == null" [ngValue]="null">{{
              'person.client.register.locationDefault' | translate
            }}</option>
            <ng-container *ngFor="let location of locations">
              <option [ngValue]="location">{{ location.name }}</option>
            </ng-container>
          </select>
        </div>
        <div
          *ngIf="selectedCoursType != null && selectedLocation != null"
          class="col-xl-12"
        >
          <label class="col-form-label">{{
            'person.client.register.setCours' | translate
          }}</label>
          <select
            #coursSelect
            [(ngModel)]="selectedCours"
            class="custom-select"
          >
            <option *ngIf="selectedCours == null" [ngValue]="null">{{
              'person.client.register.coursDefault' | translate
              }}</option>
            <ng-container *ngFor="let cours of coursFilteredByLocation">
              <option [ngValue]="cours"
              >N° {{ cours.number }} - {{ 'global.from' | translate }}
                {{ cours.startDate | date: 'dd.MM.yyyy' }}
                {{ 'global.at' | translate }}
                {{ cours.startDate | date: 'HH:mm' }}</option
              >
            </ng-container>
          </select>
        </div>
        <div
          *ngIf="
            selectedCoursType != null &&
            selectedLocation != null &&
            selectedCours != null
          "
          class="col-xl-12"
        >
          <label class="col-form-label">{{
            'person.client.register.setMethod' | translate
          }}</label>
          <select
            #methodSelect
            [(ngModel)]="selectedMethod"
            class="custom-select"
          >
            <ng-container *ngFor="let method of PricingMethod | keyvalue">
              <option [ngValue]="method.key"
                >{{ 'global.enums.pricingMethod.' + method.key | translate }}
              </option>
            </ng-container>
          </select>
        </div>
        <div
          *ngIf="
            selectedCoursType != null &&
            selectedLocation != null &&
            selectedCours != null &&
            selectedMethod != null
          "
          class="col-xl-12"
        >
          <label class="col-form-label">{{
            'person.client.register.setStatus' | translate
          }}</label>
          <select
            #statusSelect
            [(ngModel)]="selectedStatus"
            class="custom-select"
          >
            <ng-container *ngFor="let statu of status">
              <option [ngValue]="statu.status"
                >{{ statu.displayedText }}
              </option>
            </ng-container>
          </select>
        </div>
      </div>
    </div>
  </fieldset>
</div>
<div class="modal-footer">
  <button
    class="mr-1 btn btn-labeled btn-success mb-1"
    type="button"
    [disabled]="selectedCours == null"
    (click)="saveRegistration()"
  >
    <span class="btn-label"><i class="fa fa-save"></i></span>
    {{ 'global.save' | translate }}
  </button>
  <button
    class="mr-1 mb-1 btn btn-outline-secondary"
    type="button"
    (click)="close()"
  >
    {{ 'global.close' | translate }}
  </button>
</div>
