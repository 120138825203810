import {Component} from '@angular/core';
import {Subject} from 'rxjs';
import {InscriptionService} from '../../../services/inscription.service';
import {saveAs} from 'file-saver';
import {BsModalRef} from 'ngx-bootstrap/modal';

import {defineLocale} from 'ngx-bootstrap/chronos';
import {frLocale} from 'ngx-bootstrap/locale';
import {BsLocaleService} from 'ngx-bootstrap/datepicker';

defineLocale('fr', frLocale);

@Component({
  selector: 'app-download-pre-letters',
  templateUrl: './download-pre-letters.component.html',
  styleUrls: ['./download-pre-letters.component.scss']
})
export class DownloadPreLettersComponent {

  coursId;
  date: Date;
  nbDaysDeadline: number;

  spinner = false;
  onClose = new Subject();

  datepickerConfig = {
    containerClass: 'theme-angle',
    dateInputFormat: 'DD.MM.YYYY'
  };

  constructor(public bsModalRef: BsModalRef, private inscriptionService: InscriptionService, private localeService: BsLocaleService) {
    this.localeService.use('fr');
  }

  downloadPdf() {
    this.spinner = true;
    this.inscriptionService.generateLetters(this.coursId, this.date, this.nbDaysDeadline).subscribe(resp => {
      const filename = 'lettres.pdf';
      const blob = new Blob([resp.body], {type: 'application/pdf'});
      saveAs(blob, filename);
      this.spinner = false;
      this.close();
    });
  }

  close() {
    this.onClose.next();
  }
}
