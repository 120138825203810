import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from 'src/app/auth/auth.service';
import {SettingsService} from 'src/app/core/settings/settings.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-recover',
    templateUrl: './recover.component.html'
})
export class RecoverComponent implements OnInit {

    spinner = false;

    constructor(public settings: SettingsService,
                private toasterService: ToastrService,
                private router: Router,
                private authService: AuthService,
                private translateService: TranslateService) {
    }

    submitForm(email: string) {
        this.spinner = true;
        this.authService.sendNewPassword(email).subscribe(value => {

          this.toasterService.success(this.translateService.instant('toasters.success.newPassword'));
            this.router.navigateByUrl('/login');
            this.spinner = false;
        }, () => {
          this.toasterService.error(this.translateService.instant('toasters.error.newPassword'));
            this.spinner = false;
        });

    }

    ngOnInit() {
    }

}
