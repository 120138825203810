import {NgModule} from '@angular/core';

import {SharedModule} from '../../shared/shared.module';
import {LoginComponent} from './login/login.component';
import {RecoverComponent} from './recover/recover.component';

@NgModule({
    imports: [
        SharedModule,
    ],
    declarations: [
        LoginComponent,
        RecoverComponent,
    ],
    exports: [
        LoginComponent,
        RecoverComponent,
    ]
})
export class PagesModule { }
