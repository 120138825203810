import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CoursType} from 'src/app/models/cours-type';
import {map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';

const API_ROUTE = '/api/cours-type';

@Injectable({
  providedIn: 'root'
})
export class CoursTypeService {

  constructor(private readonly http: HttpClient) {
  }

  saveCoursType(coursType: CoursType) {
    if (coursType.id) {
      return this.http.put(`${API_ROUTE}/${coursType.id}`, coursType).pipe(map(CoursType.fromJson)).pipe(tap(x => this.getAll()));
    } else {
      return this.http.post(API_ROUTE, coursType).pipe(map(CoursType.fromJson)).pipe(tap(x => this.getAll()));
    }
  }

  getAll(): Observable<CoursType[]> {
    return this.http.get(`${API_ROUTE}`).pipe(map((values: any) => values.map(CoursType.fromJson)));
  }

  deleteById(coursTypeId: number): any {
    return this.http.delete(`${API_ROUTE}` + '/' + coursTypeId, {observe: 'response'});
  }

}
