<div class="modal-header">
  <h4 class="modal-title pull-left">
    {{ 'cours.details.inscriptions.cancel.title' | translate }}
  </h4>
  <button
    (click)="bsModalRef.hide()"
    aria-label="Close"
    class="close pull-right"
    type="button"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="col-xl-12">
    <span
    >{{ 'cours.details.inscriptions.cancel.info1' | translate }}
      <strong>{{ nbPlacesAvailable }}</strong>
      {{ 'cours.details.inscriptions.cancel.info2' | translate }}.
      <strong
      >{{ 'cours.details.inscriptions.cancel.info3' | translate }}.</strong
      ></span
    >
  </div>
  <div
    *ngFor="let registrationToUpdate of inscriptionsToUpdate; let i = index"
    class="col-xl-12"
  >
    <select
      name="regSelect{{ i }}"
      [ngModel]="registrationToUpdate"
      class="form-control custom-select col-xl-12 custom-margin"
      (ngModelChange)="onWaitingListSelect($event, i)"
    >
      <option *ngFor="let registration of waitingList" [ngValue]="registration">
        {{ registration.participant.lastname }}
        {{ registration.participant.firstname }}
      </option>
    </select>
  </div>
  <div *ngIf="showError" class="col-xl-12 custom-margin">
    <label class="text-danger"
      >{{
      'cours.details.inscriptions.cancel.studentAlreadyAdded' | translate
      }}.</label
    >
  </div>
  <div class="col-xl-12">
    <button
      class="btn btn-labeled btn-primary custom-margin"
      type="button"
      [tooltip]="'global.add' | translate"
      placement="right"
      (click)="onAddInscriptions()"
      [disabled]="isAddDisabled"
    >
      <span class="btn-label"><i class="fal fa-plus"></i></span>
      {{ 'global.add' | translate }}
    </button>
  </div>
</div>
<div class="modal-footer">
  <!-- Cancel button -->
  <button
    class="mr-1 mb-1 btn btn-outline-secondary"
    type="button"
    (click)="bsModalRef.hide()"
  >
    {{'cours.details.inscriptions.cancel.cancelText' | translate }}
  </button>

  <!-- Save or Continue button -->
  <button
    class="mr-1 btn btn-labeled btn-success mb-1"
    type="button"
    [disabled]="isSaveDisabled"
    (click)="createInscriptions()"
  >
    <span class="btn-label"><i class="far fa-save"></i></span>
    {{ 'global.save' | translate }}
  </button>
</div>
