import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {


  constructor(public toasterService: ToastrService, private translateService: TranslateService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';
          let show = true;
          console.error('Error', error.error)
          if (error.error instanceof ErrorEvent) {
            // client-side error
            this.translateService.get('global.errors.client').subscribe(data => {
              errorMessage = data;
            });
          } else {
            // server-side error
            if (error.error != null) {
              this.translateService.get('global.errors.server').subscribe(data => {
                errorMessage = data + error.error.exception;
              });
            } else if (error.status == 401) {
              // authentication errors are treated by login component
              show = false;
            } else {
              this.translateService.get('global.errors.server').subscribe(data => {
                errorMessage = data + error.status;
              });
            }
          }
          if (show) {
            this.toasterService.error(errorMessage);
          }
          return throwError(errorMessage);
        })
      )
  }
}
