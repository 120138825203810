import {SupportedCurrency} from './enums/supported-currency.enum';
import {AgcProfile} from './enums/agc-profile-enum';

export class Config {

  public organization: string;
  public activeProfiles: string[];
  public currency: SupportedCurrency;
  public imgFilename: String;

  constructor(json?: any) {
    this.organization = json?.organization;
    this.activeProfiles = json?.activeProfiles;
    this.currency = json?.currency;
    this.imgFilename = json?.imgFilename;
  }

  hasFunctionality(profile: AgcProfile): boolean {
    return this.activeProfiles.includes(profile);
  }

}
