import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


/**
 * A custom pipe that truncates long text
 */
@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  constructor() {
  }
  transform(value: string, limit = 37): any {
    if (value) {
      let completion = '...';
      return value.length > limit ? value.substr(0, limit) + completion : value;
    } else {
      return value;
    }
  }

}
