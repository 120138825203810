import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';
import {Seance} from 'src/app/models/seance';
import {Cours} from "../../../models/cours";

@Component({
  selector: 'app-participation',
  templateUrl: './participation-list.component.html',
  styleUrls: ['./participation-list.component.scss']
})
export class ParticipationListComponent implements OnInit {
  currentSeance: Seance;
  currentCours: Cours;

  public onClose: Subject<boolean>;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.onClose = new Subject();
    this.currentSeance.participations.sort((a, b) => a.participant.lastname.localeCompare(b.participant.lastname));
  }
}
