<div class="modal-header">
  <h4 class="modal-title pull-left">
    {{ 'cours.manualStatusChange' | translate }} {{ cours?.number }} -
    {{ cours?.coursType?.name }}
  </h4>

  <button
    (click)="bsModalRef.hide()"
    aria-label="Close"
    class="close pull-right"
    type="button"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-xl-6 col-lg-10">
      <div class="h3 m-0 text-bold text-uppercase text-center">{{ 'cours.status' | translate }} AGC</div>
    </div>
    <div class="col-xl-6 col-lg-10">
      <div class="h3 m-0 text-bold text-uppercase text-center">
        {{ 'cours.currentCoursStatus' | translate }}
      </div>
    </div>
  </div>

  <div class="row m-lg-1">
    <div class="col-xl-6 col-lg-10">
      <app-status-block
        [bgDarker]="'bg-gray-dark'"
        [bg]="'bg-gray'"
        (click)="updateCoursStatus(CoursStatsEnums.COPIED)"
        [text]="'cours.statusMessages.COPIED' | translate"
        [title]="'global.enums.coursStatus.COPIED' | translate"
      ></app-status-block>
  </div>
    <div class="col-xl-6 col-lg-10">

      <app-current-status-block
        [cours]="cours"
        [status]="CoursStatsEnums.COPIED"
        [text]="'global.enums.coursStatus.COPIED' | translate"
      ></app-current-status-block>
    </div>
  </div>

  <div class="row m-lg-1">
    <div class="col-xl-6 col-lg-10">
      <app-status-block
        [bgDarker]="'bg-info-dark'"
        [bg]="'bg-info'"
        (click)="updateCoursStatus(CoursStatsEnums.PLANNED)"
        [text]="'cours.statusMessages.PLANNED' | translate"
        [title]="'global.enums.coursStatus.PLANNED' | translate"
      ></app-status-block>
      </div>
    <div class="col-xl-6 col-lg-10">
      <app-current-status-block
        [cours]="cours"
        [status]="CoursStatsEnums.PLANNED"
        [text]="'global.enums.coursStatus.PLANNED' | translate"
      ></app-current-status-block>
      </div>
  </div>

  <div class="row m-lg-1">
    <div class="col-xl-6 col-lg-10">
      <app-status-block
        [bgDarker]="'bg-info-dark'"
        [bg]="'bg-info'"
        (click)="updateCoursStatus(CoursStatsEnums.REGISTRATIONS_OPENED)"
        [text]="'cours.statusMessages.REGISTRATIONS_OPENED' | translate"
        [title]="'global.enums.coursStatus.REGISTRATIONS_OPENED' | translate"
      ></app-status-block>
      </div>
    <div class="col-xl-6 col-lg-10">
      <app-current-status-block
        [cours]="cours"
        [status]="CoursStatsEnums.REGISTRATIONS_OPENED"
        [text]="'global.enums.coursStatus.REGISTRATIONS_OPENED' | translate"
      ></app-current-status-block>
      </div>
  </div>

  <div class="row m-lg-1">
    <div class="col-xl-6 col-lg-10">
      <app-status-block
        [bgDarker]="'bg-success-dark'"
        [bg]="'bg-success'"
        (click)="updateCoursStatus(CoursStatsEnums.IN_PROGRESS)"
        [text]="'cours.statusMessages.IN_PROGRESS' | translate"
        [title]="'global.enums.coursStatus.IN_PROGRESS' | translate"
      ></app-status-block>
      </div>
    <div class="col-xl-6 col-lg-10">
      <app-current-status-block
        [cours]="cours"
        [status]="CoursStatsEnums.IN_PROGRESS"
        [text]="'global.enums.coursStatus.IN_PROGRESS' | translate"
      ></app-current-status-block>
      </div>
  </div>

  <div class="row m-lg-1">
    <div class="col-xl-6 col-lg-10">
      <app-status-block
        [bgDarker]="'bg-warning'"
        [bg]="'bg-warning-light'"
        (click)="updateCoursStatus(CoursStatsEnums.FINISHED)"
        [text]="'cours.statusMessages.FINISHED' | translate"
        [title]="'global.enums.coursStatus.FINISHED' | translate"
      ></app-status-block>
    </div>
    <div class="col-xl-6 col-lg-10">
      <app-current-status-block
        [cours]="cours"
        [status]="CoursStatsEnums.FINISHED"
        [text]="'global.enums.coursStatus.FINISHED' | translate"
      ></app-current-status-block>
      </div>
  </div>

  <div class="row m-lg-1">
    <div class="col-xl-6 col-lg-10">
      <app-status-block
        [bgDarker]="'bg-warning-dark'"
        [bg]="'bg-warning'"
        (click)="updateCoursStatus(CoursStatsEnums.CLOSED)"
        [text]="'cours.statusMessages.CLOSED' | translate"
        [title]="'global.enums.coursStatus.CLOSED' | translate"
      ></app-status-block>
      </div>
    <div class="col-xl-6 col-lg-10">
      <app-current-status-block
        [cours]="cours"
        [status]="CoursStatsEnums.CLOSED"
        [text]="'global.enums.coursStatus.CLOSED' | translate"
      ></app-current-status-block>
      </div>
  </div>

  <div class="row m-lg-1">
    <div class="col-xl-6 col-lg-10">
      <app-status-block
        [bgDarker]="'bg-gray-darker'"
        [bg]="'bg-gray-dark'"
        (click)="updateCoursStatus(CoursStatsEnums.ARCHIVED)"
        [title]="'global.enums.coursStatus.ARCHIVED' | translate"
      ></app-status-block>
      </div>
    <div class="col-xl-6 col-lg-10">
      <app-current-status-block
        [cours]="cours"
        [status]="CoursStatsEnums.ARCHIVED"
        [text]="'global.enums.coursStatus.ARCHIVED' | translate"
      ></app-current-status-block>
      </div>
  </div>
</div>
