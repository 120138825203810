<div class="modal-header">
  <h4 class="modal-title pull-left">
    {{ 'cours.details.seances.showParticipants.title' | translate }} "{{
    currentCours.coursType.name
    }}" (n° {{ currentCours.number }})
  </h4>
  <button
    (click)="bsModalRef.hide()"
    aria-label="Close"
    class="close pull-right"
    type="button"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-xl-12">
      <div class="card card-default">
        <div class="col-xl-12 mb-3">
          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>
                    {{
                    'cours.details.inscriptions.common.client' | translate
                    }}
                  </th>
                  <th>{{ 'person.presence' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngFor="
                    let participation of this.currentSeance.participations
                  "
                >
                  <tr>
                    <td>
                      <a
                              stopPropagation
                              [routerLink]="['/participant', participation.participant.id]"
                              [tooltip]="'tooltips.showParticipant' | translate"
                              (click)="bsModalRef.hide()"
                      >
                          {{ participation.participant.lastname }}
                          {{ participation.participant.firstname }}
                      </a>
                    </td>
                    <td *ngIf="participation.participantPresence; else notDefined">
                      {{
                      'global.enums.participantPresence.' +
                      participation.participantPresence | translate
                      }}
                    </td>
                    <ng-template #notDefined>
                      <td>{{ 'person.presences.null' | translate }}</td>
                    </ng-template>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      class="mr-1 mb-1 btn btn-outline-secondary"
      type="button"
      (click)="bsModalRef.hide()"
    >
      {{ 'global.close' | translate }}
    </button>
  </div>
</div>
