import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead';
import {Participant} from 'src/app/models/participant';

/**
 * This component contains a single button, that should be used wherever an "add" button is needed.
 * The `tip` property will be displayed as a tooltip on the button
 */
@Component({
  selector: 'app-static-typeahead',
  templateUrl: './static-typeahead.component.html',
  styleUrls: ['./static-typeahead.component.scss']
})
export class StaticTypeaheadComponent implements OnInit {
  public asyncSelected = '';

  @Input()
  public dataSource: any[] = [];

  @Input()
  field: string;

  @Input()
  placeholderText: string = 'Chercher...';

  @Input()
  noResultText = 'Aucun résultat';

  @Input()
  loadingText = 'Chargement...';

  @Output()
  onStateChanged: EventEmitter<Participant> = new EventEmitter<Participant>();

  public typeaheadLoading: boolean = false;
  public typeaheadNoResults: boolean = false;

  ngOnInit(): void {}

  public changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
    this.onStateChanged.emit(null);
  }

  public changeTypeaheadNoResults(e: boolean): void {
    this.typeaheadNoResults = e;
  }

  public typeaheadOnSelect(e: TypeaheadMatch): void {
    this.onStateChanged.emit(e.item);
  }
}
