import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';
import {CoursType} from 'src/app/models/cours-type';
import {CoursTypeService} from 'src/app/services/cours-type.service';
import {LocationService} from 'src/app/services/location.service';
import {LiteLocation} from 'src/app/models/lite-location';
import {ConfigService} from 'src/app/services/config.service';
import {Config} from 'src/app/models/config';
import {LiteIntervenant} from "../../../../models/lite-intervenant";
import {Cours} from "../../../../models/cours";
import {CoursService} from "../../../../services/cours.service";

@Component({
  selector: 'app-cours-create-step1',
  templateUrl: './cours-create-step1.component.html',
  styleUrls: ['./cours-create-step1.component.scss']
})
export class CoursCreateStep1Component implements OnInit {

  @Input()
  cours: Cours;

  @Input()
  coursTypes: CoursType[] = [];

  @Input()
  locations: LiteLocation[] = [];

  @Input()
  teachers: LiteIntervenant[] = [];


  // contains the year of the trimester, excluding the current year which is found in cour.year
  years: number[] = [];


  // is not a cours property so we need to pass the value to the parent
  // so we can correctly init step 2.
  @Input()
  nbSeancesToCreate: number;

  @Output()
  seancesNbrChange: EventEmitter<number> = new EventEmitter<number>();

  public onClose: Subject<boolean>;

  config: Config;

  constructor(
    public bsModalRef: BsModalRef,
    private coursService: CoursService,
    private coursTypeService: CoursTypeService,
    private locationService: LocationService,
    private configService: ConfigService
  ) {

  }

  ngOnInit() {
    this.initYears();
    this.configService.getConfig().subscribe(config => this.config = config);
  }

  initYears() {
    let currentYear = this.cours.year;

    // get the next 3 years so the user can select it easily
    for (let index = 0; index < 3; index++) {
      currentYear++;
      this.years.push(currentYear);
    }
  }

  onNbSeanceChange(event: any) {
    this.seancesNbrChange.emit(event.target.value);
  }
}
