import {Person} from './person';
import {formatDate} from "@angular/common";

export class LiteParticipant extends Person {

  public nonMembre: boolean = false;
  public avsNumber: string;
  public note: string;

  // special field added for research only in Typeahead element.
  // Typeaheads don't allow to search on multiple field so we have to create
  // a search field that will concat student's name and firstname (and more fields in the future)
  public search: string = '';

  constructor(data?: any) {
    super(data);
    this.nonMembre = data?.nonMembre;
    this.avsNumber = data?.avsNumber;
    this.note = data?.note;

    const dob = this.formatBirthDate(this.birthDate);
    this.search = data?.lastname + ' ' + data?.firstname + ' - ' + dob + '';
  }

  private formatBirthDate(birthDate: Date): string {
    return birthDate ? formatDate(birthDate, 'dd.MM.yyyy', 'fr') : 'pas de date';
  }
}
