<div class="modal-header">
  <h4 class="modal-title pull-left">
    {{ 'cours.details.registerTrial.title' | translate }}
  </h4>
  <button
    (click)="bsModalRef.hide()"
    aria-label="Close"
    class="close pull-right"
    type="button"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div *ngIf="config" class="modal-body">
  <div class="form-group row">
    <label
      class="col-xl-12 col-form-label"
    >{{ 'cours.details.registerTrial.pricingSelect' | translate }} {{ currentParticipant.lastname }} {{ currentParticipant.firstname }}
      né(e) le : {{ currentParticipant.birthDate|date:'dd.MM.yyyy' }} (n° {{ currentParticipant.abacusNumber }}):
    </label>
    <div class="col-xl-6">
      <select
        [(ngModel)]="selectedPricingMethod"
        class="form-control custom-select"
        name="pricingMethodSelect"
      >
        <ng-container *ngFor="let method of PricingMethod | keyvalue">
          <option [ngValue]="method.key">{{
            'global.enums.pricingMethod.' + method.key | translate
          }}</option>
        </ng-container>
      </select>
    </div>
  </div>
</div>
<div class="modal-footer">
  <!-- Cancel button -->
  <button
    class="mr-1 mb-1 btn btn-outline-secondary"
    type="button"
    (click)="bsModalRef.hide()"
  >
    {{ 'global.close' | translate }}
  </button>

  <!-- Save or Continue button -->
  <button
    class="mr-1 btn btn-labeled btn-success mb-1"
    type="button"
    (click)="onRegisterTrial()"
    [disabled]="selectedPricingMethod == null"
  >
    <span class="btn-label"><i class="far fa-save"></i></span>
    {{ 'global.save' | translate }}
  </button>
</div>
