<div class="modal-header">
  <h4 class="modal-title pull-left">
    {{ 'cours.intervenantEdit.title' | translate }}
  </h4>
  <button
    (click)="bsModalRef.hide()"
    aria-label="Close"
    class="close pull-right"
    type="button"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <!-- LEFT HEADING -->
  <div class="row form-group">
    <label class="col-xl-4"><strong>{{ 'cours.intervenantEdit.actualCours' | translate }}: </strong></label>
    <label class="col-xl-8"
    >{{ currentCours.coursType.name }} (n° {{ currentCours.number }}) -
      {{ 'global.from' | translate }} {{ currentCours.startDate | date: 'dd.MM.yyyy' }} {{ 'global.at' | translate }}
      {{ currentCours.startDate | date: 'HH:mm' }}</label
    >
  </div>
  <div class="row form-group">
    <label class="col-xl-4"><strong>{{ 'cours.intervenantEdit.actualTeacher' | translate }}: </strong></label>
    <label class="col-xl-8"
    >{{ currentCours.seances[0].animations[0].intervenant.firstname }}
      {{ currentCours.seances[0].animations[0].intervenant.lastname }}</label
    >
  </div>
  <div *ngIf="teachers" class="row form-group">
    <label class="col-xl-4"><strong>{{ 'cours.intervenantEdit.newTeacher' | translate }}: </strong></label>
    <div class="col-xl-8">
      <select
        class="form-control custom-select col-xl-12"
        [(ngModel)]="selectedTeacher"
        (ngModelChange)="onTeacherChange($event)"
      >
        <option *ngFor="let teacher of teachers" [ngValue]="teacher"
          >{{ teacher.firstname }} {{ teacher.lastname }}</option
        >
      </select>
    </div>
  </div>
</div>
<div class="modal-footer">
  <!-- Cancel button -->
  <button
    class="mr-1 mb-1 btn btn-outline-secondary"
    type="button"
    (click)="bsModalRef.hide()"
  >
    {{ 'global.cancel' | translate }}
  </button>

  <!-- Save or Continue button -->
  <button
    class="mr-1 btn btn-labeled btn-success mb-1"
    type="button"
    [disabled]="selectedTeacher == null"
    (click)="onEditCoursTeacher()"
  >
    <span class="btn-label"><i class="far fa-save"></i></span>
    {{ 'global.save' | translate }}
  </button>
</div>
