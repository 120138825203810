<div class="modal-header">
  <h4 class="modal-title pull-left">
    Edition du cours n°{{ cours.number }} ({{ cours.coursType.name }})
  </h4>
  <button
    (click)="bsModalRef.hide()"
    aria-label="Close"
    class="close pull-right"
    type="button"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form #coursEditForm="ngForm" (ngSubmit)="saveCours()" novalidate role="form">
  <div class="modal-body">
    <div class="alert alert-info" role="alert">
      <i class="fal fa-info-square"></i>
      {{ 'form.indicatesRequiredFields' | translate }}
    </div>
    <div class="form-group row">
      <label for="trimester" class="col-xl-4 col-form-label required"
      >{{ 'cours.trimester' | translate }}/{{
        'global.year' | translate
        }}</label
      >
      <div class="col-xl-2">
        <input
          #trimester="ngModel"
          [(ngModel)]="coursCopy.trimester"
          class="form-control"
          id="trimester"
          name="trimester"
          type="number"
          [placeholder]="'cours.trimester | translate'"
          min="1"
          max="3"
          required
        />
        <div
          *ngIf="trimester.invalid && (trimester.dirty || trimester.touched)"
          class="text-danger"
        >
          {{ 'form.mandatoryField' | translate }}
        </div>
      </div>
      <div class="col-xl-2">
        <select
          #year="ngModel"
          [(ngModel)]="coursCopy.year"
          class="form-control custom-select"
          id="year"
          name="year"
        >
          <!-- <option selected [ngValue]="cours.year">{{ cours.year }} </option> -->
          <option
            *ngFor="let year of years"
            [ngValue]="year"
            [attr.selected]="cours.year == year ? true : null"
            >{{ year }}</option
          >
        </select>
        <div
          *ngIf="year.invalid && (year.dirty || year.touched)"
          class="text-danger"
        >
          {{ 'form.mandatoryField' | translate }}
        </div>
      </div>
      <div class="col-xl-4"></div>
    </div>
    <div *ngIf="coursCopy.location" class="form-group row">
      <label class="col-xl-4 col-form-label required" for="location">{{
        'cours.location' | translate
        }}</label>
      <div class="col-xl-8">
        <span *ngIf="locations.length == 0">
          <em class="fa fa-spin fa-spinner"></em> Chargement
        </span>
        <ng-container *ngIf="locations.length > 0">
          <select
            #location="ngModel"
            [(ngModel)]="coursCopy.location.id"
            class="form-control custom-select"
            id="location"
            name="location"
          >
            <option
              *ngFor="let location of locations"
              [attr.selected]="isCurrentLocation(location)"
              [ngValue]="location.id"
            >{{ location.name }}</option
            >
          </select>
          <div
            *ngIf="location.invalid && (location.dirty || location.touched)"
            class="text-danger"
          >
            {{ 'form.mandatoryField' | translate }}
          </div>
        </ng-container>
      </div>
    </div>
    <div *ngIf="coursCopy.coursType" class="form-group row">
      <label class="col-xl-4 col-form-label required" for="coursType">{{
        'cours.type' | translate
        }}</label>
      <div class="col-xl-8">
        <select
          #coursType="ngModel"
          [(ngModel)]="coursCopy.coursType.id"
          class="form-control custom-select"
          id="coursType"
          name="coursType"
        >
          <option
            *ngFor="let coursType of coursTypes"
            [attr.selected]="isCurrentCoursType(coursType)"
            [ngValue]="coursType.id"
          >{{ coursType.name }}</option
          >
        </select>
        <div
          *ngIf="coursType.invalid && (coursType.dirty || coursType.touched)"
          class="text-danger"
        >
          {{ 'form.mandatoryField' | translate }}
        </div>
      </div>
    </div>

    <div *ngIf="coursCopy.intervenant" class="form-group row">
      <label class="col-xl-4 col-form-label required" for="location">Intervenant principal</label>
      <div class="col-xl-8">
        <span *ngIf="intervenants.length == 0">
          <em class="fa fa-spin fa-spinner"></em> Chargement
        </span>
        <ng-container *ngIf="intervenants.length > 0">
          <select
            #teacher="ngModel"
            [(ngModel)]="coursCopy.intervenant.id"
            class="form-control custom-select"
            id="teacher"
            name="teacher"
          >
            <option
              *ngFor="let intervenant of intervenants"
              [attr.selected]="isCurrentTeacher(intervenant)"
              [ngValue]="intervenant.id"
            >{{intervenant.lastname}} {{intervenant.firstname}}</option
            >
          </select>
          <div
            *ngIf="teacher.invalid && (teacher.dirty || teacher.touched)"
            class="text-danger"
          >
            {{ 'form.mandatoryField' | translate }}
          </div>
        </ng-container>
      </div>
    </div>

    <div class="form-group row">
      <label for="nbPlaces" class="col-xl-4 col-form-label required">{{
        'cours.create.nbPlacesMax' | translate
      }}</label>
      <div class="col-xl-2">
        <input
          #nbPlaces="ngModel"
          [(ngModel)]="coursCopy.nbPlaces"
          class="form-control"
          id="nbPlaces"
          name="nbPlaces"
          type="number"
          placeholder="nombre"
          required
        />
        <div
          *ngIf="nbPlaces.invalid && (nbPlaces.dirty || nbPlaces.touched)"
          class="text-danger"
        >
          {{ 'form.mandatoryField' | translate }}
        </div>
      </div>
      <div class="col-xl-6"></div>
    </div>
    <div *ngFor="let pricing of coursCopy.pricings" class="form-group row">
      <label class="col-xl-4 col-form-label required"
      >{{ 'cours.create.rate' | translate }}
        {{ 'global.enums.pricingMethod.' + pricing.method | translate }}</label
      >
      <div class="col-xl-8">
        <div class="custom-flex-row col-xl-12 custom-no-padding-left">
          <div>
            <app-price-input
              [mandatory]="true"
              [pricing]="pricing"
              placeholderTxt="{{ 'cours.create.rate' | translate }} {{
                'global.enums.pricingMethod.' + pricing.method | translate
              }}"
            ></app-price-input>
          </div>
          <div class="checkbox c-checkbox">
            <label class="custom-no-margin-bottom custom-margin-left">
              <input type="checkbox" [checked]="pricing.taxIncluded" disabled/>
              <span class="far fa-check"></span
              >{{ 'cours.create.taxIncluded' | translate }}</label
            >
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="shortDescription" class="col-xl-4 col-form-label">{{
        'cours.shortDescription' | translate
      }}</label>
      <div class="col-xl-8">
        <input
          #shortDescription="ngModel"
          [(ngModel)]="coursCopy.shortDescription"
          class="form-control"
          id="shortDescription"
          name="shortDescription"
          type="text"
          [placeholder]="'cours.create.shortDescriptionPlcHldr' | translate"
        />
      </div>
    </div>

    <div class="form-group row">
      <label for="longDescription" class="col-xl-4 col-form-label">{{
        'cours.longDescription' | translate
        }}</label>
      <div class="col-xl-8">
        <input
          #longDescription="ngModel"
          [(ngModel)]="coursCopy.longDescription"
          class="form-control"
          id="longDescription"
          name="longDescription"
          type="text"
          [placeholder]="'cours.create.longDescriptionPlcHldr' | translate"
        />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <!-- Cancel button -->
    <button
      class="mr-1 mb-1 btn btn-outline-secondary"
      type="button"
      (click)="bsModalRef.hide()"
    >
      {{ 'global.cancel' | translate }}
    </button>

    <!-- Save or Continue button -->
    <button
      class="mr-1 btn btn-labeled btn-success mb-1"
      type="submit"
      [disabled]="coursEditForm.invalid"
    >
      <span class="btn-label"><i class="far fa-save"></i></span>
      {{ 'global.save' | translate }}
    </button>
  </div>
</form>
