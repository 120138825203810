import {LiteSeance} from './lite-seance';
import {ParticipantPresence} from './enums/participant-presence.enum';

export class LiteParticipation {

  public id: number;
  public seance: LiteSeance;
  public participantPresence: ParticipantPresence;

  constructor(
    json?: any
  ) {
    this.id = json?.id;
    this.seance = json?.seance;
    this.participantPresence = json?.participantPresence;
  }

  static fromJson(json: any = {}): LiteParticipation {
    return new LiteParticipation(json);
  }

}
