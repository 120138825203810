import {LiteIntervenant} from './lite-intervenant';
import {LiteAnimation} from './lite-animation';

export class Animation extends LiteAnimation {

  public intervenant: LiteIntervenant;

  constructor(data?: any) {
    super(data);
    if (data) {
      this.intervenant = new LiteIntervenant(data?.intervenant);

    }
  }

  static fromJson(json: any = {}): Animation {
    return new Animation(
      json
    );
  }

}
