<div class="modal-header">
  <h4 class="modal-title pull-left">
    {{ 'cours.details.inscriptions.pre.download' | translate }}
  </h4>
  <button
    (click)="bsModalRef.hide()"
    aria-label="Close"
    class="close pull-right"
    type="button"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form class="form" #form="ngForm">
    <div class="form-group">
      <label for="date"
      >{{ 'cours.copy.reRegisterDate' | translate }}: *</label
      >
      <input
        type="text"
        bsDatepicker
        class="form-control"
        id="date"
        name="date"
        [(ngModel)]="date"
        required
        [bsConfig]="datepickerConfig"
      />
    </div>
    <div class="form-group">
      <label for="date"
      >{{ 'cours.copy.reRegisterDelay' | translate }} ({{
        'global.day2' | translate
        }}): *</label
      >
      <input
        type="number"
        class="form-control"
        id="nbDaysDeadline"
        name="nbDaysDeadline"
        [(ngModel)]="nbDaysDeadline"
        required
      />
    </div>
  </form>
</div>
<div class="modal-footer">
  <!-- Cancel button -->
  <button
    class="mr-1 mb-1 btn btn-outline-secondary"
    type="button"
    (click)="bsModalRef.hide()"
  >
    {{ 'global.close' | translate }}
  </button>

  <br />
  <!-- Save or Continue button -->
  <button
    class="mr-1 btn btn-labeled btn-success mb-1"
    type="button"
    (click)="downloadPdf()"
    [disabled]="form.invalid || this.spinner"
  >
    <div *ngIf="this.spinner; else downloadButton">
      <span class="btn-label"><i class="fal fa-spin fa-spinner"></i></span>
      {{ 'global.spinner' | translate }}
    </div>
    <ng-template #downloadButton>
      <span class="btn-label"><i class="far fa-download"></i></span>
      {{ 'global.download' | translate }}
    </ng-template>
  </button>
</div>
