<div *ngIf="cours" class="content-heading">
  <span
  >{{ 'cours.details.title' | translate }} n°{{ cours.number }} -
    {{ cours.coursType.name }}</span
  >
</div>
<!-- Cours INFO CARD -->
<div *ngIf="cours" class="row">
  <div class="col-xl-12">
    <div class="card card-default">
      <div class="card-body">
        <h4 class="page-header">
          {{ 'cours.details.informations.title' | translate }}
        </h4>
        <!-- LEFT CONTENT -->
        <div class="custom-flex-display">
          <div class="col-xl-6 custom-form-display">
            <div class="custom-flex-display pt-1 ">
              <label class="col-xl-3 custom-no-left-padding">{{
                'cours.number' | translate
                }}</label>
              <div class="col-xl-9">
                <input
                  class="form-control"
                  type="number"
                  [(ngModel)]="cours.number"
                  disabled
                  [placeholder]="'cours.number' | translate"
                />
              </div>
            </div>
            <div class="custom-flex-display pt-1">
              <label class="col-xl-3 custom-no-left-padding"
              ><a
                [routerLink]="[
                    '/location',
                    cours.location?.id,
                    'seances',
                    coursId
                  ]"
              >
                {{ 'cours.location' | translate }}</a
              ></label
              >
              <div class="col-xl-9">
                <input
                  class="form-control"
                  type="text"
                  [(ngModel)]="cours.location.name"
                  disabled
                  [placeholder]="'cours.location' | translate"
                />
              </div>
            </div>
            <div class="custom-flex-display pt-1">
              <label class="col-xl-3 custom-no-left-padding">{{
                'cours.type' | translate
                }}</label>
              <div class="col-xl-9">
                <input
                  class="form-control"
                  type="text"
                  [(ngModel)]="cours.coursType.name"
                  disabled
                  [placeholder]="'cours.type' | translate"
                />
              </div>
            </div>
            <div class="custom-flex-display pt-1">
              <label class="col-xl-3 custom-no-left-padding">{{
                'cours.intervenant' | translate
                }}</label>
              <div class="col-xl-9">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Intervenant principal"
                  disabled
                  value="{{ cours.intervenant?.lastname }} {{
                    cours.intervenant?.firstname
                  }}"
                />
              </div>
            </div>

            <div class="custom-flex-display pt-1">
              <label class="col-xl-3 custom-no-left-padding">{{
                'cours.details.informations.period' | translate
                }}</label>
              <div class="col-xl-9">
                <input
                  class="form-control"
                  type="text"
                  [placeholder]="
                    'cours.details.informations.period' | translate
                  "
                  disabled
                  value="{{ cours.year }} trim. {{ cours.trimester }}"
                />
              </div>
            </div>
          </div>
          <!-- RIGHT CONTENT -->
          <div class="col-xl-6 custom-form-display">
            <div
              *ngFor="let pricing of cours.pricings"
              class="custom-flex-display pt-1"
            >
              <label class="col-xl-3 custom-no-left-padding"
              >{{ 'cours.details.informations.price' | translate }}
                {{
                'global.enums.pricingMethod.' + pricing.method | translate
                }}</label
              >
              <div class="col-xl-9">
                <app-price-input
                  [pricing]="pricing"
                  [disabled]="true"
                ></app-price-input>
              </div>
            </div>
            <div class="custom-flex-display pt-1">
              <label class="col-xl-3 custom-no-left-padding">{{
                'cours.status' | translate
                }}</label>
              <div class="col-xl-9">
                <input
                  class="form-control lastItem"
                  type="text"
                  [ngModel]="
                    'global.enums.coursStatus.' + cours.status | translate
                  "
                  disabled
                  [placeholder]="
                    'global.enums.coursStatus.' + cours.status | translate
                  "
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-12 pt-lg-3">
          <!-- [AGC-223] open registration for PLANNED cours added to this view -->
          <button
            *ngIf="cours.status == CoursStatus.PLANNED"
            class="btn btn-labeled btn-primary ml-1"
            type="button"
            [tooltip]="'cours.list.table.openReg' | translate"
            (click)="onOpenRegistration()"
          >
            <span class="btn-label"><i class="far fa-lock-open"></i></span>
            {{ 'cours.list.table.openReg' | translate }}
          </button>

          <!-- Register students button only showed in cours's that are REGISTRATION_OPENED or IN_PROGRESS -->
          <!-- [AGC-83] button also shown for cours IN_PROGRESS -->
          <button
            *ngIf="
              cours.status == CoursStatus.REGISTRATIONS_OPENED ||
              cours.status == CoursStatus.IN_PROGRESS
            "
            class="btn btn-labeled btn-primary ml-1"
            type="button"
            (click)="onRegisterParticipants()"
            [tooltip]="
              'cours.details.informations.registerParticipantsTooltip'
                | translate
            "
          >
            <span class="btn-label"><i class="far fa-user-plus"></i></span>
            {{ 'cours.details.informations.registerParticipants' | translate }}
          </button>

          <!-- [AGC-247] Presence entries button for this cours if its stats is IN_PROGRESS or FINISHED -->
          <button
            class="btn btn-labeled btn-info ml-1"
            type="button"
            [tooltip]="'cours.list.table.presenceEntries' | translate"
            (click)="onShowPresenceEntries()"
          >
            <span class="btn-label"><i class="far fa-tasks"></i></span>
            {{ 'cours.list.table.presenceEntries' | translate }}
          </button>

          <button
            class="btn btn-warning btn-labeled ml-1"
            type="button"
            (click)="statusModal()"
          >
            <span class="btn-label"> <em class="far fa-sync"></em> </span>
            {{ 'cours.status' | translate }}
          </button>

          <div class="btn-group ml-auto" container="body" dropdown>
            <button
              aria-controls="dropdown-alignment"
              class="btn btn-outline-secondary ml-1"
              dropdownToggle
              id="button-alignment"
              type="button"
            >
              <i class="far fa-ellipsis-h"></i>
            </button>
            <ul
              *dropdownMenu
              class="dropdown-menu"
              id="dropdown-alignment"
              role="menu"
              aria-labelledby="button-alignment"
            >
              <li
                role="menuitem"
                *ngIf="
                  this.cours.status == CoursStatus.REGISTRATIONS_OPENED ||
                  this.cours.status == CoursStatus.IN_PROGRESS ||
                  this.cours.status == CoursStatus.FINISHED
                "
              >
                <div (click)="onCopyCoursRegistrations()" class="dropdown-item">
                  <span
                  ><i class="far fa-copy"></i>
                    {{ 'cours.list.table.copyReg' | translate }}</span
                  >
                </div>
              </li>
              <li role="menuitem">
                <div (click)="editCours()" class="dropdown-item">
                  <span
                  ><i class="far fa-fw fa-edit"></i>
                    {{ 'global.edit' | translate }}</span
                  >
                </div>
              </li>
              <li class="divider dropdown-divider"></li>
              <li
                role="menuitem"
                *ngIf="
                  cours.status != CoursStatus.CANCELLED &&
                  cours.status != CoursStatus.ARCHIVED
                "
              >
                <div (click)="onCancel()" class="dropdown-item">
                  <span><i class="far fa-ban"></i></span>
                  {{ 'global.cancel' | translate }}
                </div>
              </li>
              <li *ngIf="cours.status == CoursStatus.CANCELLED" role="menuitem">
                <div (click)="onReactivate()" class="dropdown-item">
                  <span
                  ><i class="far fa-check"></i>
                    {{ 'cours.list.table.reactivate' | translate }}
                  </span>
                </div>
              </li>

              <li role="menuitem">
                <div (click)="onDelete()" class="dropdown-item">
                  <span
                  ><i class="far fa-trash "></i>
                    {{ 'global.delete' | translate }}
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- SEANCE LIST -->
<div class="row">
  <div class="col-xl-12">
    <div class="card card-default">
      <div class="card-body">
        <h4 class="page-header mt0">
          <button
            class="btn btn-secondary"
            (click)="toggleShowSeances()"
            *ngIf="showSeances"
            type="button"
            [title]="'cours.details.seances.list.close' | translate"
          >
            <span><em class="far fa-caret-square-up"></em></span>
          </button>
          <button
            class="btn btn-secondary"
            (click)="toggleShowSeances()"
            *ngIf="!showSeances"
            type="button"
            [title]="'cours.details.seances.list.open' | translate"
          >
            <span><em class="far fa-caret-square-down"></em></span>
          </button>
          {{ 'cours.details.seances.title' | translate }} ({{
          cours?.seancesCount
          }})
        </h4>
        <div *ngIf="showSeances">
          <app-seance-list [currentCours]="cours"></app-seance-list>
          <br/>
          <app-add-button
            (click)="onCreate()"
            [text]="'cours.details.seances.addSeances' | translate"
            [tooltip]="'cours.details.seances.addSeances' | translate"
          ></app-add-button>
          <button
            class="btn btn-labeled btn-primary ml-1"
            type="button"
            [tooltip]="
              'cours.details.seances.downloadParticipantsTooltip' | translate
            "
            placement="right"
            (click)="onGenerateParticipations()"
          >
            <span class="btn-label"><i class="far fa-download"></i></span>
            {{ 'cours.details.seances.downloadParticipants' | translate }}
          </button>
          <button
            class="btn btn-labeled btn-primary ml-1"
            type="button"
            placement="right"
            (click)="onEditTeacherAllSeances()"
            [disabled]="isEditTeacherDisabled"
          >
            <span class="btn-label"><i class="far fa-edit"></i></span>
            {{ 'cours.details.seances.editTeacher' | translate }}
          </button>
        </div>
        <label id="info"
        ><strong
        >* {{ 'cours.details.seances.editTeacherInfo' | translate }}</strong
        ></label
        >
      </div>
    </div>
  </div>
</div>
<!-- PRE-REGISTRATIONS LIST  -->
<div class="row" *ngIf="config">
  <div class="col-xl-12">
    <div class="card card-default">
      <div class="card-body">
        <div class="page-header">
          <h4>
            {{ 'cours.details.inscriptions.pre.title' | translate }} ({{
            preRegistredRegistrations?.length
            }})
          </h4>
          <button
            class="btn btn-labeled btn-primary"
            type="button"
            (click)="onGeneratePreLetters()"
          >
            <span class="btn-label"><i class="far fa-file-pdf"></i></span>
            {{ 'cours.details.inscriptions.pre.download' | translate }}
          </button>
        </div>
        <table
          *ngIf="preRegistredRegistrations.length > 0; else noPreRegistrations"
          class="table table-responsive table-hover"
        >
          <thead>
          <tr>
            <th>
              {{ 'cours.details.inscriptions.common.number' | translate }}
            </th>
            <th>
              {{ 'cours.details.inscriptions.common.client' | translate }}
            </th>
            <th>{{ 'cours.status' | translate }}</th>
            <th>{{ 'cours.create.rate' | translate }}</th>
            <th>{{ 'global.actions' | translate }}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let registration of preRegistredRegistrations">
            <td>{{ registration.id }}</td>
            <td>
              <a
                [routerLink]="['/participant', registration.participant.id]"
                [tooltip]="'tooltips.showParticipant' | translate"
                stopPropagation
              >
                {{ registration.participant.lastname }}
                {{ registration.participant.firstname }}
              </a>
            </td>
            <td>
              {{
              'global.enums.registrationStatus.' + registration.status
                | translate
              }}
            </td>
            <td>
              {{
              'global.enums.pricingMethod.' + registration.method
                | translate
              }}
            </td>
            <td>
              <button
                (click)="onPreRegValidation(registration)"
                [tooltip]="'global.validate' | translate"
                class="btn btn-secondary ml-1"
                type="button"
              >
                <span><em class="far far fa-user-plus"></em></span>
              </button>
              <button
                (click)="onRegCancel(registration)"
                [tooltip]="'global.cancel' | translate"
                class="btn btn-outline-warning ml-1"
                type="button"
              >
                <span><em class="far fa-ban "></em></span>
              </button>
              <button
                (click)="onRegistrationEdit(registration)"
                [tooltip]="'global.edit' | translate"
                class="btn btn-secondary ml-1"
                type="button"
              >
                <span><em class="far fa-edit"></em></span>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
        <ng-template #noPreRegistrations
        ><span>{{
          'cours.details.inscriptions.pre.noPreFound' | translate
          }}</span></ng-template
        >
      </div>
    </div>
  </div>
</div>
<!-- REGISTRATIONS LIST (filtered by status 'REGISTERED' or 'REGISTERED_BILLED') -->
<div *ngIf="config" class="row">
  <div class="col-xl-12">
    <div class="card card-default">
      <div class="card-body">
        <h4 class="page-header">
          {{ 'cours.details.inscriptions.normal.title' | translate }} ({{
          registredAndBilledregistrations?.length
          }})
        </h4>
        <div class="table-responsive">
          <table
            *ngIf="
              registredAndBilledregistrations.length > 0;
              else noRegistrations
            "
            class="table table-hover"
          >
            <thead>
            <tr>
              <th>
                {{ 'cours.details.inscriptions.common.number' | translate }}
              </th>
              <th>
                {{ 'cours.details.inscriptions.common.client' | translate }}
              </th>
              <th>{{ 'cours.status' | translate }}</th>
              <th>{{ 'cours.create.rate' | translate }}</th>
              <th>
                {{ 'cours.details.inscriptions.common.date' | translate }}
              </th>
              <th>
                {{ 'cours.details.inscriptions.normal.isBill' | translate }}
              </th>
              <th>{{ 'global.actions' | translate }}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let registration of registredAndBilledregistrations">
              <td>{{ registration.id }}</td>
              <td>
                <a
                  [routerLink]="['/participant', registration.participant.id]"
                  [tooltip]="'tooltips.showParticipant' | translate"
                  stopPropagation
                >
                  {{ registration.participant.lastname }}
                  {{ registration.participant.firstname }}
                </a>
              </td>
              <td>
                {{
                'global.enums.registrationStatus.' + registration.status
                  | translate
                }}
              </td>
              <td>
                {{
                'global.enums.pricingMethod.' + registration.method
                  | translate
                }}
              </td>
              <td>{{ registration.validationDate | date: 'dd.MM.yyyy' }}</td>
              <td
                *ngIf="
                    registration.status != RegistrationStatus.REGISTERED_BILLED;
                    else billed
                  "
              >
                <button
                  (click)="onBilling(registration)"
                  [tooltip]="
                      'cours.details.inscriptions.normal.bill' | translate
                    "
                  class="btn btn-secondary ml-1"
                  type="button"
                >
                    <span class="text-danger"
                    ><em class="far fa-dollar-sign"></em
                    ></span>
                </button>
              </td>
              <ng-template #billed>
                <td>
                  <button
                    (click)="cancelBilling(registration)"
                    [tooltip]="
                        'cours.details.inscriptions.normal.billCancel'
                          | translate
                      "
                    class="btn btn-secondary ml-1"
                    type="button"
                  >
                      <span class="text-green"
                      ><em class="far fa-dollar-sign"></em
                      ></span>
                  </button>
                </td>
              </ng-template>
              <td>
                <div>
                  <button
                    (click)="onDownloadCertificate(registration)"
                    *ngIf="
                        cours.status == CoursStatus.CLOSED;
                        else undownloadable
                      "
                    [ngClass]="
                        registration.certificateGenerationDate
                          ? 'btn-warning'
                          : 'btn-primary'
                      "
                    [tooltip]="
                        'person.client.details.inscriptions.certificate'
                          | translate
                      "
                    class="btn"
                  >
                    <em class="far fa-download"></em> PDF
                  </button>
                  <ng-template #undownloadable>
                    <button
                      [tooltip]="
                          'person.client.details.inscriptions.certificateNotAvailable'
                            | translate
                        "
                      class="btn btn-secondary ml-1"
                      type="button"
                    >
                        <span style="text-decoration: line-through"
                        ><em class="far fa-download"></em> PDF</span
                        >
                    </button>
                  </ng-template>
                  <button
                    (click)="onRegCancel(registration)"
                    [tooltip]="'global.cancel' | translate"
                    class="btn btn-outline-warning ml-1"
                    type="button"
                  >
                    <em class="far fa-ban"></em>
                  </button>
                  <button
                    (click)="onRegistrationEdit(registration)"
                    [tooltip]="'global.edit' | translate"
                    class="btn btn-secondary ml-1"
                    type="button"
                  >
                    <span><em class="far fa-edit"></em></span>
                  </button>
                </div>
                <span
                  *ngIf="registration.certificateGenerationDate"
                  class="small"
                >Généré le
                  {{
                  registration.certificateGenerationDate
                    | date: 'dd.MM.yyyy'
                  }}</span
                >
              </td>
            </tr>
            </tbody>
          </table>
          <ng-template #noRegistrations
          ><span>{{
            'cours.details.inscriptions.normal.noFound' | translate
            }}</span></ng-template
          >
        </div>
        <br/>
        <button
          class="btn btn-labeled btn-primary ml-1"
          *ngIf="cours && cours.inscriptions"
          type="button"
          [tooltip]="'cours.details.inscriptions.normal.billAll' | translate"
          placement="right"
          (click)="onBillingAll()"
          [disabled]="isDisabled()"
        >
          <span class="btn-label"><i class="far fa-money-bill-alt"></i></span>
          {{ 'cours.details.inscriptions.normal.billAll' | translate }}
        </button>

        <button
          (click)="onAllDownloadCertificate()"
          *ngIf="cours.status == CoursStatus.CLOSED;"
          [tooltip]="
                        'person.client.details.inscriptions.certificate'
                          | translate
                      "
          class="btn btn-labeled btn-warning ml-1"
        >
          <span class="btn-label"><i class="far fa-download"></i></span>
          Télécharger toutes les attestations PDF
        </button>

      </div>
    </div>
  </div>
</div>
<!-- CANCELLED REGISTRATIONS LIST  -->
<div class="row">
  <div class="col-xl-12">
    <div class="card card-default">
      <div class="card-body">
        <h4 class="page-header">
          {{ 'cours.details.inscriptions.cancelled.title' | translate }} ({{
          cancelledRegistrations?.length
          }})
        </h4>
        <table
          *ngIf="
            cancelledRegistrations?.length > 0;
            else noCancelledRegistrations
          "
          class="table table-responsive table-hover"
        >
          <thead>
          <tr>
            <th>
              {{ 'cours.details.inscriptions.common.number' | translate }}
            </th>
            <th>
              {{ 'cours.details.inscriptions.common.client' | translate }}
            </th>
            <th>{{ 'cours.status' | translate }}</th>
            <th>{{ 'cours.create.rate' | translate }}</th>
            <th>
              {{ 'cours.details.inscriptions.common.date' | translate }}
            </th>
            <th>{{ 'global.actions' | translate }}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let registration of cancelledRegistrations">
            <td>{{ registration.id }}</td>
            <td>
              <a
                [routerLink]="['/participant', registration.participant.id]"
                [tooltip]="'tooltips.showParticipant' | translate"
                stopPropagation
              >
                {{ registration.participant.lastname }}
                {{ registration.participant.firstname }}
              </a>
            </td>
            <td>
              {{
              'global.enums.registrationStatus.' + registration.status
                | translate
              }}
            </td>
            <td>
              {{
              'global.enums.pricingMethod.' + registration.method
                | translate
              }}
            </td>
            <td>{{ registration.date | date: 'dd.MM.yyyy' }}</td>
            <td>
              <button
                (click)="_showRegisterWaitingConfirmation(registration)"
                class="btn btn-secondary ml-1"
                tooltip="Ajouter aux inscriptions en attente"
                type="button"
              >
                <span><em class="far fa-user-clock"></em></span>
              </button>

              <button
                (click)="onPreRegValidation(registration)"
                [tooltip]="'global.register' | translate"
                class="btn btn-secondary ml-1"
                type="button"
              >
                <span><em class="far fa-user-plus"></em></span>
              </button>

              <button
                (click)="onParticipationDelete(registration)"
                [tooltip]="'Effacer la participation'"
                class="btn btn-outline-danger ml-1"
                type="button"
              >
                <span><em class="far fa-trash"></em></span>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
        <ng-template #noCancelledRegistrations
        ><span>{{
          'cours.details.inscriptions.cancelled.noCancelledFound' | translate
          }}</span></ng-template
        >
      </div>
    </div>
  </div>
</div>

<!-- WAITING LIST -->
<div class="row">
  <div class="col-xl-12">
    <div class="card card-default">
      <div class="card-body">
        <h4 class="page-header">
          {{ 'cours.details.inscriptions.waiting.title' | translate }} ({{
          waitingRegistrations?.length
          }})
        </h4>
        <table
          *ngIf="waitingRegistrations?.length > 0; else noWaitingRegistrations"
          class="table table-responsive table-hover"
        >
          <thead>
          <tr>
            <th>
              {{ 'cours.details.inscriptions.common.number' | translate }}
            </th>
            <th>
              {{ 'cours.details.inscriptions.common.client' | translate }}
            </th>
            <th>{{ 'cours.status' | translate }}</th>
            <th>{{ 'cours.create.rate' | translate }}</th>
            <th>
              {{ 'cours.details.inscriptions.common.date' | translate }}
            </th>
            <th>{{ 'global.actions' | translate }}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let registration of waitingRegistrations">
            <td>{{ registration.id }}</td>
            <td>
              <a
                [routerLink]="['/participant', registration.participant.id]"
                [tooltip]="'tooltips.showParticipant' | translate"
                stopPropagation
              >
                {{ registration.participant.lastname }}
                {{ registration.participant.firstname }}
              </a>
            </td>
            <td>
              {{
              'global.enums.registrationStatus.' + registration.status
                | translate
              }}
            </td>
            <td>
              {{
              'global.enums.pricingMethod.' + registration.method
                | translate
              }}
            </td>
            <td>{{ registration.createdDate | date: 'dd.MM.yyyy' }}</td>
            <td>
              <button
                (click)="onRegCancel(registration)"
                [tooltip]="'global.cancel' | translate"
                class="btn btn-outline-warning ml-1"
                type="button"
              >
                <span><em class="far fa-ban"></em></span>
              </button>
              <button
                (click)="onPreRegValidation(registration)"
                [tooltip]="'global.register' | translate"
                class="btn btn-secondary ml-1"
                type="button"
              >
                <span><em class="far fa-user-plus"></em></span>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
        <ng-template #noWaitingRegistrations
        ><span>{{
          'cours.details.inscriptions.waiting.noWaitingFound' | translate
          }}</span></ng-template
        >
        <span *ngIf="waitingRegistrations?.length > 0"
        ><br/><strong>{{
          'cours.details.inscriptions.waiting.info' | translate
          }}</strong></span
        >
      </div>
    </div>
  </div>
</div>
