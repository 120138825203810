export class CoursType {

  public id: number;
  public name: string;
  public abacusName: string;
  public shortDescription: string;
  public longDescription: string;

  constructor(id: number, name: string, abacusName: string, shortDescription: string, longDescription: string) {
    this.id = id;
    this.name = name;
    this.abacusName = abacusName;
    this.shortDescription = shortDescription;
    this.longDescription = longDescription;
  }

  static fromJson(json: any = {}): CoursType {
    return new CoursType(
      json.id,
      json.name,
      json.abacusName,
      json.shortDescription,
      json.longDescription
    );
  }

}
