import {Component, Input} from '@angular/core';

@Component({
  selector: 'th[agc-sortable-column]',
  templateUrl: './sortable-column.component.html',
  styleUrls: ['./sortable-column.component.scss']
})
export class SortableColumnComponent {

  sortButtonsVisible = false;

  @Input()
  currentFieldToSort: string;

  @Input()
  sortingAsc: boolean;

  @Input()
  fieldToSort: string;

  @Input()
  columnTranslationKey: string;

  showSortButtons() {
    this.sortButtonsVisible = true;
  }

  hideSortButtons() {
    this.sortButtonsVisible = false;
  }
}
