import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {CoursStatus} from '../models/enums/cours-status.enum';
import {Seance} from '../models/seance';
import {Inscription} from '../models/inscription';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {LiteCours} from '../models/lite-cours';
import {Cours} from "../models/cours";

const API_ROUTE = '/api/cours';

@Injectable({
  providedIn: 'root'
})
export class CoursService {


  constructor(private readonly http: HttpClient, private translateService: TranslateService, private toasterService: ToastrService) {
  }

  getAll(status: CoursStatus, returnLite: boolean = true, coursTypeId?: number, locationId?: number): Observable<Cours[]> {
    if (status != null && coursTypeId != null && locationId != null) {
      return this.http.get(`${API_ROUTE}?status=${status}&returnLite=${returnLite}&coursTypeId=${coursTypeId}&locationId=${locationId}`).pipe(map((values: any) => values.map(l => new Cours(l))));
    } else if (status != null && coursTypeId != null) {
      return this.http.get(`${API_ROUTE}?status=${status}&returnLite=${returnLite}&coursTypeId=${coursTypeId}`).pipe(map((values: any) => values.map(l => new Cours(l))));
    } else if (status != null && locationId != null) {
      return this.http.get(`${API_ROUTE}?status=${status}&returnLite=${returnLite}&locationId=${locationId}`).pipe(map((values: any) => values.map(l => new Cours(l))));
    } else if (status != null) {
      return this.http.get(`${API_ROUTE}?status=${status}&returnLite=${returnLite}`).pipe(map((values: any) => values.map(l => new Cours(l))));
    } else {
    }
  }

  /**
   * set liteResponse to true in order to get LiteCours objects instead of a fully Cours
   */
  getAllByStatuses(statuses: CoursStatus[], liteResponse: boolean = false): Observable<LiteCours[]> {
    const params = new HttpParams().append('statuses', statuses.join(',')).append('lite', liteResponse.toString());
    if (liteResponse) {
      return this.http.get(`${API_ROUTE}/getAllByStatuses`, {params: params}).pipe(map((values: any) => values.map(l => new LiteCours(l))));
    } else {
      return this.http.get(`${API_ROUTE}/getAllByStatuses`, {params: params}).pipe(map((values: any) => values.map(l => new Cours(l))));
    }
  }

  getAllFromTrimester(year: number, trimester: number): Observable<LiteCours[]> {
    return this.http.get(`${API_ROUTE}/fromTrimester?year=${year}&trimester=${trimester}`).pipe(map((values: any) => values.map(l => new LiteCours(l))));
  }

  copyToTrimester(ids: number[], year: number, trimester: number): Observable<Cours[]> {
    let body = {
      ids: ids,
      year: year,
      trimester: trimester
    };
    return this.http.post(`${API_ROUTE}/copyToTrimester`, body).pipe(map((values: any) => values.map(l => new Cours(l))));
  }


  /**
   * Find a cours from it's ID.
   */
  getCours(coursId: number): Observable<Cours> {
    return this.http.get(`${API_ROUTE}/${coursId}`)
      .pipe(map(l => new Cours(l)));
  }

  /**
   * Find a cours from it's number.
   */
  getCoursByNumber(number: number): Observable<Cours> {
    return this.http.get(`${API_ROUTE}/number/${number}`)
      .pipe(map(l => new Cours(l)));
  }

  getCoursAnimatedBy(intervenantId: number, startDate: Date, endDate: Date): Observable<Cours[]> {
    return this.http.get(`${API_ROUTE}/animatedBy/${intervenantId}?startDate=${startDate.getTime()}&endDate=${endDate.getTime()}`)
      .pipe(map((values: any) => values.map(l => new Cours(l))));
  }

  getCoursByLocationFromTo(locationId: number, startDate: Date, endDate: Date, coursId: number): Observable<Cours[]> {
    return this.http.get(`${API_ROUTE}/fromLocation/${locationId}?startDate=${startDate.getTime()}&endDate=${endDate.getTime()}&coursId=${coursId}`)
      .pipe(map((values: any) => values.map(l => new Cours(l))));
  }

  deleteById(coursId: number): any {
    return this.http.delete(`${API_ROUTE}` + '/' + coursId, {observe: 'response'});
  }

  refresh(): any {
    return this.http.post(`${API_ROUTE}/refresh`, null);
  }

  saveCours(cours: Cours): Observable<Cours> {
    if (cours.id) {
      return this.http.put(`${API_ROUTE}/${cours.id}`, cours).pipe(map(l => new Cours(l)));
    } else {
      return this.http.post(API_ROUTE, cours).pipe(map(l => new Cours(l)));
    }
  }

  saveRegistrations(coursId: number, registrations: Inscription[]): Observable<Cours> {
    return this.http.post(`${API_ROUTE}/${coursId}/registrations`, registrations).pipe(map(l => new Cours(l)));
  }

  setStatus(coursId: number, status: CoursStatus): Observable<Cours> {
    return this.http.patch(`${API_ROUTE}/${coursId}/setStatus`, status).pipe(map(l => new Cours(l)));
  }


  addSeances(coursId: number, seances: Seance[]): Observable<Cours> {
    return this.http.put(`${API_ROUTE}/${coursId}/addSeances`, seances).pipe(map(l => new Cours(l)));
  }

  generateParticipations(coursId: number): Observable<HttpResponse<any>> {
    return this.http.get(`${API_ROUTE}/${coursId}/generateParticipations`, {
      responseType: 'blob',
      observe: 'response'
    });
  }

  archiveAll(selectedDate: Date): Observable<Cours[]> {
    return this.http.put(`${API_ROUTE}/archiveAll`, selectedDate).pipe(map((values: any) => values.map(l => new Cours(l))));
  }

  /**
   * Edits the teacher of every seance of this cours. Updating a teacher is part of TeacherService.
   */
  editTeacher(coursId: number, intervenantId: number) {
    return this.http.patch(`${API_ROUTE}/${coursId}/editTeacher/${intervenantId}`, null).pipe(map(l => new Cours(l)));
  }

  areSeancesValid(seances: Seance[]): boolean {
    let validDates: Date[] = [];
    for (let seance of seances) {

      // checks start/endDate
      if (seance.startDate == null) {
        this.toasterService.error(this.translateService.instant('toasters.error.wrongSeancesDate'));
        return false;
      }
      if (seance.endDate == null) {
        this.toasterService.error(this.translateService.instant('toasters.error.wrongSeancesDate'));
        return false;
      }

      // checks that seances dates are unique
      // !! it checks only the full date value !!

      if (!this.existsInArray(validDates, seance.startDate)) {
        validDates.push(seance.startDate);
      } else {
        this.toasterService.error(this.translateService.instant('toasters.error.multipleSeancesAtSameDate'));
        return false;
      }

      // checks that end time is greater than start time
      if (seance.startDate.getTime() >= seance.endDate.getTime()) {
        this.toasterService.error(this.translateService.instant('toasters.error.startGreaterThanEnd'));
        return false;
      }

      // checks that at least one teacher is set for each seance
      if (seance.animations.length < 1) {
        this.toasterService.error(this.translateService.instant('toasters.error.needAtLeastOneTeacher'));
        return false;
      }
    }
    return true;
  }

  getNbPlacesAvailable(cours: Cours) {
    let maxParticipations = 0;
    for (let seance of cours.seances) {
      let nbParticipations = seance.participations.length;
      if (nbParticipations > maxParticipations) {
        maxParticipations = nbParticipations;
      }
    }
    return cours.nbPlaces - maxParticipations;
  }

  private existsInArray(validDates: Date[], dateToBeChecked: Date): boolean {
    if (validDates.length == 0) {
      return false;
    }
    for (let currentDate of validDates) {
      if (currentDate.getTime() == dateToBeChecked.getTime()) {
        return true;
      }
    }
    return false;
  }
}
