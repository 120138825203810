<div class="modal-header">
  <h4 class="modal-title pull-left">
    {{ 'cours.copy.title' | translate }}
  </h4>
  <button
    (click)="bsModalRef.hide()"
    aria-label="Close"
    class="close pull-right"
    type="button"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div *ngIf="cours" class="modal-body">
  <div class="form-group row">
    <label class="col-xl-12 col-form-label"
    ><strong>{{ 'cours.copy.source' | translate }}: </strong></label
    >
    <label class="col-xl-12 col-form-label"
    >{{ 'global.cours' | translate }} "{{ selectedCours.coursType.name }}"
      (n°{{ selectedCours.number }}), {{ selectedCours.location.name }}
    </label>
    <label class="col-xl-12 col-form-label"
    >{{ 'global.begin' | translate }}:
      {{ selectedCours.startDate | date: 'dd.MM.yyyy' }}
      {{ 'global.at' | translate }}
      {{ selectedCours.startDate | date: 'HH:mm' }}
    </label>
  </div>
  <div class="form-group row">
    <label class="col-xl-12 col-form-label"
    ><strong>{{ 'cours.copy.destination' | translate }}: </strong></label
    >
    <div class="col-xl-12">
      <select
        class="form-control custom-select col-xl-12"
        [(ngModel)]="selectedDestinationCours"
      >
        <option *ngFor="let theCours of cours" [ngValue]="theCours"
        >{{ theCours.coursType.name }} |
          {{ theCours.number }} | {{ theCours.location.name }} | (trim. {{ theCours.trimester }})
          | {{theCours.startDate | date: 'EE HH:mm'}}</option
        >
      </select>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-xl-12 col-form-label"
    ><strong>{{ 'cours.copy.partToCopy' | translate }}</strong></label
    >
    <div class="col-xl-12">
      <div
        *ngIf="
          registrationsChecked && registrationsChecked.length > 0;
          else noRegistrations
        "
        class="table-responsive"
      >
        <table class="table table-bordered">
          <thead>
          <tr>
            <th>
              {{ 'cours.details.inscriptions.common.client' | translate }}
            </th>
            <th>{{ 'cours.status' | translate }}</th>
            <th>{{ 'cours.create.rate' | translate }}</th>
            <th>
              <button
                class="mr-1 mb-1 btn btn-outline-secondary"
                type="button"
                (click)="onAllChecked($event)"
              >
                {{ selectAllBtnTxt }}
              </button>
            </th>
          </tr>
          </thead>
          <tbody>
            <tr *ngFor="let object of registrationsChecked">
              <td>
                {{ object.registration.participant.lastname }}
                {{ object.registration.participant.firstname }}
              </td>
              <td>
                {{
                  'global.enums.registrationStatus.' +
                    object.registration.status | translate
                }}
              </td>
              <td>
                {{
                  'global.enums.pricingMethod.' + object.registration.method
                    | translate
                }}
              </td>
              <td>
                <div class="checkbox c-checkbox custom-auto-width">
                  <label class="custom-no-margin-bottom">
                    <input
                      type="checkbox"
                      [(checked)]="object.checked"
                      (change)="onChecked($event, object)"/>
                    <span class="far fa-check"></span
                  ></label>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <ng-template #noRegistrations
      >{{ 'cours.copy.noRegFound' | translate }}.
      </ng-template
      >
    </div>
  </div>
  <div *ngIf="participants" class="form-group row custom-flex-row col-xl-12">
    <div class="custom-margin-left">
      <label
        class="col-xl-12 col-form-label custom-margin-bottom custom-no-padding-bottom"
      ><strong
      >{{ 'cours.copy.addMoreClient' | translate }}...</strong
      ></label
      >
    </div>
    <div class="col-xl-12 custom-margin-bottom">
      <div class="custom-margin-bottom">
        <div class="col-xl-12">
          <app-static-typeahead
            (onStateChanged)="onParticipantStateChanged($event)"
            [dataSource]="participants"
            [field]="'search'"
            [placeholderText]="'person.client.search' | translate"
          >
          </app-static-typeahead>
        </div>
      </div>
      <div class="col-xl-12 custom-margin-bottom">
        <select
          *ngIf="selectedParticipant"
          class="form-control custom-select col-xl-12"
          [(ngModel)]="selectedPricingMethod"
          (ngModelChange)="onSelectMethod($event)"
        >
          <option
            *ngFor="let method of PricingMethod | keyvalue"
            [ngValue]="method.key"
          >{{ 'cours.create.rate' | translate }}
            {{ 'global.enums.pricingMethod.' + method.key | translate }}</option
          >
        </select>
      </div>
      <div class="custom-margin-left">
        <button
          class="btn btn-labeled btn-primary"
          type="button"
          [tooltip]="'cours.copy.addThisClient' | translate"
          placement="right"
          (click)="addRegistration()"
          [disabled]="isParticipantAddDisabled"
        >
          <span class="btn-label"><i class="fal fa-plus"></i></span>
          {{ 'cours.copy.addThisClient' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <!-- Cancel button -->
  <button
    class="mr-1 mb-1 btn btn-outline-secondary"
    type="button"
    (click)="bsModalRef.hide()"
  >
    {{ 'global.cancel' | translate }}
  </button>

  <!-- Save or Continue button -->
  <button
    class="mr-1 btn btn-labeled btn-success mb-1"
    type="button"
    (click)="onCopyRegistrations()"
  >
    <span class="btn-label"><i class="far fa-check"></i></span>
    {{ 'cours.copy.copyCopiedReg' | translate }}
  </button>
</div>
