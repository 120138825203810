import {Participation} from './participation';
import {SeanceStatus} from './enums/seance-status.enum';
import {LiteSeance} from './lite-seance';
import {Animation} from './animation';

export class Seance extends LiteSeance {
  public participations: Participation[] = [];
  public animations: Animation[] = [];

  constructor(data?: any) {
    super(data);
    if (data) {
      this.participations = data.participations?.map(p => new Participation(p));
      this.animations = data.animations?.map(a => new Animation(a));
    }
  }

  static fromJson(json: any = {}): Seance {
    return new Seance(json);
  }

  isCancelled(): boolean {
    return this.status == SeanceStatus.CANCELLED;
  }

}
