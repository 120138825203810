import {Component, OnInit} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {BsLocaleService} from 'ngx-bootstrap/datepicker';
import {Seance} from 'src/app/models/seance';
import {SeanceService} from 'src/app/services/seance.service';
import {Subject} from 'rxjs';
import {ParticipantService} from 'src/app/services/participant.service';
import {Participant} from 'src/app/models/participant';
import {Intervenant} from 'src/app/models/intervenant';
import {IntervenantService} from 'src/app/services/intervenant.service';
import {CoursDetailsComponent} from '../../cours/cours-details/cours-details.component';
import {TranslateService} from '@ngx-translate/core';
import {ParticipantPresence} from 'src/app/models/enums/participant-presence.enum';
import {Participation} from 'src/app/models/participation';
import {
  RegisterTrialStudentComponent
} from '../../cours/cours-details/register-trial-student/register-trial-student.component';
import {ToastrService} from 'ngx-toastr';
import {Cours} from "../../../models/cours";
import {SwalService} from "../../../services/swal.service";

@Component({
  selector: 'app-seance-edit',
  templateUrl: './seance-edit.component.html',
  styleUrls: ['./seance-edit.component.scss']
})
export class SeanceEditComponent implements OnInit {
  currentSeance: Seance;
  currentCours: Cours;

  // we don't care about the date, we just need the time
  dayOfSeance: Date;

  // date + time
  seancesStartDateTime: Date;
  // add one hour (default seance duration)
  seancesEndDateTime: Date;

  isSaveEditsDisabled: boolean = true;

  ParticipantPresence = ParticipantPresence;

  datepickerConfig = {
    containerClass: 'theme-angle',
    dateInputFormat: 'DD.MM.YYYY'
  };

  // Generic typeahead options (for both student and teacher)
  public asyncSelected: string = '';
  public typeaheadLoading: boolean = false;
  public typeaheadNoResults: boolean = false;

  // Teacher
  public selectedIntervenant: Intervenant;
  public intervenants: Intervenant[] = [];
  isTeacherAddDisabled: boolean = true;

  // Student
  public selectedStudent: Participant;
  public participants: Participant[] = [];
  isTrial: boolean = true;
  public isParticipantAddDisabled: boolean = true;
  public isSeanceFull: boolean = false;

  // Modal
  public onClose: Subject<boolean>;

  constructor(
    private swalService: SwalService,
    private seanceService: SeanceService,
    private modalService: BsModalService,
    private bsModalRef: BsModalRef,
    private participantService: ParticipantService,
    private intervenantService: IntervenantService,
    private toasterService: ToastrService,
    private localeService: BsLocaleService,
    private translateService: TranslateService
  ) {
    this.onClose = new Subject();
    this.participantService.getAll().subscribe((participants: Participant[]) => {
      this.participants = participants;
    });

    this.intervenantService.getAll().subscribe((intervenants: Intervenant[]) => {
      this.intervenants = intervenants;
    });
  }

  ngOnInit() {
    this.localeService.use('fr');
    if (this.getNbPlacesAvailable() == 0) {
      this.isSeanceFull = true;
    }
    this.dayOfSeance = new Date(this.currentSeance.startDate);
    this.seancesStartDateTime = this.currentSeance.startDate;
    this.seancesEndDateTime = this.currentSeance.endDate;
    this.currentSeance.participations.sort((a, b) => a.participant.lastname.localeCompare(b.participant.lastname));
    this.currentSeance.animations.sort((a, b) => a.intervenant.lastname.localeCompare(b.intervenant.lastname));
  }

  addParticipation() {
    if (!this.studentAlreadyInSeance()) {
      this.saveParticipationToCurrentSeance(this.selectedStudent);
    } else {
      this.toasterService.error(this.translateService.instant('toasters.error.clientAlreadyParticipating'));
    }
  }

  addAnimation() {
    if (!this.intervenantAlreadyInSeance()) {
      this.saveAnimationToCurrentSeance(this.selectedIntervenant);
    } else {
      this.toasterService.error(this.translateService.instant('toasters.error.teacherAlreadyAnimating'));
    }
  }

  studentAlreadyInSeance() {
    for (let participation of this.currentSeance.participations) {
      if (participation.participant.id === this.selectedStudent.id) {
        return true;
      }
    }
    return false;
  }

  intervenantAlreadyInSeance() {
    for (let animation of this.currentSeance.animations) {
      if (animation.intervenant.id === this.selectedIntervenant.id) {
        return true;
      }
    }
    return false;
  }

  saveParticipationToCurrentSeance(participant: Participant) {
    this.seanceService
      .addParticipation(this.currentSeance.id, participant.id, this.isTrial)
      .subscribe((seance: Seance) => {
        this.currentSeance = seance;
        this.isParticipantAddDisabled = true;
      });
  }

  saveAnimationToCurrentSeance(intervenants: Intervenant) {
    this.seanceService
      .addAnimation(this.currentSeance.id, intervenants.id)
      .subscribe((seance: Seance) => {
        this.currentSeance = seance;
        this.isTeacherAddDisabled = true;
        CoursDetailsComponent.disableTeacherEdit();
      });
  }

  close() {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

  deleteParticipation(participationId: number) {
    this.swalService.warning(() => {
      this.seanceService
        .deleteParticipation(this.currentSeance.id, participationId)
        .subscribe(response => {
          if (response.status === 200) {
            // tslint:disable-next-line: forin
            for (let index in this.currentSeance.participations) {
              let indexNbr = +index;
              let p = this.currentSeance.participations[indexNbr];
              if (p.id == participationId) {
                this.currentSeance.participations.splice(indexNbr, 1);
              }
            }
            this.isSeanceFull = false;
            this.toasterService.success(this.translateService.instant('toasters.success.participationDeleted'));
          }
        });
    }, null, 'Supprimer le participant', null, 'global.warningModal.confirmText');


  }

  deleteAnimation(animationId: number) {
    if (this.currentSeance.animations.length > 1) {
      this.swalService.warning(() => {
        this.seanceService
          .deleteAnimation(this.currentSeance.id, animationId)
          .subscribe(response => {
            if (response.status === 200) {
              // tslint:disable-next-line: forin
              for (let index in this.currentSeance.animations) {
                let indexNbr = +index;
                let a = this.currentSeance.animations[indexNbr];
                if (a.id == animationId) {
                  this.currentSeance.animations.splice(indexNbr, 1);
                }
              }
              if (this.currentSeance.animations.length == 1) {
                CoursDetailsComponent.enableTeacherEdit();
              }
              this.toasterService.success(this.translateService.instant('toasters.success.animationDeleted'));
            }
            // errors catched by http interceptor
          });
      }, null, 'Supprimer l\'intervenant', null, 'global.warningModal.confirmText');
    } else {
      this.toasterService.error(this.translateService.instant('toasters.error.removeLastAnimation'));
    }
  }

  studentStateChange(event: any) {
    if (event != null) {
      this.selectedStudent = event;
      this.updateViewState(event);
    } else {
      this.selectedStudent = null;
      this.updateViewState(event);
    }
  }

  intervenantStateChange(event: any) {
    if (event != null) {
      this.selectedIntervenant = event;
      this.isTeacherAddDisabled = false;
    } else {
      this.selectedIntervenant = null;
      this.isTeacherAddDisabled = true;
    }
  }

  /**
   * Will enable/disable some components that allows the user to add participations to the seance.
   */
  updateViewState(event: any) {
    let nbPlacesAvailable = this.getNbPlacesAvailable();
    if (event != null) {
      if (nbPlacesAvailable >= 1) {
        this.isParticipantAddDisabled = false;
        this.isSeanceFull = false;
      } else {
        this.isParticipantAddDisabled = true;
        this.isSeanceFull = true;
      }
    } else {
      this.isParticipantAddDisabled = true;
    }
  }

  onTrialChange($event: any) {
    this.isTrial = !this.isTrial;
  }

  registerTrialForNextSeances(participation: Participation) {
    this.bsModalRef.hide(); // dont call close() so we dont refresh the cours yet
    this._showSelectPricingModal(participation);
  }

  getNbPlacesAvailable(): number {
    return (
      this.currentCours.nbPlaces - this.currentSeance.participations.length
    );
  }

  /**
   * startTime change event
   */
  onStartTimeChange($event: Date) {
    let startMinutes = $event.getMinutes();
    let startHours = $event.getHours();
    this.currentSeance.startDate.setMinutes(startMinutes);
    this.currentSeance.startDate.setHours(startHours);
    this.isSaveEditsDisabled = this.checkSaveEditsDisabled();
  }

  /**
   * endTime change event
   */
  onEndTimeChange($event: Date) {
    let endMinutes = $event.getMinutes();
    let endHours = $event.getHours();
    this.currentSeance.endDate.setMinutes(endMinutes);
    this.currentSeance.endDate.setHours(endHours);
    this.isSaveEditsDisabled = this.checkSaveEditsDisabled();
  }

  /**
   * Fired when a date is selected in the date picker.
   * @param $event The seleted date
   */
  onDateChange($event: Date) {
    // avoid changes from datepicker initialization
    if ($event != null) {
      let day = $event.getDate();
      let month = $event.getMonth();
      let year = $event.getFullYear();

      this.seancesStartDateTime.setDate(day);
      this.seancesStartDateTime.setMonth(month);
      this.seancesStartDateTime.setFullYear(year);

      this.seancesEndDateTime.setDate(day);
      this.seancesEndDateTime.setMonth(month);
      this.seancesEndDateTime.setFullYear(year);
    }
  }

  saveSeance() {
    this.seanceService
      .setStartEndDates(
        this.currentSeance.id,
        this.seancesStartDateTime,
        this.seancesEndDateTime
      )
      .subscribe((seance: Seance) => {
        this.close();
      });
  }

  checkSaveEditsDisabled() {
    if (this.seancesStartDateTime.getTime() > this.seancesEndDateTime.getTime()) {
      this.translateService.get('toasters.warning.seanceIncorrect').subscribe(data => {
        this.toasterService.warning(data);
      });
      return true;
    }
    return false;
  }

  private _showSelectPricingModal(participation: Participation) {
    const config: any = {class: 'modal-lg'};
    const initialState: any = (config.initialState = {});
    config.backdrop = true;
    config.ignoreBackdropClick = true;
    initialState.currentSeance = participation.seance;
    initialState.currentParticipant = participation.participant;
    this.bsModalRef = this.modalService.show(RegisterTrialStudentComponent, config);
    this.bsModalRef.content.closeBtnName = 'Fermer';
    this.bsModalRef.content.onClose.subscribe(() => {
      this.close();
    });
  }
}
