<ng-container *ngIf="status == cours.status">
  <div class="card bg-danger-dark border-0">
    <div class="row align-items-center mx-0">
      <div class="col-4 text-center">
        <em class="fal fa-arrow-alt-left fa-2x"></em>
      </div>
      <div class="col-8 py-4 bg-danger rounded-right">
        <div class="h3 m-0 text-bold">{{ 'cours.currentCoursStatus' | translate}}</div>
        <div class="text-sm text-uppercase">Cours {{cours.number}}
          : {{ 'global.enums.coursStatus.' + cours.status | translate }} </div>
      </div>
    </div>
  </div>
</ng-container>
