<div *ngIf="dataSource?.length > 0" class="col-xl-12 mb-3 custom-no-margin" id="typeaheadContainer">
  <input
    [(ngModel)]="asyncSelected"
    [typeahead]="dataSource"
    (typeaheadLoading)="changeTypeaheadLoading($event)"
    (typeaheadNoResults)="changeTypeaheadNoResults($event)"
    (typeaheadOnSelect)="typeaheadOnSelect($event)"
    [typeaheadOptionsLimit]="20"
    [typeaheadOptionField]="field"
    [placeholder]="placeholderText"
    class="form-control"
  />
  <div *ngIf="typeaheadNoResults === true">
    <i class="fa fa-times"></i> {{ noResultText }}
  </div>
  <div *ngIf="typeaheadLoading">{{ loadingText }}</div>
</div>
