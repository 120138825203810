import {Component} from '@angular/core';
import {Cours} from '../../../models/cours';
import {CoursService} from '../../../services/cours.service';
import {CoursStatus} from '../../../models/enums/cours-status.enum';
import {ToastrService} from 'ngx-toastr';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-cours-status-updater',
  templateUrl: './cours-status-updater.component.html',
  styleUrls: ['./cours-status-updater.component.scss']
})
export class CoursStatusUpdaterComponent {

  cours: Cours;

  public coursUpdated = new Subject();

  constructor(private coursService: CoursService, private toastrService: ToastrService, public bsModalRef: BsModalRef, private translateService: TranslateService) {
  }

  public get CoursStatsEnums() {
    return CoursStatus;
  }

  updateCoursStatus(coursStatus: CoursStatus) {
    this.coursService.setStatus(this.cours.id, coursStatus).subscribe(cours => {
      this.cours = cours;
      this.toastrService.success(this.translateService.instant('toasters.success.statusUpdaterMessage', {
        'numeroCours': this.cours.number,
        'status': this.translateService.instant('global.enums.coursStatus.' + coursStatus)
      }), this.translateService.instant('toasters.success.statusUpdaterTitle'));
      this.coursUpdated.next(cours);
      this.bsModalRef.hide();
    })
  }

}
