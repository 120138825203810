import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
    name: 'role'
})
export class RolePipe implements PipeTransform {


    constructor(private readonly translate: TranslateService) {
    }

    transform(value: any, ...args: any[]): any {
        switch (value) {
            case 'ROOT':
                return this.translate.instant('global.role.root');
            case 'ADMIN':
                return this.translate.instant('global.role.admin');
            case 'WRITER':
                return this.translate.instant('global.role.writer');
            case 'READER':
                return this.translate.instant('global.role.reader');
            default:
                return value;
        }
    }

}
