<div class="modal-header">
  <h4 class="modal-title pull-left">
    Inscriptions :
    {{ currentCours.coursType.name }} (n° {{ currentCours.number }}) - {{ currentCours.location.name }} -
    du {{ currentCours.startDate | date: 'dd.MM.yyyy' }} au {{ currentCours.endDate | date: 'dd.MM.yyyy' }}
  </h4>
  <button
    (click)="closeModal()"
    aria-label="Close"
    class="close pull-right"
    type="button"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-6">
      <div class="card card-default">
        <div class="card-header">
          <h4>Inscriptions</h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <app-static-typeahead
                class="custom-no-margin-left"
                (onStateChanged)="onParticipantStateChanged($event)"
                [dataSource]="participants"
                [field]="'search'"
                [placeholderText]="'person.client.search' | translate"
              >
              </app-static-typeahead>
            </div>
            <div class="col-3">
              <select
                [(ngModel)]="selectedPricingMethod"
                class="form-control"
              >
                <option *ngFor="let method of PricingMethod | keyvalue"
                        [ngValue]="method.key">{{'global.enums.pricingMethod.' + method.key | translate}}</option>
              </select>
            </div>

            <div class="col-3">
              <button
                [disabled]="!(selectedStudent && selectedPricingMethod)"
                type="button"
                (click)="addRegistration(RegistrationStatus.REGISTERED)"
                class="btn btn-primary"
              >
                <i class="fal fa-plus"></i>
              </button>
            </div>

          </div>

        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="card card-default">
        <div class="card-header">
          <h4>Liste d'attente</h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <app-static-typeahead
                class="custom-no-margin-left"
                (onStateChanged)="onWaitingParticipantStateChanged($event)"
                [dataSource]="participants"
                [field]="'search'"
                [placeholderText]="'person.client.search' | translate"
              >
              </app-static-typeahead>
            </div>
            <div class="col-3">
              <select
                [(ngModel)]="selectedWaitingPricingMethod"
                class="form-control"
              >
                <option *ngFor="let method of PricingMethod | keyvalue" [ngValue]="method.key">{{
                  'global.enums.pricingMethod.' + method.key | translate
                  }}</option>
              </select>
            </div>
            <div class="col-3">
              <button
                [disabled]="!(selectedWaitingStudent &&selectedWaitingPricingMethod)"
                type="button"
                (click)="addRegistration(RegistrationStatus.WAITING)"
                class="btn  btn-primary"
              >
                <i class="fal fa-plus"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>

  <div class="row">
    <div class="col-xl-12">
      <div class="card card-default">
        <div class="card-header">
          <h4 class="page-header">
            Participants à inscrire ({{inscriptionsToSave.length}})
          </h4>
          <div class="float-right">
            <button
              (click)="saveRegistrations()"
              [disabled]="inscriptionsToSave?.length == 0"
              class="btn btn-labeled btn-success"
              type="button"
            >
              <span class="btn-label"><i class="fal fa-save"></i></span>
              Ajouter les participants
            </button>
          </div>

        </div>
        <div *ngIf="inscriptionsToSave?.length > 0" class="card-body">
          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
              <tr>
                <th>
                  {{
                  'cours.details.inscriptions.common.client'
                    | translate
                  }}
                </th>
                <th>{{ 'cours.status' | translate }}</th>
                <th>{{ 'cours.create.rate' | translate }}</th>
                <th>{{ 'global.actions' | translate }}</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let registration of inscriptionsToSave">
                <td>
                  {{ registration.participant.lastname }}
                  {{ registration.participant.firstname }}
                </td>
                <td>
                  {{
                  'global.enums.registrationStatus.' +
                  registration.status | translate
                  }}
                </td>
                <td>
                  {{
                  'global.enums.pricingMethod.' + registration.method
                    | translate
                  }}
                </td>
                <td>
                  <button
                    (click)="onRemove(registration)"
                    [tooltip]="'global.delete' | translate"
                    type="button"
                  >
                    <span><em class="fas fa-trash"></em></span>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  </div>


  <!-- Register students list -->
  <div class="row">
    <div class="col-xl-12">
      <div class="card card-default">
        <div class="card-body">
          <h4 class="page-header">
            Participants au cours ({{currentCours?.inscriptions?.length}})
          </h4>
          <div class="table-responsive">
            <table
              *ngIf="
                currentCours?.inscriptions?.length > 0;
                else noRegistrations
              "
              class="table table-hover"
            >
              <thead>
              <tr>
                <th>Identifiant métier</th>
                <th>
                  {{
                  'cours.details.inscriptions.common.client' | translate
                  }}
                </th>
                <th>{{ 'cours.status' | translate }}</th>
                <th>{{ 'cours.create.rate' | translate }}</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let registration of currentCours.inscriptions">
                <td>
                  {{ registration.participant.abacusNumber }}
                <td>
                  {{ registration.participant.lastname }}
                  {{ registration.participant.firstname }}
                </td>
                <td>
                  {{
                  'global.enums.registrationStatus.' + registration.status
                    | translate
                  }}
                </td>
                <td>
                  {{
                  'global.enums.pricingMethod.' + registration.method
                    | translate
                  }}
                </td>
              </tr>
              </tbody>
            </table>
            <ng-template #noRegistrations
            ><span>{{
              'cours.details.inscriptions.normal.noFound' | translate
              }}</span></ng-template
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
