<!-- Square footer for web version -->
<div *ngIf="config" class="row web-footer">
  <div class="col-xl-9">
    <span class="hidden-in-print"
    >Square Services Sàrl &copy; {{ settings.getAppSetting('year') }} - Env:
    {{ serverInfo?.environment }} - Version: {{ serverInfo?.version }} - Build:
    {{ serverInfo?.buildTime | date: 'dd.MM.yyyy HH:mm' }} - Uptime:
    {{ serverInfo?.startTime | amTimeAgo }}</span
  >
  </div>
</div>

