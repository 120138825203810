import {CoursType} from './cours-type';
import {Pricing} from './pricing';
import {CoursStatus} from './enums/cours-status.enum';
import {LiteLocation} from './lite-location';
import {LiteIntervenant} from './lite-intervenant';

export class LiteCours {

  public id: number;
  public number: number;
  public nbPlaces: number;
  public shortDescription: string;
  public longDescription: string;
  public status: CoursStatus;
  public pricings: Pricing[];
  public trimester: number;
  public year: number;
  public startDate: Date;
  public endDate: Date;
  public coursType: CoursType;
  public location: LiteLocation;

  public seancesCount: number;
  public intervenant: LiteIntervenant;


  public hasTrialPeopleInFuture: boolean;


  constructor(
    data?: any
  ) {
    this.id = data?.id;
    this.number = data?.number;
    this.nbPlaces = data?.nbPlaces;
    this.shortDescription = data?.shortDescription;
    this.longDescription = data?.longDescription;
    this.status = data?.status;
    this.pricings = data?.pricings;
    this.trimester = data?.trimester;
    this.year = data?.year;
    this.startDate = data?.startDate ? new Date(data?.startDate) : null;
    this.endDate = data?.endDate ? new Date(data?.endDate) : null;
    this.coursType = data?.coursType;
    this.location = data?.location;
    this.seancesCount = data?.seancesCount;
    this.intervenant = new LiteIntervenant(data?.intervenant);
    this.hasTrialPeopleInFuture = data?.hasTrialPeopleInFuture;
  }

}
