import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Intervenant} from '../models/intervenant';

const API_ROUTE = '/api/intervenant';

@Injectable({
  providedIn: 'root'
})
export class IntervenantService {

  private isDisabledOnly: boolean = false;

  constructor(private readonly http: HttpClient) {
  }

  saveIntervenant(intervenant: Intervenant): Observable<Intervenant> {
    if (intervenant.id) {
      return this.http.put(`${API_ROUTE}/${intervenant.id}`, intervenant).pipe(map(i => new Intervenant(i)));
    } else {
      return this.http.post(API_ROUTE, intervenant).pipe(map(i => new Intervenant(i)));
    }
  }

  disableIntervenant(intervenantId: number): any {
    return this.http.put(`${API_ROUTE}/${intervenantId}/disable`, null, {observe: 'response'});
  }

  enableIntervenant(intervenantId: number): any {
    return this.http.put(`${API_ROUTE}/${intervenantId}/enable`, null, {observe: 'response'});
  }

  getAll(isDisabledOnly: boolean = false): Observable<Intervenant[]> {
    let params = new HttpParams();
    params = params.set('isDisabledOnly', isDisabledOnly.toString());
    return this.http.get(`${API_ROUTE}`, {params: params}).pipe(map((values: any) => values.map(i => new Intervenant(i))));
  }

  getIntervenantById(intervenantId: number): Observable<Intervenant> {

    return this.http.get(`${API_ROUTE}/${intervenantId}`)
      .pipe(map(i => new Intervenant(i)));
  }

  /*
  * MAKE SURE YOU WANT THIS INSTEAD OF "disableTeacher(intervenant: Teacher)" method.
  * REMOVES DEFINETLY A RECORD FROM THE DB.
  */
  deleteById(intervenantId: number) {
    return this.http.delete(`${API_ROUTE}` + '/' + intervenantId, {observe: 'response'});
  }

  getIsDisabledOnly(): boolean {
    return this.isDisabledOnly;
  }

  setIsDisabledOnly(isDisabledOnly: boolean) {
    this.isDisabledOnly = isDisabledOnly;
  }
}
