<div class="wrapper">
  <div class="block-center mt-4 wd-xl">
    <!-- START card-->
    <div class="card card-flat">
      <div class="card-header text-center bg-info">
        <!--
          <img alt="Image" class="block-center" src="assets/img/square.png"/>
        -->
        <label>AGC</label>
      </div>
      <div class="card-body">
        <form
          (submit)="submitForm($event, valForm.value)"
          [formGroup]="valForm"
          class="form-validate mb-3"
          name="loginForm"
          novalidate=""
          role="form"
        >
          <div class="form-group">
            <div class="input-group with-focus">
              <input
                autocomplete="off"
                class="form-control border-right-0"
                formControlName="email"
                id="exampleInputEmail1"
                name="email"
                placeholder="{{ 'login.emailPlaceholder' | translate }}"
                required=""
                type="email"
              />
              <div class="input-group-append">
                <span
                  class="input-group-text text-muted bg-transparent border-left-0"
                >
                  <em class="fal fa-envelope"></em>
                </span>
              </div>
            </div>
            <div
              *ngIf="
                valForm.controls['email'].hasError('required') &&
                (valForm.controls['email'].dirty ||
                  valForm.controls['email'].touched)
              "
              class="text-danger"
            >
              {{ 'form.mandatoryField' | translate }}
            </div>
            <div
              *ngIf="
                valForm.controls['email'].hasError('email') &&
                (valForm.controls['email'].dirty ||
                  valForm.controls['email'].touched)
              "
              class="text-danger"
            >
              {{ 'login.emailNotValid' | translate }}
            </div>
          </div>
          <div class="form-group">
            <div class="input-group with-focus">
              <input
                class="form-control border-right-0"
                formControlName="password"
                id="exampleInputPassword1"
                name="password"
                placeholder="{{ 'login.passwordPlaceholder' | translate }}"
                required=""
                type="password"
              />
              <div class="input-group-append">
                <span
                  class="input-group-text text-muted bg-transparent border-left-0"
                >
                  <em class="fal fa-lock"></em>
                </span>
              </div>
            </div>
            <div
              *ngIf="
                valForm.controls['password'].hasError('required') &&
                (valForm.controls['password'].dirty ||
                  valForm.controls['password'].touched)
              "
              class="text-danger"
            >
              {{ 'form.mandatoryField' | translate }}
            </div>
          </div>

          <div class="form-group">
            <div class="input-group with-focus">
              <input class="form-control border-right-0" formControlName="otp"
                     id="otp" name="otp"
                     placeholder="On time password"
                     type="text"/>
              <div class="input-group-append">
                                <span class="input-group-text text-muted bg-transparent border-left-0">
                                    <em class="fal fa-lock"></em>
                                </span>
              </div>
            </div>
          </div>

          <img *ngIf="qrCode" [src]="qrCode" width="70%">


          <button class="btn btn-block btn-info mt-3" type="submit">
            <em class="fal fa-sign-in"></em> {{ 'login.button' | translate }}
          </button>
        </form>
      </div>
    </div>

    <a [routerLink]="['/recover']">Mot de passe perdu</a>

    <!-- END card-->
    <div class="p-3 text-center">
      <span>&copy;</span>
      <span>{{ settings.getAppSetting('year') }}</span>
      <span class="mx-2">-</span>
      <span>{{ settings.getAppSetting('name') }}</span>
      <br />
      <span>{{ settings.getAppSetting('description') }}</span>
    </div>
  </div>
</div>
