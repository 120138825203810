import {LiteSeance} from './lite-seance';
import {IntervenantPresence} from './enums/intervenant-presence.enum';

export class LiteAnimation {

  public id: number;
  public seance: LiteSeance;
  public intervenantPresence: IntervenantPresence;

  constructor(data?: any) {
    this.id = data?.id;
    this.seance = data?.seance;
    this.intervenantPresence = data?.intervenantPresence;
  }

  static fromJson(json: any = {}): LiteAnimation {
    return new LiteAnimation(
      json
    );
  }
}
