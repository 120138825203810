import {Injectable} from '@angular/core';
import {ConfigService} from 'src/app/services/config.service';
import {Config} from 'src/app/models/config';
import {AgcProfile} from 'src/app/models/enums/agc-profile-enum';
import {AsyncSubject, Observable} from 'rxjs';

@Injectable()
export class MenuService {

  menuItems: Array<any>;

  private configSubject = new AsyncSubject<Array<any>>();

  constructor(private configService: ConfigService) {
    this.menuItems = [];
  }

  addMenu(items: Array<{
    text: string,
    heading?: boolean,
    link?: string,     // internal route links
    elink?: string,    // used only for external links
    target?: string,   // anchor target="_blank|_self|_parent|_top|framename"
    icon?: string,
    fa?: string,
    alert?: string,
    submenu?: Array<any>,
    displayOnly?: AgcProfile
  }>) {
    this.configService.getConfig().subscribe((config: Config) => {
      for (let item of items) {
        if (item.displayOnly && item.displayOnly.length > 0) {
          if (config.activeProfiles.includes(item.displayOnly)) {
            this.menuItems.push(item);
          }
        } else {
          // if displayOnly property is not specified, add the menu.
          this.menuItems.push(item);
        }
      }
      this.configSubject.next(this.menuItems);
      this.configSubject.complete();
    });
  }

  getMenu(): Observable<Array<any>> {
    return this.configSubject.asObservable();
  }

}
