import {PricingMethod} from './enums/pricing-method.enum';
import {RegistrationExportStatus} from './enums/registration-export-status.enum';
import {RegistrationStatus} from './enums/registration-status.enum';
import {LiteParticipant} from './lite-participant';

export class LiteInscription {
  public id: number;
  public status: RegistrationStatus;
  public exportStatus: RegistrationExportStatus;
  public exportDate: Date;
  public method: PricingMethod;
  public validationDate: Date;
  public createdDate: Date;
  public participant: LiteParticipant;
  public billingAddress: string;
  public certificateGenerationDate: Date;

  constructor(data?: any) {
    this.id = data?.id;
    this.status = data?.status;
    this.exportStatus = data?.exportStatus;
    this.exportDate = new Date(data?.exportDate);
    this.method = data?.method;
    this.validationDate = data?.validationDate;
    this.createdDate = new Date(data?.createdDate);
    this.participant = new LiteParticipant(data?.participant);
    this.billingAddress = data?.billingAddress;
    this.certificateGenerationDate = data?.certificateGenerationDate;
  }
}
