import {AgcProfile} from '../models/enums/agc-profile-enum';

const Cours = {
  text: 'Liste des cours',
  translate: 'global.menus.cours.list',
  link: '/cours',
  icon: 'fa fa-list'
};

const Presence = {
  text: 'Saisie des présences',
  translate: 'global.menus.cours.presences',
  link: '/presence',
  fa: 'fal fa-tasks'
};

const Participants = {
  text: 'Liste des participants',
  translate: 'global.menus.participant.list',
  link: '/participant',
  fa: 'fal fa-users'
};

const BillingLvr = {
  text: 'Facturation',
  translate: 'global.menus.participant.billing',
  link: '/billing',
  fa: 'fal fa-dollar-sign',
  displayOnly: AgcProfile.LVR_BILLING
};

const ImportStudent = {
  text: 'Importer depuis Abacus',
  translate: 'global.menus.participant.import',
  link: '/participant/import',
  fa: 'fal fa-upload',
  displayOnly: AgcProfile.IMPORT_STUDENT
};

const BexioImport = {
  text: 'Import Bexio',
  link: '/bexio/import',
  fa: 'fal fa-upload',
  displayOnly: AgcProfile.BEXIO
};

const BexioInvoice = {
  text: 'Facturation Bexio',
  link: '/bexio/invoice',
  fa: 'fal fa-dollar-sign',
  displayOnly: AgcProfile.BEXIO
};

const Intervenant = {
  text: 'Liste des intervenants',
  translate: 'global.menus.admin.list',
  link: '/intervenant',
  fa: 'fal fa-users'
};

const Location = {
  text: 'Liste des lieux',
  translate: 'global.menus.location.list',
  link: '/location',
  fa: 'fal fa-city'
};

const StatsLVR = {
  text: 'Statistiques LVR',
  translate: 'global.menus.stats.list',
  link: '/stats',
  fa: 'fal fa-chart-pie',
  displayOnly: AgcProfile.LVR_STATS
};

const User = {
  text: 'Administrateurs AGC',
  translate: 'global.menus.config.agc',
  link: '/user',
  icon: 'fal fa-users'
};


const headingCours = {
  text: 'Gestion des cours',
  translate: 'global.menus.cours.heading',
  heading: true
};

const headingStudents = {
  text: 'Gestion des clients',
  translate: 'global.menus.participant.heading',
  heading: true
};

const headingBackoffice = {
  text: 'Suivi administratif',
  translate: 'global.menus.admin.heading',
  heading: true
};

const CoursType = {
  text: 'Types de cours',
  translate: 'global.menus.cours.types',
  link: '/cours-type',
  fa: 'fal fa-tag'
};

const configMenu = {
  text: 'Configuration',
  translate: 'global.menus.config.heading',
  heading: true
}

export const menu = [
  headingCours,
  Cours,
  Presence,
  headingStudents,
  Participants,
  BexioImport,
  BillingLvr,
  BexioInvoice,
  ImportStudent,
  headingBackoffice,
  Intervenant,
  Location,
  StatsLVR,
  configMenu,
  CoursType,
  User
];
