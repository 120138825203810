export enum RegistrationExportStatus {
  NOT_EXPORTED = 'NOT_EXPORTED', // special for LVR who exports registrations in Abacus to manage billings
  EXPORTED = 'EXPORTED', // special for LVR who exports registrations in Abacus to manage billings

  NOT_ENTERED = 'NOT_ENTERED', // has not been entered in bexio & facturation address has not been chosen.
  WAITING = 'WAITING', // has not been entered in Bexio but is ready to.
  ENTERED = 'ENTERED', // has been entered in Bexio
  CANCELLED = 'CANCELLED', // has been cancelled
  ARCHIVED = 'ARCHIVED' // has been archived
}
