import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {Config} from '../models/config';

const API_ROUTE = '/api/config';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  config: Config = null;
  configSubject = new BehaviorSubject<Config>(null);

  constructor(private readonly http: HttpClient) {

  }

  /**
   * If the config has already been loaded, it returns the existing config. Otherwise, the config is loaded.
   */
  getConfig(): Observable<Config> {
    return new Observable((observer) => {
      if (this.config != null) {
        // using existing config
        observer.next(this.config);
      } else {
        // load config
        this.http.get(API_ROUTE).subscribe((data: any) => {
          this.config = new Config(data);
          observer.next(this.config);
        });
      }
    });
  }

  /**
   * Unused yet
   */
  refreshConfig(): Observable<Config> {
    this.config = null;
    return this.getConfig();
  }

}
