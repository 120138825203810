import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {Inscription} from 'src/app/models/inscription';
import {InscriptionService} from 'src/app/services/inscription.service';
import {LiteInscription} from 'src/app/models/lite-inscription';
import {ToastrService} from 'ngx-toastr';
import {SwalService} from '../../../services/swal.service';


@Component({
  selector: 'app-billing-details',
  templateUrl: './billing-details.component.html',
  styleUrls: ['./billing-details.component.scss']
})
export class BillingDetailsComponent implements OnInit {

  inscription: Inscription;
  registrationExtended: any;

  selectedBillingAddress: string = '';

  saveDisabled: boolean = true;

  public onClose: Subject<boolean>;


  constructor(public bsModalRef: BsModalRef,
              private toasterService: ToastrService,
              private swalService: SwalService,
              private inscriptionService: InscriptionService,
              private translateService: TranslateService) {
  }

  ngOnInit() {
    this.onClose = new Subject();
    this.registrationExtended = JSON.parse(JSON.stringify(this.inscription));
    this.registrationExtended.coursFormatted = this.inscription.cours.coursType.name + ' (n°' + this.inscription.cours.number + ') - ' + this.inscription.cours.seances.length + ' ' + this.translateService.instant('global.seance') + '(s)';
  }

  saveRegistration() {
    this._showSaveConfirmationModal();
  }

  onBillingAddressChange(value: string) {
    if (this.selectedBillingAddress === "") {
      this.saveDisabled = true;
    } else {
      if (this.selectedBillingAddress == this.registrationExtended.billingAddress) {
        this.saveDisabled = true;
      } else {
        this.saveDisabled = false;
      }
    }
  }

  _showSaveConfirmationModal() {
    //let text= this.translateService.instant('global.warningModal.saveBilling.text', { newBillingAddress: this.selectedBillingAddress }),

    let params: any[] = [
      {
        in: "text",
        key: "newBillingAddress",
        value: this.selectedBillingAddress
      }
    ];
    this.swalService.warning(() => {
      this._onSwalConfirm()
    }, null, 'global.warningModal.saveBilling.text', null, 'global.warningModal.saveBilling.confirmText', params);

  }

  _onSwalConfirm() {
    this.inscription.billingAddress = this.selectedBillingAddress;
    this.inscriptionService.editInscription(this.inscription).subscribe(($registration: LiteInscription) => {
      if ($registration) {
        this.toasterService.success(this.translateService.instant('toasters.success.registrationUpdated'));
      }
      this.onClose.next(true);
      this.bsModalRef.hide();
    });
  }

}
