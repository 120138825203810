import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';
import {InscriptionService} from 'src/app/services/inscription.service';
import {LiteInscription} from 'src/app/models/lite-inscription';

@Component({
  selector: 'app-waiting-list-registration',
  templateUrl: './waiting-list-registration.component.html',
  styleUrls: ['./waiting-list-registration.component.scss']
})
export class WaitingListRegistrationComponent implements OnInit {

  public onClose: Subject<boolean>;

  nbPlacesAvailable: number;
  waitingList: LiteInscription[];
  inscriptionsToUpdate: LiteInscription[] = [null];
  isAddDisabled: boolean = true;
  isSaveDisabled: boolean = true;

  showError: boolean = false;

  constructor(
    public bsModalRef: BsModalRef,
    public inscriptionService: InscriptionService
  ) {
  }

  ngOnInit() {
    this.onClose = new Subject();
  }

  _closeModal() {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

  createInscriptions() {
    let idsToUpdate = this.inscriptionsToUpdate.map(reg => reg.id);
    this.inscriptionService.setAllValidated(idsToUpdate).subscribe(($inscriptions: LiteInscription[]) => {
      this._closeModal();
    });
  }

  onWaitingListSelect(inscription: LiteInscription, i: number) {
    this.showError = false;
    if (!this.hasBeenAdded(inscription.id)) {
      this.inscriptionsToUpdate[i] = inscription;
      this.isSaveDisabled = false;
      this.nbPlacesAvailable--;
      this.checkAddBtnState();

    } else {
      this.showError = true;
    }
  }


  onAddInscriptions() {
    this.inscriptionsToUpdate.push(null);
    this.isSaveDisabled = true;
    this.checkAddBtnState();
  }

  checkAddBtnState() {
    // disable the button if the cours is full or if all awaiting inscriptions has been added
    if (this.nbPlacesAvailable == 0 || this.waitingList.length == this.inscriptionsToUpdate.length) {
      this.isAddDisabled = true;
    } else {
      this.isAddDisabled = false;
    }
  }

  hasBeenAdded(inscriptionId: number): boolean {
    if (this.inscriptionsToUpdate.length == 1 && this.inscriptionsToUpdate[0] == null) {
      return false;
    }
    for (let inscription of this.inscriptionsToUpdate) {
      if (inscription != null) {
        if (inscription.id == inscriptionId) {
          return true;
        }
      }
    }
    return false;
  }
}
