import {Gender} from './enums/gender.enum';
import {formatDate} from '@angular/common';

export class Person {

  public id: number;
  public abacusNumber: number;
  public bexioId: number;
  public bexioNr: number;
  public businessId: number;
  public lastname: string;
  public firstname: string;
  public gender: Gender;
  public email: string;
  public privatePhone: string;
  public mobilePhone: string;
  public profPhone: string;
  public address: string;
  public locality: string;
  public zip: string;
  public birthDate: Date;
  public enabled: boolean;

  constructor(data?: any) {
    this.id = data?.id;
    this.abacusNumber = data?.abacusNumber;
    this.bexioId = data?.bexioId;
    this.bexioNr = data?.bexioNr;
    this.businessId = data?.businessId;
    this.lastname = data?.lastname;
    this.firstname = data?.firstname;
    this.gender = data?.gender;
    this.email = data?.email;
    this.privatePhone = data?.privatePhone;
    this.mobilePhone = data?.mobilePhone;
    this.profPhone = data?.profPhone;
    this.address = data?.address;
    this.locality = data?.locality;
    this.zip = data?.zip;
    this.birthDate = data?.birthDate ? new Date(data?.birthDate) : null;
    this.enabled = data?.enabled;
  }


  static formatBirthDate(birthDate: Date): string {
    return formatDate(birthDate, 'dd.MM.yyyy', 'fr');
  }

  public getFullname(): string {
    return this.firstname + " " + this.lastname;
  }

}
