import {Injectable} from '@angular/core';

import {JwtHelperService} from '@auth0/angular-jwt';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';

import {User} from '../models/user';
import {TranslatorService} from '../core/translator/translator.service';
import {ConfigService} from '../services/config.service';

const TOKEN_KEY = 'agc_token';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private connected = new BehaviorSubject(false);
  private theUserSubject = new BehaviorSubject<User>(null);

  constructor(private jwtHelper: JwtHelperService,
              private http: HttpClient,
              private router: Router,
              private translatorService: TranslatorService,
              private configService: ConfigService
  ) {
    this.auth();
  }

  // public login(username: string, password: string): void {
  //   this.http.post('/jwtlogin', { 'username': username, 'password': password }, {
  //     observe: 'response',
  //     responseType: 'text'
  //   }).pipe(map(response => {
  //     const jwtToken = response.headers.get('Authorization');
  //     return jwtToken;
  //   })).subscribe((jwt: string) => {
  //     localStorage.setItem(TOKEN_KEY, jwt);
  //     this.connected.next(true);
  //     this._loadProfile();
  //   },
  //     error => {
  //       this.connected.next(false);
  //     });
  // }

  public login(username: string, password: string, otp: number): void {
    this.http.post('/jwtlogin', {'username': username, 'password': password, 'otp': otp}, {
      observe: 'response',
      responseType: 'text'
    }).pipe(map(response => {
      const jwtToken = response.headers.get('Authorization');
      return jwtToken;
    })).subscribe((jwt: string) => {
        localStorage.setItem(TOKEN_KEY, jwt);
        this.connected.next(true);
        this._loadProfile();
      },
      error => {
        this.connected.next(false);
      });
  }

  public auth(): void {
    const token = localStorage.getItem(TOKEN_KEY);

    // Check whether the token is expired and return
    // true or false
    if (token && !this.jwtHelper.isTokenExpired(token)) {
      this.connected.next(true);
      this._loadProfile();
    } else {
      localStorage.removeItem(TOKEN_KEY);
      this.connected.next(false);
    }
  }

  public logout() {
    localStorage.removeItem(TOKEN_KEY);
    location.href = '/';
  }

  getLoggedUser() {
    return this.theUserSubject;
  }

  // ...
  public isAuthenticated(): boolean {
    return this.connected.getValue();
  }

  public getConnectedObservable() {
    return this.connected.asObservable();
  }

  impersonate(userId: number) {
    return this.http.post('/api/user/impersonate', userId).pipe(map(() => {
      return this._loadProfile();
    }));
  }

  impersonateLogout() {
    return this.http.get('/api/user/impersonate/logout').pipe(map(() => {
      return this._loadProfile();
    }));
  }

  sendNewPassword(username: string) {
    return this.http.post('/api/lost-password', username);
  }

  updatePassword(current: any, newPassword: any) {
    const passwords: any = {};
    passwords.currentPassword = current;
    passwords.newPassword = newPassword;
    return this.http.patch('/api/profile/newPassword', passwords);
  }


  updateUser(user: User) {
    return this.http.patch('/api/profile', user).pipe(map((data: any) => (this._loadProfile())));
  }

  private _loadProfile() {
    this.http.get('/api/profile').pipe(map(User.fromJson)).subscribe((data: User) => {
      this.configService.getConfig().subscribe(config => {
        this.translatorService.useLanguage(data.preferredLang);
        this.theUserSubject.next(data);
      })
    });
  }

  verifyOtp(otp: string) {
    return this.http.post('/api/otp/verify', otp);
  }

  enabledOtp(username: string) {
    return this.http.post('/api/otp/enabled', username);
  }

  public otp(username: string) {
    return this.http.post('/api/otp/setup', username, {
      observe: 'response',
      responseType: 'text',
    }).pipe(map(response => {
      const qrCode = response.body;
      return qrCode;
    }));
  }
}
