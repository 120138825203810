import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

const API_ROUTE = '/api/info';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private readonly http: HttpClient) {

  }

  getApiInfo(): any {
    return this.http.get(API_ROUTE);
  }

}
