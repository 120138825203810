import {Component, OnInit} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {LiteInscription} from 'src/app/models/lite-inscription';
import {RegistrationStatus} from 'src/app/models/enums/registration-status.enum';
import {Subject} from 'rxjs';
import {CoursStatus} from 'src/app/models/enums/cours-status.enum';
import {Inscription} from 'src/app/models/inscription';
import {Participant} from 'src/app/models/participant';
import {ParticipantService} from 'src/app/services/participant.service';
import {PricingMethod} from 'src/app/models/enums/pricing-method.enum';
import {BsLocaleService} from 'ngx-bootstrap/datepicker';
import {InscriptionService} from 'src/app/services/inscription.service';
import {TranslateService} from '@ngx-translate/core';
import {ConfigService} from 'src/app/services/config.service';
import {Config} from 'src/app/models/config';
import {ToastrService} from 'ngx-toastr';
import {RegistrationExportStatus} from 'src/app/models/enums/registration-export-status.enum';
import {LiteCours} from 'src/app/models/lite-cours';
import {CoursService} from "../../../services/cours.service";
import {Cours} from "../../../models/cours";

@Component({
  selector: 'app-cours-copy-registrations',
  templateUrl: './cours-copy-registrations.component.html',
  styleUrls: ['./cours-copy-registrations.component.scss']
})
export class CoursCopyRegistrationsComponent implements OnInit {
  public onClose: Subject<boolean>;
  cours: Cours[];
  selectedCours: Cours;
  selectedDestinationCours: Cours = null;
  registrationsChecked: any[];

  participants: Participant[];
  selectedParticipant: Participant;
  isParticipantAddDisabled = true;
  selectedPricingMethod: PricingMethod = PricingMethod.NORMAL;

  PricingMethod = PricingMethod;

  isSelectAll = false;

  selectAllBtnTxt;

  config: Config;

  datepickerConfig = {
    containerClass: 'theme-angle',
    dateInputFormat: 'DD.MM.YYYY'
  }

  constructor(
    private coursService: CoursService,
    private inscriptionService: InscriptionService,
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private toasterService: ToastrService,
    private participantService: ParticipantService,
    private localeService: BsLocaleService,
    private translateService: TranslateService,
    private configService: ConfigService
  ) { }

  ngOnInit() {
    this.configService.getConfig().subscribe(config => {
      this.config = config;
    });
    this.localeService.use('fr');
    this.onClose = new Subject();
    this.coursService
      .getAllByStatuses([
        CoursStatus.REGISTRATIONS_OPENED
      ])
      .subscribe((cours: LiteCours[]) => {
        this.cours = this.removeSelectedCours(cours as Cours[]);
      });
    this.participantService.getAll().subscribe((participants: Participant[]) => {
      this.participants = participants;
    });
    this.initRegistrations();
    this.setBtnTitle();
  }

  removeSelectedCours(cours: Cours[]): Cours[] {
    return cours.filter(o => {
      return o.id !== this.selectedCours.id;
    });
  }

  initRegistrations() {
    this.registrationsChecked = [];
    this.selectedCours.inscriptions.forEach(inscription => {
      if (inscription.status != RegistrationStatus.CANCELLED) {
        this.registrationsChecked.push({
          registration: inscription,
          checked:
            inscription.status == RegistrationStatus.REGISTERED ||
            inscription.status == RegistrationStatus.REGISTERED_BILLED ? true : false
        });
      }
    });
    this.sortRegistrationsChecked();
  }

  sortRegistrationsChecked() {
    this.registrationsChecked.sort((a, b) => a.registration.participant.lastname.localeCompare(b.registration.participant.lastname));
  }

  _closeModal() {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }


  /* Returns true if no registration has been selected, false otherwise */
  isNoRegistrationsChecked(): boolean {
    for (let checkedRegistration of this.registrationsChecked) {
      if (checkedRegistration.checked) {
        return false;
      }
    }
    return true;
  }

  setBtnTitle() {
    this.translateService.get('global.selectAll').subscribe(data1 => {
      this.translateService.get('global.unselectAll').subscribe(data2 => {
        this.selectAllBtnTxt = this.isSelectAll ? data2 : data1;
      });
    })
  }

  onChecked($event: any, object: any) {
    object.checked = !object.checked;
  }

  onAllChecked($event: any) {
    this.registrationsChecked.forEach(o => {
      if (this.isSelectAll) {
        o.checked = false;
      } else {
        o.checked = true;
      }
    });
    this.isSelectAll = !this.isSelectAll;
    this.setBtnTitle();
  }

  onCopyRegistrations() {
    let toExport: Inscription[] = [];
    this.registrationsChecked.forEach(checkedRegistration => {
      if (checkedRegistration.checked) {
        let liteReg = checkedRegistration.registration;
        const inscription = new Inscription();
        inscription.status = RegistrationStatus.PRE_REGISTERED
        inscription.exportStatus = RegistrationExportStatus.NOT_EXPORTED;
        inscription.method = liteReg.method;
        inscription.participant = liteReg.participant;
        toExport.push(inscription);
      }
    });
    if (toExport.length > 0) {
      this.coursService
        .saveRegistrations(this.selectedDestinationCours.id, toExport)
        .subscribe(cours => {
          if (cours != null) {
            this._closeModal();
            this.toasterService.success(this.translateService.instant('toasters.success.coursSuccessfullyCopied'), null, {timeOut: 8000});
          } else {
            this.toasterService.error(this.translateService.instant('toasters.error.coursCopyFailed'));
          }
        });
    } else {
      this.toasterService.error(this.translateService.instant('toasters.error.noRegistrationSelected'));
    }
  }

  onParticipantStateChanged(event: Participant) {
    if (event != null) {
      this.selectedParticipant = event;
      this.isParticipantAddDisabled = false;
      this.selectedPricingMethod = PricingMethod.NORMAL;
    } else {
      this.selectedParticipant = null;
      this.isParticipantAddDisabled = true;
      this.selectedPricingMethod = null;
    }
  }


  onSelectMethod(event: PricingMethod) {
  }


  addRegistration() {
    if (!this.studentAlreadyAdded()) {
      // these fields will be overwritten by the onCopyRegistrations() function.
      // This creates a "fake" registration to display the added user in the table
      let reg = new LiteInscription();
      reg.status = RegistrationStatus.PRE_REGISTERED;
      reg.exportStatus = RegistrationExportStatus.NOT_EXPORTED;
      reg.method = this.selectedPricingMethod;
      reg.participant = this.selectedParticipant;
      this.registrationsChecked.push({
        registration: reg,
        checked: true
      });
    } else {
      this.toasterService.error(this.translateService.instant('toasters.error.clientAlreadyAdded'));
    }


  }

  studentAlreadyAdded(): boolean {
    for (let o of this.registrationsChecked) {
      if (o.registration.participant.id == this.selectedParticipant.id) {
        return true;
      }
    }
    return false;
  }
}
