import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule, DatePipe, KeyValuePipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';

import {AccordionModule} from 'ngx-bootstrap/accordion';
import {AlertModule} from 'ngx-bootstrap/alert';
import {ButtonsModule} from 'ngx-bootstrap/buttons';
import {CarouselModule} from 'ngx-bootstrap/carousel';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ModalModule} from 'ngx-bootstrap/modal';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {ProgressbarModule} from 'ngx-bootstrap/progressbar';
import {RatingModule} from 'ngx-bootstrap/rating';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';

import {MomentModule} from 'ngx-moment';

import {ColorsService} from './colors/colors.service';
import {NowDirective} from './directives/now/now.directive';
import {ScrollableDirective} from './directives/scrollable/scrollable.directive';
import {AddButtonComponent} from './components/add-button/add-button.component';
import {StaticTypeaheadComponent} from './components/static-typeahead/static-typeahead.component';
import {RolePipe} from '../pipes/role.pipe';
import {PriceInputComponent} from './components/price-input/price-input.component';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {CustomCurrencyPipe} from '../pipes/custom-currency.pipe';
import {CheckallDirective} from './directives/checkall/checkall.directive';
import {StopPropagationDirective} from './directives/stop-propagation/stop-propagation.directive';
import {CoursListTableComponent} from './components/cours-list-table/cours-list-table.component';
import {TruncatePipe} from '../pipes/truncate.pipe';
import {ToastrModule} from 'ngx-toastr';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {SortableColumnComponent} from './components/sortable-column/sortable-column.component';

// https://angular.io/styleguide#!#04-10
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    ToastrModule.forRoot(),
    AccordionModule.forRoot(),
    AlertModule.forRoot(),
    ButtonsModule.forRoot(),
    CarouselModule.forRoot(),
    CollapseModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    ProgressbarModule.forRoot(),
    RatingModule.forRoot(),
    TabsModule.forRoot(),
    TimepickerModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    TypeaheadModule.forRoot(),
    MomentModule,
    NgxDatatableModule,
    SweetAlert2Module.forRoot(),
    RouterModule
  ],
  providers: [
    ColorsService
  ],
  declarations: [
    CheckallDirective,
    NowDirective,
    ScrollableDirective,
    StopPropagationDirective,
    AddButtonComponent,
    StaticTypeaheadComponent,
    PriceInputComponent,
    RolePipe,
    CustomCurrencyPipe,
    TruncatePipe,
    CoursListTableComponent,
    SortableColumnComponent
  ],
  exports: [
    DatePipe,
    KeyValuePipe,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    AccordionModule,
    AlertModule,
    ButtonsModule,
    CarouselModule,
    CollapseModule,
    BsDatepickerModule,
    BsDropdownModule,
    ModalModule,
    PaginationModule,
    ProgressbarModule,
    RatingModule,
    TabsModule,
    TimepickerModule,
    TooltipModule,
    PopoverModule,
    TypeaheadModule,
    CheckallDirective,
    NowDirective,
    ScrollableDirective,
    StopPropagationDirective,
    MomentModule,
    AddButtonComponent,
    StaticTypeaheadComponent,
    RolePipe,
    PriceInputComponent,
    NgxDatatableModule,
    CustomCurrencyPipe,
    TruncatePipe,
    CoursListTableComponent,
    ToastrModule
  ]
})

// https://github.com/ocombe/ng2-translate/issues/209
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule
    };
  }
}
