import {Role} from './enums/roles.enum';
import {Langage} from './enums/langage.enum';

export class User {

  private hasRoles: any;

  constructor(
    public id: number,
    public email: string,
    public firstname: string,
    public lastname: string,
    public role: Role,
    public preferredLang: Langage,
    // Impersonate real user
    public realUsername: string,
    // Connection data
    public lock: boolean,
    public otpVerified: boolean,
    public lastConnection: Date,
    public password?: string,
  ) {
    this.hasRoles = {
      [Role.ROOT]: this.isRoot(),
      [Role.ADMIN]: this.isAdmin(),
      [Role.STUDENT]: this.isStudent(),
      [Role.TEACHER]: this.isTeacher(),
    }
  }

  static fromJson(json: any = {}): User {
    return new User(
      json.id,
      json.email,
      json.firstname,
      json.lastname,
      json.role ? json.role : null,
      json.preferredLang,
      json.realUsername,
      json.lock,
      json.otpVerified,
      json.lastConnection ? new Date(json.lastConnection) : null
    );
  }

  getFullname(): String {
    return this.firstname + ' ' + this.lastname;
  }

  isRoot(): boolean {
    return this.role === Role.ROOT;
  }

  isAdmin(): boolean {
    return this.role === Role.ADMIN || this.isRoot();
  }

  isTeacher(): boolean {
    return this.role === Role.TEACHER;
  }

  isStudent(): boolean {
    return this.role === Role.STUDENT;
  }

  hasRole(role: Role | string): boolean {
    // No role => false
    if (!this.role) {
      return false;
    }
    return role ? this.hasRoles[role] : true;
  }

}
