import {Component, OnInit} from '@angular/core';
import {SettingsService} from '../../core/settings/settings.service';
import {ApiService} from 'src/app/services/api.service';
import {ConfigService} from 'src/app/services/config.service';
import {Config} from 'src/app/models/config';

@Component({
  selector: '[app-footer]',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  serverInfo: any;
  config: Config;


  constructor(public settings: SettingsService,
    private apiService: ApiService, private configService: ConfigService) {
  }

  ngOnInit() {
    this.apiService.getApiInfo().subscribe((data: any) => this.serverInfo = data);
    this.configService.getConfig().subscribe(config => {
      this.config = config;
    });
  }
}
