import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-navsearch',
  templateUrl: './navsearch.component.html',
  styleUrls: ['./navsearch.component.scss']
})
export class NavsearchComponent implements OnInit, OnChanges {
  courseId: any;

  @Input() visible: boolean;
  @Output() onclose = new EventEmitter<boolean>();

  constructor(public elem: ElementRef, private router: Router) {}

  ngOnInit() {
    document.addEventListener('keyup', event => {
      if (event.keyCode === 27) {
        // ESC
        this.closeNavSearch();
      }
    });
    document.addEventListener('click', event => {
      const contains =
        this.elem.nativeElement !== event.target &&
        this.elem.nativeElement.contains(event.target);
      if (!contains) {
        this.closeNavSearch();
      }
    });
  }

  closeNavSearch() {
    this.visible = false;
    this.onclose.emit();
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if (changes['visible'].currentValue === true) {
      this.elem.nativeElement.querySelector('input').focus();
    }
  }

  handleForm() {}

  searchCours() {
    this.router.navigate(['/cours', 'find', this.courseId]);
    this.courseId = null;
    this.closeNavSearch();
  }
}
