import {BrowserAnimationsModule} from '@angular/platform-browser/animations'; // this is needed!
import {Injector, LOCALE_ID, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {AppComponent} from './app.component';

import {CoreModule} from './core/core.module';
import {LayoutModule} from './layout/layout.module';
import {SharedModule} from './shared/shared.module';
import {RoutesModule} from './routes/routes.module';
import {JwtModule} from '@auth0/angular-jwt';
import {JwtService} from './auth/jwt.service';
import {ParticipationListComponent} from './routes/participation/participation-list/participation-list.component';
import {SeanceEditComponent} from './routes/seance/seance-edit/seance-edit.component';
import {ParticipantInscriptionComponent} from './routes/participant/inscription/participant-inscription.component';
import localeFr from '@angular/common/locales/fr';
import {registerLocaleData} from '@angular/common';
import {CoursCreateComponent} from './routes/cours/cours-create/cours-create.component';
import {CoursCreateStep1Component} from './routes/cours/cours-create/step1/cours-create-step1.component';
import {defineLocale} from 'ngx-bootstrap/chronos';
import {frLocale} from 'ngx-bootstrap/locale';
import {CoursEditComponent} from './routes/cours/cours-edit/cours-edit.component';
import {CoursArchiveComponent} from './routes/cours/cours-archive/cours-archive.component';
import {CoursCreateStep2Component} from './routes/cours/cours-create/step2/cours-create-step2.component';
import {SeanceCancelComponent} from './routes/seance/seance-cancel/seance-cancel.component';
import {
  CoursCopyRegistrationsComponent
} from './routes/cours/cours-copy-registrations/cours-copy-registrations.component';
import {HttpErrorInterceptor} from './interceptor/httpconfig.interceptor';
import {InscriptionEditComponent} from './shared/components/inscription-edit/inscription-edit.component';
import {CoursChangeTeacherComponent} from './routes/cours/cours-change-teacher/cours-change-teacher.component';
import {
  InscriptionParticipantComponent
} from './routes/cours/cours-details/inscription/inscription-participants.component';
import {SeanceCreateComponent} from './routes/seance/seance-create/seance-create.component';
import {BillingDetailsComponent} from './routes/billing/billing-details/billing-details.component';
import {SpinnerInterceptor} from './core/spinner-interceptor';
import {PagesModule} from './routes/pages/pages.module';

defineLocale('fr', frLocale);

// // https://github.com/ocombe/ng2-translate/issues/218
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// Multi translate loader (not working now)
// export function translateLoader(http: HttpClient) {
//   return new MultiTranslateHttpLoader(http, './assets/i18n/', '.json');
// }

export function tokenGetter() {
  return localStorage.getItem('agc_token');
}

// Special variable used to inject services to classes without setting the Injector in the class' constructor
// https://stackoverflow.com/questions/37482460/getting-instance-of-service-without-constructor-injection
export let AppInjector: Injector;

registerLocaleData(localeFr, 'fr');


@NgModule({
  declarations: [
    AppComponent,
    ParticipationListComponent,
    SeanceEditComponent,
    CoursCreateComponent,
    CoursEditComponent,
    ParticipantInscriptionComponent,
    CoursCreateStep1Component,
    CoursCreateStep2Component,
    CoursArchiveComponent,
    SeanceCreateComponent,
    SeanceCancelComponent,
    CoursCopyRegistrationsComponent,
    InscriptionEditComponent,
    CoursChangeTeacherComponent,
    InscriptionParticipantComponent,
    BillingDetailsComponent
  ],
  imports: [
    HttpClientModule,
    BrowserAnimationsModule, // required for ng2-tag-input
    CoreModule,
    LayoutModule,
    SharedModule.forRoot(),
    RoutesModule,
    PagesModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter
      }
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'fr-FR' }
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(private injector: Injector) {
    AppInjector = this.injector;
  }
}


