import {LiteIntervenant} from './lite-intervenant';
import {Seance} from './seance';

export class Intervenant extends LiteIntervenant {

  public seances: Seance[] = [];

  constructor(data?: any) {
    super(data);
    this.seances = data?.seances?.map(s => Seance.fromJson(s));
  }
}
