<div class="modal-header">
  <h4 class="modal-title pull-left">
    {{ 'cours.create.step' | translate }} {{ currentStep }}/2 -
    {{ 'cours.create.creatingCours' | translate }}
  </h4>
  <button
    (click)="bsModalRef.hide()"
    aria-label="Close"
    class="close pull-right"
    type="button"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form
  #coursCreateForm="ngForm"
  (ngSubmit)="saveCours()"
  novalidate
  role="form"
>
  <div class="modal-body">
    <div *ngIf="currentStep == 1">
      <app-cours-create-step1
        [cours]="cours"
        [teachers]="teachers"
        [coursTypes]="coursTypes"
        [locations]="locations"
        (seancesNbrChange)="seancesNbrChange($event)"
        [nbSeancesToCreate]="nbSeancesToCreate"
      ></app-cours-create-step1>
    </div>
    <div *ngIf="currentStep == 2">
      <app-cours-create-step2
        (seancesUpdate)="updateSeances($event)"
        [cours]="cours"
        [nbSeancesToCreate]="nbSeancesToCreate"
        [intervenants]="teachers"
      ></app-cours-create-step2>
    </div>
  </div>
  <div class="modal-footer">
    <!-- Back button -->
    <button
      *ngIf="currentStep > 1"
      class="mr-1 btn btn-labeled btn-secondary mb-1"
      type="button"
      [disabled]="coursCreateForm.invalid"
      (click)="back()"
    >
      <span class="btn-label"><i class="far fa-arrow-circle-left"></i></span>
      {{ 'global.back' | translate }}
    </button>

    <!-- Cancel button -->
    <button
      class="mr-1 mb-1 btn btn-outline-secondary"
      type="button"
      (click)="bsModalRef.hide()"
    >
      {{ 'global.cancel' | translate }}
    </button>

    <!-- Save or Continue button -->
    <button
      *ngIf="currentStep == 2; else continueButton"
      class="mr-1 btn btn-labeled btn-success mb-1"
      type="submit"
      [disabled]="isSaveDisabled"
    >
      <span class="btn-label"><i class="far fa-save"></i></span>
      {{ 'cours.create.createCours' | translate }}
    </button>
    <ng-template #continueButton>
      <button
        class="mr-1 btn btn-labeled btn-success mb-1"
        type="button"
        [disabled]="coursCreateForm.invalid"
        (click)="nextStep()"
      >
        <span class="btn-label"><i class="far fa-arrow-circle-right"></i></span>
        {{ 'global.continue' | translate }}
      </button>
    </ng-template>
  </div>
</form>
