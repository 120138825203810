<div
  *ngIf="coursExtended.length > 0; else noCours"
  class="table-responsive"
>
  <table class="table table-striped table-hover">
    <thead>
      <tr>
        <th
          agc-sortable-column
          [columnTranslationKey]="'cours.trimester'"
          [currentFieldToSort]="currentFieldToSort"
          [fieldToSort]="'trimester'"
          [sortingAsc]="columnSortDirectionAsc"
          (click)="sort('trimester')"
        ></th>
        <th
          agc-sortable-column
          [columnTranslationKey]="'cours.number'"
          [currentFieldToSort]="currentFieldToSort"
          [fieldToSort]="'number'"
          [sortingAsc]="columnSortDirectionAsc"
          (click)="sort('number')"
        ></th>
        <th
          agc-sortable-column
          [columnTranslationKey]="'cours.type'"
          [currentFieldToSort]="currentFieldToSort"
          [fieldToSort]="'type'"
          [sortingAsc]="columnSortDirectionAsc"
          (click)="sort('type')"
        ></th>
        <th
          agc-sortable-column
          [columnTranslationKey]="'cours.location'"
          [currentFieldToSort]="currentFieldToSort"
          [fieldToSort]="'location'"
          [sortingAsc]="columnSortDirectionAsc"
          (click)="sort('location')"
        ></th>
        <th>
          {{ 'global.day' | translate }}
        </th>
        <th
          agc-sortable-column
          [columnTranslationKey]="'cours.startDateAndHour'"
          [currentFieldToSort]="currentFieldToSort"
          [fieldToSort]="'startDate'"
          [sortingAsc]="columnSortDirectionAsc"
          (click)="sort('startDate')"
        ></th>
        <th>
          {{ 'cours.nbSeances' | translate }}
        </th>
        <th>
          {{ 'cours.nbRegistered' | translate }}
        </th>
        <th
          agc-sortable-column
          (click)="sort('intervenant')"
          [currentFieldToSort]="currentFieldToSort"
          [columnTranslationKey]="'cours.intervenant'"
          [sortingAsc]="columnSortDirectionAsc"
          [fieldToSort]="'intervenant'"
        ></th>

        <!-- Actions are not shown in cours list that is used in presence entries -->
        <th
          *ngIf="
            isCoursList &&
            (selectedStatus == CoursStatus.PLANNED ||
              selectedStatus == CoursStatus.COPIED ||
              selectedStatus == CoursStatus.REGISTRATIONS_OPENED ||
              selectedStatus == CoursStatus.IN_PROGRESS ||
              selectedStatus == CoursStatus.FINISHED)
          "
        >
          {{ 'global.actions' | translate }}
        </th>
      </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let obj of coursExtended">
      <tr (click)="onRowClick(obj.cours)">
        <td>{{ obj.cours.trimester }} ({{ obj.cours.year }})</td>
        <td>
          <a
            stopPropagation
            [routerLink]="['/cours', obj.cours.id]"
            [tooltip]="'tooltips.showCours' | translate"
          >
            {{ obj.cours.number }}
          </a>
        </td>
        <td>{{ obj.cours.coursType.name }}</td>
        <td>{{ obj.cours.location.name }}</td>
        <td *ngIf="obj.cours.startDate; else emptyField">
          {{ obj.cours.startDate | date: 'EEEE' }}
        </td>
        <td *ngIf="obj.cours.startDate; else emptyField">
          {{ obj.cours.startDate | date: 'dd.MM.yyyy' }}
          {{ 'global.at' | translate }}
          {{ obj.cours.startDate | date: 'HH:mm' }}
        </td>
        <td>{{ obj.cours.seancesCount }}</td>
        <td>
            <span [tooltip]="nbRegTemplate" placement="right"
            >{{ obj.nbRegistered }}/{{ obj.cours.nbPlaces }}</span
            >
          <span *ngIf="obj.cours.hasTrialPeopleInFuture" class="text-danger"
                tooltip="Il y a des personnes inscrites à l'essai dans certaines séances.">*</span>
          </td>
        <ng-template #nbRegTemplate>
          {{ 'cours.nbPreRegistered' | translate }}: {{ obj.nbPreRegistered
          }}<br/>
          {{ 'cours.nbWaiting' | translate }}: {{ obj.nbWaiting }}<br/>
        </ng-template>
        <td *ngIf="obj.cours.seancesCount > 0; else emptyField">
          <a
            stopPropagation
            [tooltip]="'tooltips.showTeacher' | translate"
            [routerLink]="['/intervenant', obj.cours.intervenant.id]"
          >
            {{ obj.cours.intervenant?.lastname }}
            {{ obj.cours.intervenant?.firstname }}
          </a>
        </td>
        <!-- Actions are not shown in cours list that is used in presence entries -->
          <td
            *ngIf="
              isCoursList &&
              (selectedStatus == CoursStatus.PLANNED ||
                selectedStatus == CoursStatus.COPIED ||
                selectedStatus == CoursStatus.REGISTRATIONS_OPENED ||
                selectedStatus == CoursStatus.IN_PROGRESS ||
                selectedStatus == CoursStatus.FINISHED)
            "
          >
            <!-- warning triangle for cours that are "REGISTRATIONS_OPENED" and where not all registrations has been billed -->
            <button
              *ngIf="
                (selectedStatus == CoursStatus.REGISTRATIONS_OPENED ||
                  selectedStatus == CoursStatus.IN_PROGRESS) &&
                !obj.allRegBilled
              "
              type="button"
              [tooltip]="unpaid"
              class="custom-transparent custom-no-hover"
            >
              <span class="text-warning"
                ><em class="fa fa-exclamation-triangle"></em
              ></span>
            </button>
            <ng-template #unpaid
            >{{ 'cours.list.table.unbilledReg' | translate }}!
            </ng-template
            >

            <!-- validate cours button for copied cours -->
            <button
              stopPropagation
              (click)="onValidate(obj.cours.id)"
              *ngIf="
                selectedStatus == CoursStatus.COPIED &&
                obj.cours.seancesCount > 0
              "
              type="button"
              [tooltip]="'global.validate' | translate"
              class="custom-transparent"
            >
              <em class="fa fa-check-circle"></em>
            </button>

            <!-- set presence entries button for finished and in progress cours -->
            <!--

            -->

            <button
              stopPropagation
              (click)="onShowPresenceEntries(obj.cours.id)"
              type="button"
              [tooltip]="'cours.list.table.presenceEntries' | translate"
              class="custom-transparent"
            >
              <em class="fal fa-tasks"></em>
            </button>


            <!-- open registrations button for planned cours -->
            <button
              stopPropagation
              (click)="onOpenRegistration(obj.cours)"
              *ngIf="selectedStatus == CoursStatus.PLANNED"
              type="button"
              [tooltip]="'cours.list.table.openReg' | translate"
              class="custom-transparent"
            >
              <em class="fas fa-lock-open"></em>
            </button>
          </td>
        <ng-template #emptyField>
          <td>-</td>
        </ng-template>
      </tr>
    </ng-container
    >
    </tbody>
  </table>
</div>
<ng-template #noCours>{{
  'cours.list.noCoursFound' | translate
  }}</ng-template>
