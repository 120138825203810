import {Component, Input, OnInit} from '@angular/core';

/**
 * This component contains a single button, that should be used wherever an "add" button is needed.
 * The `tip` property will be displayed as a tooltip on the button
 */
@Component({
  selector: 'app-add-button',
  templateUrl: './add-button.component.html',
})
export class AddButtonComponent implements OnInit {
  @Input() tip: string;
  @Input() disabled: boolean;
  @Input() text: string;

  ngOnInit(): void {
  }

}
