import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Config} from '../models/config';
import {ConfigService} from '../services/config.service';
import {formatCurrency} from '@angular/common';

@Pipe({
  name: 'customCurrency'
})
export class CustomCurrencyPipe implements PipeTransform {

  config: Config;
  constructor(private readonly translate: TranslateService, private configService: ConfigService) {
    this.configService.getConfig().subscribe(config => this.config = config);
  }

  transform(value: number): string | null {
    return formatCurrency(
      value,
      'en',
      this.config.currency + ' ',
      this.config.currency,
      '1.2-2'
    );
  }

}
