import {Component, OnInit} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Participant} from 'src/app/models/participant';
import {Subject} from 'rxjs';
import {CoursType} from 'src/app/models/cours-type';
import {CoursTypeService} from 'src/app/services/cours-type.service';
import {CoursStatus} from 'src/app/models/enums/cours-status.enum';
import {LiteLocation} from 'src/app/models/lite-location';
import {RegistrationStatus} from 'src/app/models/enums/registration-status.enum';
import {Inscription} from 'src/app/models/inscription';
import {PricingMethod} from 'src/app/models/enums/pricing-method.enum';
import {TranslateService} from '@ngx-translate/core';
import {Config} from 'src/app/models/config';
import {AgcProfile} from 'src/app/models/enums/agc-profile-enum';
import {ConfigService} from 'src/app/services/config.service';
import {ToastrService} from 'ngx-toastr';
import {RegistrationExportStatus} from 'src/app/models/enums/registration-export-status.enum';
import {LiteCours} from 'src/app/models/lite-cours';
import {CoursService} from "../../../services/cours.service";
import {Cours} from "../../../models/cours";


@Component({
  selector: 'app-student-registration',
  templateUrl: './participant-inscription.component.html',
  styleUrls: ['./participant-inscription.component.scss']
})
export class ParticipantInscriptionComponent implements OnInit {
  currentParticipant: Participant;
  selectedCoursType: CoursType;
  selectedLocation: LiteLocation;
  selectedCours: LiteCours;
  selectedMethod: PricingMethod = PricingMethod.NORMAL;
  selectedStatus: RegistrationStatus = RegistrationStatus.REGISTERED;

  PricingMethod = PricingMethod;

  status: any[] = [
    {
      status: RegistrationStatus.REGISTERED,
      displayedText: 'Inscrit'
    },
    {
      status: RegistrationStatus.WAITING,
      displayedText: "Liste d'attente"
    },
    {
      status: RegistrationStatus.PRE_REGISTERED,
      displayedText: 'Pré-inscrit'
    }
  ];

  registrableCours: LiteCours[] = [];
  registrableCoursCopy: LiteCours[] = [];
  coursTypes: CoursType[] = [];
  onClose: Subject<boolean>;
  locations: LiteLocation[] = [];
  coursFilteredByType: LiteCours[] = [];
  // usually will contain locations filtered by type and location
  coursFilteredByLocation: LiteCours[] = [];

  config: Config;
  AgcProfile = AgcProfile;

  constructor(
    private coursTypeService: CoursTypeService,
    private coursService: CoursService,
    private modalService: BsModalService,
    private bsModalRef: BsModalRef,
    private toasterService: ToastrService,
    private translateService: TranslateService,
    private configService: ConfigService
  ) { }

  ngOnInit() {
    this.onClose = new Subject();
    this.selectedCoursType = null;
    this.selectedLocation = null;
    this.selectedCours = null;
    this.configService.getConfig().subscribe(config => this.config = config);
    this._initList();
  }

  _initList() {
    this.coursService.getAllByStatuses([CoursStatus.REGISTRATIONS_OPENED, CoursStatus.IN_PROGRESS], true).subscribe(cours => {
      this.registrableCours = cours;
      this.registrableCoursCopy = JSON.parse(JSON.stringify(this.registrableCours));
      this.initCoursTypes();
    });
  }

  onCoursTypeChange(event: any) {
    this.registrableCoursCopy = JSON.parse(JSON.stringify(this.registrableCours));
    this.registrableCoursCopy = this.registrableCoursCopy.filter(cours => cours.coursType.id == this.selectedCoursType.id);
    this.locations = [];
    this.groupLocationFromCours();
    this.selectedLocation = null;
    this.selectedCours = null;
  }

  onLocationChange(event: any) {
    this.registrableCoursCopy = this.registrableCours.filter(cours => cours.coursType.id == this.selectedCoursType.id);
    this.registrableCoursCopy = this.registrableCoursCopy.filter(cours => cours.location.id == this.selectedLocation.id);
    this.coursFilteredByLocation = JSON.parse(JSON.stringify(this.registrableCoursCopy)) as LiteCours[];
    if (this.coursFilteredByLocation.length == 1) {
      this.selectedCours = this.coursFilteredByLocation[0];
    } else {
      this.selectedCours = null;
    }
  }

  private isLocationAlreadyInArray(id: number) {
    for (let location of this.locations) {
      if (location.id == id) {
        return true;
      }
    }
    return false;
  }

  close() {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

  groupLocationFromCours() {
    for (let cours of this.registrableCoursCopy) {
      let location = cours.location;
      if (!this.isLocationAlreadyInArray(location.id)) {
        this.locations.push(location);
      }
    }
    this.locations.sort((l1, l2) => {
      return l1.name > l2.name ? 1 : -1;
    });
  }

  saveRegistration() {
    const currentDate = new Date();
    const toSave = new Inscription();
    toSave.status = this.selectedStatus;
    toSave.exportStatus = RegistrationExportStatus.NOT_EXPORTED;
    toSave.method = this.selectedMethod;
    toSave.validationDate = currentDate;
    toSave.participant = this.currentParticipant;

    this.coursService
      .saveRegistrations(this.selectedCours.id, [toSave])
      .subscribe((cours: Cours) => {
        this.toasterService.success(this.currentParticipant.firstname + ' ' + this.currentParticipant.lastname + this.translateService.instant('toasters.success.inscriptionSaved') + cours.coursType.name + ' (n° ' + cours.number + ')');
        this.close();
        // errors are catched by http interceptor
      });
  }

  private initCoursTypes() {
    for (let cours of this.registrableCoursCopy) {
      if (!this.isTypeAlreadyInArray(cours.coursType.id)) {
        this.coursTypes.push(cours.coursType);
      }
    }
    this.coursTypes.sort((l1, l2) => {
      return l1.name > l2.name ? 1 : -1;
    });
  }

  private isTypeAlreadyInArray(id: number) {
    for (let type of this.coursTypes) {
      if (type.id == id) {
        return true;
      }
    }
    return false;
  }
}
