import {Component, Input, OnInit} from '@angular/core';
import {Pricing} from 'src/app/models/pricing';
import {ConfigService} from 'src/app/services/config.service';
import {Config} from 'src/app/models/config';

/**
 * This component contains a single button, that should be used wherever an "add" button is needed.
 * The `tip` property will be displayed as a tooltip on the button
 */
@Component({
  selector: 'app-price-input',
  templateUrl: './price-input.component.html',
  styleUrls: ['./price-input.component.scss']
})
export class PriceInputComponent implements OnInit {

  @Input()
  pricing: Pricing;

  @Input()
  mandatory: boolean = false;

  @Input()
  disabled: boolean = false;

  @Input()
  required: boolean = true;

  @Input()
  placeholderTxt: string;

  config: Config;



  constructor(private configService: ConfigService) {

  }

  ngOnInit(): void {
    this.configService.getConfig().subscribe(config => this.config = config);
  }

}
