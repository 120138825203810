import {LiteParticipation} from './lite-participation';
import {LiteParticipant} from './lite-participant';

export class Participant extends LiteParticipant {

  public participations: LiteParticipation[] = [];

  constructor(data?: any) {
    super(
      data
    );
    if (data) {
      /// verifier ça dgo !
      this.participations = data?.participations?.map(p => LiteParticipation.fromJson(p));
    }
  }

}
