import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Participant} from '../models/participant';

const API_ROUTE = '/api/participant';

@Injectable({
  providedIn: 'root'
})
export class ParticipantService {

  private isDisabledOnly: boolean = false;

  constructor(private readonly http: HttpClient) {
  }

  saveParticipant(particioant: Participant) {
    if (particioant.id) {
      return this.http.put(`${API_ROUTE}/${particioant.id}`, particioant).pipe(map(p => new Participant(p))).pipe(tap(x => this.getAll()));
    } else {
      return this.http.post(API_ROUTE, particioant).pipe(map(p => new Participant(p))).pipe(tap(x => this.getAll()));
    }
  }

  disableParticipant(participantId: number): any {
    return this.http.put(`${API_ROUTE}/${participantId}/disable`, null, {observe: 'response'});
  }

  enableParticipant(participantId: number): any {
    return this.http.put(`${API_ROUTE}/${participantId}/enable`, null, {observe: 'response'});
  }

  getAll(isDisabledOnly: boolean = false): Observable<Participant[]> {
    let params = new HttpParams();
    params = params.set('isDisabledOnly', isDisabledOnly.toString());
    return this.http.get(`${API_ROUTE}`, {params: params}).pipe(map((values: any) => values.map(p => new Participant(p))));
  }

  getParticipantById(participantId: number): Observable<Participant> {
    return this.http.get(`${API_ROUTE}/${participantId}`)
      .pipe(map(p => new Participant(p)));
  }

  getIsDisabledOnly(): boolean {
    return this.isDisabledOnly;
  }

  setIsDisabledOnly(isDisabledOnly: boolean) {
    this.isDisabledOnly = isDisabledOnly;
  }

  toggleMembership(participantId: number): Observable<Participant> {
    return this.http.put(`${API_ROUTE}/${participantId}/membership`, null)
      .pipe(map(p => new Participant(p)));
  }
}
