import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Router} from '@angular/router';
import {CoursStatus} from 'src/app/models/enums/cours-status.enum';
import {CoursEditComponent} from '../../../routes/cours/cours-edit/cours-edit.component';
import {TranslateService} from '@ngx-translate/core';
import {SeanceService} from '../../../services/seance.service';
import {ToastrService} from 'ngx-toastr';
import {SwalService} from '../../../services/swal.service';
import {RegistrationStatus} from "../../../models/enums/registration-status.enum";
import {Cours} from "../../../models/cours";
import {CoursService} from "../../../services/cours.service";

@Component({
  selector: 'app-cours-list-table',
  templateUrl: './cours-list-table.component.html',
  styleUrls: ['./cours-list-table.component.scss']
})
export class CoursListTableComponent {
  @Output()
  refreshList = new EventEmitter<CoursStatus>();

  @Input()
  selectedStatus: CoursStatus;

  @Input()
  public cours: Cours[];

  // contains all the cours with 2 additional fields :
  // [{
  //    cours : Cours,
  //    allRegBilled : boolean // used to display or not the warning icon
  //    nbRegistered : number // used to display the nb of inscriptions
  // }, ...]
  public coursExtended: any[];

  @Input()
    // true if the parent of this component is "CoursListComponent" => means that when we select a cours, we enter to its details
    // false if the parent of this component is "PresenceCoursList" => means that when we select a cours, we enter to presence entries
  isCoursList: boolean;

  CoursStatus = CoursStatus;

  bsModalRef: BsModalRef;

  columnSortDirectionAsc = true;
  currentFieldToSort = 'trimester';

  constructor(
    private coursService: CoursService,
    private modalService: BsModalService,
    private toasterService: ToastrService,
    private router: Router,
    private swalService: SwalService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {}

  sort(fieldToSort) {
    switch (fieldToSort) {
      case 'trimester':
        this.coursExtended = this.coursExtended.sort(
          (a, b) => a.cours.trimester - b.cours.trimester
        );
        break;
      case 'number':
        this.coursExtended = this.coursExtended.sort(
          (a, b) => a.cours.number - b.cours.number
        );
        break;
      case 'type':
        this.coursExtended = this.coursExtended.sort((a, b) =>
          a.cours.coursType.name.localeCompare(b.cours.coursType.name)
        );
        break;
      case 'location':
        this.coursExtended = this.coursExtended.sort((a, b) =>
          a.cours.location.name.localeCompare(b.cours.location.name)
        );
        break;
      case 'startDate':
        this.coursExtended = this.coursExtended.sort(
          (a, b) => a.cours.startDate - b.cours.startDate
        );
        break;
      case 'intervenant':
        this.coursExtended = this.coursExtended.sort((a, b) =>
          a.cours.intervenant.lastname.localeCompare(b.cours.intervenant.lastname)
        );
        break;
      default:
    }
    if (this.currentFieldToSort == fieldToSort) {
      this.columnSortDirectionAsc = !this.columnSortDirectionAsc;
    }
    if (!this.columnSortDirectionAsc) {
      this.coursExtended = this.coursExtended.reverse();
    }
    this.currentFieldToSort = fieldToSort;
  }

  ngOnChanges() {
    this.fillCourssExtended();
  }

  fillCourssExtended() {
    this.coursExtended = [];
    this.cours.forEach(cours => {
      this.coursExtended.push({
        cours: cours,
        allRegBilled: this.areAllRegistrationsBilled(cours),
        nbRegistered: this.calcNbRegistered(cours), // [AGC-34] - requested field
        nbPreRegistered: this.calcNbPreRegistered(cours),
        nbWaiting: this.calcNbWaiting(cours)
      });
    });
  }

  areAllRegistrationsBilled(cours: Cours) {
    for (let inscription of cours.inscriptions) {
      if (inscription.status == RegistrationStatus.REGISTERED) {
        return false;
      }
    }
    return true;
  }

  // method : 0 => delete, 1 => cancel, 2 => open inscription, 3 => reactivate
  _showConfirmationModal(
    cours: Cours,
    component: CoursListTableComponent,
    method: number
  ) {
    switch (method) {
      case 0:
        // [AGC-170] removed delete from this component
        break;
      case 1:
        // [AGC-170] removed cancel from this component
        break;
      case 2:
        this.swalService.warning(
          () => {
            component.openRegistrationConfirmed(cours);
          },
          null,
          'global.warningModal.coursOpenRegistration.text',
          null,
          'global.warningModal.coursOpenRegistration.confirmText'
        );
        break;
      case 3:
        this.swalService.warning(
          () => {
            component.reactivateConfirmed(cours);
          },
          null,
          'global.warningModal.coursReactivated.text',
          null,
          'global.warningModal.coursReactivated.confirmText'
        );
        break;

      default:
        break;
    }
  }

  onEdit(cours: Cours) {
    this._showEditModal(cours);
  }

  onOpenRegistration(cours: Cours) {
    this._showConfirmationModal(cours, this, 2);
  }

  onValidate(coursId: number) {
    this.coursService
      .setStatus(coursId, CoursStatus.PLANNED)
      .subscribe((cours: Cours) => {
        this.refreshList.emit(this.selectedStatus);
        this.toasterService.success(
          this.translateService.instant('toasters.success.coursValidated') +
          ' (n°' +
          cours.number +
          ').'
        );
        // errors are catched by http interceptor
      });
  }

  showDetails(coursId: number) {
    this.router.navigate(['/cours/', coursId]);
  }

  showPresences(coursId: number) {
    this.router.navigate(['/presence/', coursId]);
  }

  onRowClick(cours: Cours) {
    if (this.isCoursList) {
      if (cours.status == CoursStatus.COPIED) {
        this.toasterService.error(
          this.translateService.instant('cours.list.table.cannotShowDetails')
        );
      } else {
        this.showDetails(cours.id);
      }
    } else {
      this.showPresences(cours.id);
    }
  }

  onShowPresenceEntries(coursId: number) {
    this.showPresences(coursId);
  }

  /**
   * Calculates the number of inscription that are "REGISTERED" or "REGISTERED_BILLED" of the specified cours
   */
  private calcNbRegistered(cours: Cours) {
    let nbRegistration = 0;
    for (let inscription of cours.inscriptions) {
      if (
        inscription.status == RegistrationStatus.REGISTERED ||
        inscription.status == RegistrationStatus.REGISTERED_BILLED
      ) {
        nbRegistration++;
      }
    }
    return nbRegistration;
  }

  /**
   * Calculates the number of inscription that are "PRE_REGISTERED" of the specified cours
   */
  private calcNbPreRegistered(cours: Cours) {
    let nbRegistration = 0;
    for (let inscription of cours.inscriptions) {
      if (inscription.status == RegistrationStatus.PRE_REGISTERED) {
        nbRegistration++;
      }
    }
    return nbRegistration;
  }

  private _showEditModal(cours: Cours) {
    const config: any = {class: 'modal-lg'};
    const initialState: any = (config.initialState = {});
    config.backdrop = true;
    config.ignoreBackdropClick = true;
    initialState.cours = cours;
    this.bsModalRef = this.modalService.show(CoursEditComponent, config);
    this.bsModalRef.content.closeBtnName = 'Fermer';
    this.bsModalRef.content.onClose.subscribe(() => {
      // Refresh the list
      this.refreshList.emit(this.selectedStatus);
    });
  }

  /**
   * Calculates the number of inscription that are "WAITING" of the specified cours
   */
  private calcNbWaiting(cours1: Cours) {
    let nbRegistration = 0;
    for (let inscription of cours1.inscriptions) {
      if (inscription.status == RegistrationStatus.WAITING) {
        nbRegistration++;
      }
    }
    return nbRegistration;
  }

  private openRegistrationConfirmed(cours: Cours) {
    cours.status = CoursStatus.REGISTRATIONS_OPENED;
    this.coursService.saveCours(cours).subscribe((cours: Cours) => {
      this.refreshList.emit(this.selectedStatus);
      this.toasterService.success(
        this.translateService.instant(
          'toasters.success.coursOpenRegistrations'
        ) +
        cours.coursType.name +
        ' (n°' +
        cours.number +
        ').'
      );
    });
  }

  private reactivateConfirmed(cours: Cours) {
    this.setCoursStatus(cours);
    this.coursService.saveCours(cours).subscribe((cours: Cours) => {
      this.refreshList.emit(this.selectedStatus);
      let statusTranslated = this.translateService.instant(
        'global.enums.coursStatus.' + cours.status
      );
      this.translateService
        .get('toasters.success.coursReactivated', {
          cours: cours,
          status: statusTranslated
        })
        .subscribe(text => {
          this.toasterService.success(text);
        });
    });
  }

  private setCoursStatus(cours: Cours) {
    // default status when reactivated
    let status = CoursStatus.REGISTRATIONS_OPENED;

    if (cours.seances.length > 0) {
      SeanceService.sortSeances(cours.seances);
      let first = cours.seances[0];
      let last = cours.seances[cours.seances.length - 1];

      let now = new Date();

      // check now if the cours should be FINISHED or IN_PROGRESS instead of waiting on the cron task
      if (last.endDate.getTime() < now.getTime()) {
        status = CoursStatus.FINISHED;
      } else if (first.startDate.getTime() < now.getTime()) {
        status = CoursStatus.IN_PROGRESS;
      }
    } else {
      status = CoursStatus.COPIED;
    }

    cours.status = status;
  }
}
