import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {BsLocaleService} from 'ngx-bootstrap/datepicker';
import {Subject} from 'rxjs';
import {CoursTypeService} from 'src/app/services/cours-type.service';
import {LocationService} from 'src/app/services/location.service';
import {Seance} from 'src/app/models/seance';
import {SeanceStatus} from 'src/app/models/enums/seance-status.enum';
import {LiteIntervenant} from 'src/app/models/lite-intervenant';
import {Animation} from 'src/app/models/animation';
import {Participation} from 'src/app/models/participation';

import {frLocale} from 'ngx-bootstrap/locale';
import {defineLocale} from 'ngx-bootstrap/chronos';
import {TranslateService} from '@ngx-translate/core';
import {CoursService} from "../../../../services/cours.service";
import {Cours} from "../../../../models/cours";

@Component({
  selector: 'app-cours-create-step2',
  templateUrl: './cours-create-step2.component.html',
  styleUrls: ['./cours-create-step2.component.scss']
})
export class CoursCreateStep2Component implements OnInit {

  @Input()
  cours: Cours;

  @Input()
  nbSeancesToCreate: number;

  @Input()
  intervenants: LiteIntervenant[] = [];

  @Output()
  seancesUpdate: EventEmitter<Seance[]> = new EventEmitter<[]>();

  seancesToCreate: Seance[] = [];

  // we don't care about the date, we just need the time
  seancesStartTime: Date = new Date('2000-01-01T18:45:00');
  // add one hour (default seance duration)
  seancesEndTime: Date = new Date(this.seancesStartTime.getTime() + 60 * 60 * 1000);

  seancesTeacher: LiteIntervenant;

  seancesDates: Date[] = [];

  datepickerConfig = {
    containerClass: 'theme-angle',
    dateInputFormat: 'DD.MM.YYYY'
  }

  public onClose: Subject<boolean>;

  constructor(
    public bsModalRef: BsModalRef,
    private coursService: CoursService,
    private coursTypeService: CoursTypeService,
    private locationService: LocationService,
    private translateService: TranslateService,
    private localeService: BsLocaleService
  ) {
  }

  ngOnInit() {
    // set language for the calendar
    // fix for date picker local message not translated
    // https://stackoverflow.com/questions/50415568/how-to-change-message-invalid-date-in-ngx-bootstrap-datepicker-without-being-i/51515852#51515852
    if (this.translateService.currentLang?.startsWith('fr-')) {
      frLocale.invalidDate = 'Date invalide';
      defineLocale('fr', frLocale);
      this.localeService.use('fr');
    }
    this.seancesTeacher = this.cours.intervenant;
    for (let i = 0; i < this.nbSeancesToCreate; i++) {
      this.addSeance();
    }
  }

  createNewDefaultSeance(): Seance {
    let seanceDate = new Date();
    seanceDate.setFullYear(this.cours.year);
    let setParticipations =
      this.cours.seances?.length > 0 &&
      this.cours.seances[0].participations?.length > 0;

    const seance = new Seance();
    seance.startDate = seanceDate;
    seance.status = SeanceStatus.PLANNED;
    seance.participations = this.setExistingParticipations();
    const animation = new Animation();
    animation.intervenant = this.seancesTeacher;
    seance.animations.push(animation);
    seance.paid = false;
    return seance;
  }

  addSeance() {
    const newSeance: Seance = this.createNewDefaultSeance();
    this.seancesToCreate.push(newSeance);
    this.seancesDates.push(newSeance.startDate);
    this.seancesUpdate.emit(this.seancesToCreate);
  }

  /**
   * When the time has been changed, make sure that this time is updated in seance's startDate
   */
  onStartTimeChange(event: Date) {
    if (event != null) {
      let startMinutes = event.getMinutes();
      let startHours = event.getHours();
      for (let seance of this.seancesToCreate) {
        seance.startDate.setMinutes(startMinutes);
        seance.startDate.setHours(startHours);
        seance.startDate.setSeconds(0);
        seance.startDate.setMilliseconds(0);
      }
      this.seancesUpdate.emit(this.seancesToCreate);
    }

  }

  /**
   * When the time has been changed, make sure that this time is updated in seance's endDate
   */
  onEndTimeChange(event: Date) {
    if (event != null) {
      let endMinutes = event.getMinutes();
      let endHours = event.getHours();
      for (let seance of this.seancesToCreate) {
        seance.endDate.setMinutes(endMinutes);
        seance.endDate.setHours(endHours);
        seance.endDate.setSeconds(0);
        seance.endDate.setMilliseconds(0);
      }
      this.seancesUpdate.emit(this.seancesToCreate);
    }

  }

  /**
   * When the intervenant has been changed, make sure that this intervenant is updated in seance's animations
   */
  onTeacherChange(event: LiteIntervenant) {
    for (let seance of this.seancesToCreate) {
      seance.animations[0].intervenant = event;
    }
    this.seancesUpdate.emit(this.seancesToCreate);
  }

  /**
   * Fired when a date is selected in the date picker.
   * @param $event The seleted date
   * @param $index Let us know which seance has to be updated with the selected date.
   */
  onDateChange($event: Date, $index: number) {
    // avoid changes from datepicker initialization
    if ($event != null) {

      const startDate = new Date($event);
      startDate.setHours(this.seancesStartTime.getHours());
      startDate.setMinutes(this.seancesStartTime.getMinutes());
      const endDate = new Date($event);
      endDate.setHours(this.seancesEndTime.getHours());
      endDate.setMinutes(this.seancesEndTime.getMinutes());

      this.seancesToCreate[$index].startDate = startDate;
      this.seancesToCreate[$index].endDate = endDate;
      this.seancesUpdate.emit(this.seancesToCreate);
    }
  }

  private setExistingParticipations(): Participation[] {
    let existing = [];
    if (this.cours?.seances?.length > 0) {
      for (let participation of this.cours.seances[0].participations) {
        existing.push(participation);
      }
    }
    return existing;
  }
}
