<div class="modal-header">
  <h4 class="modal-title pull-left">
    {{ 'cours.details.seances.edit.title' | translate }}
    {{ currentSeance.startDate | date: 'dd.MM.yyyy' }}
    {{ 'global.at' | translate }}
    {{ currentSeance.startDate | date: 'HH:mm' }} -
    {{ 'global.cours' | translate }} "{{ currentCours.coursType.name }}" (n°
    {{ currentCours.number }})
  </h4>
  <button
    (click)="close()"
    aria-label="Close"
    class="close pull-right"
    type="button"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <!-- SEANCE INFO -->
  <div class="row">
    <div class="col-xl-12">
      <h4>{{ 'cours.details.seances.edit.info.title' | translate }}</h4>
    </div>
    <div class="col-xl-12">
      <div class="card card-default">
        <div class="col-xl-12 mb-3">
          <!-- SEANCE DATE -->
          <div class="row form-group">
            <div class="col-xl-6 custom-row custom-margin-top">
              <label class="col-xl-6"
              >{{ 'cours.create.seanceDate' | translate }}:</label
              >
              <div class="col-xl-6">
                <input
                  [bsValue]="dayOfSeance"
                  bsDatepicker
                  [bsConfig]="datepickerConfig"
                  id="seanceDate"
                  class="form-control"
                  (bsValueChange)="onDateChange($event)"
                  name="seanceDate"
                />
              </div>
            </div>
            <div class="col-xl-6 custom-row"></div>
          </div>
          <div class="row form-group">
            <!-- LEFT HEADING -->
            <div class="col-xl-6 custom-row">
              <label class="col-xl-6">
                {{ 'cours.details.seances.list.start' | translate }}:
              </label>
              <div class="col-xl-6">
                <timepicker
                  [showMeridian]="false"
                  [(ngModel)]="seancesStartDateTime"
                  (ngModelChange)="onStartTimeChange($event)"
                ></timepicker>
              </div>
            </div>
            <!-- RIGHT HEADING -->
            <div class="col-xl-6 custom-row">
              <label class="col-xl-6 custom-label"
              >{{ 'cours.details.seances.list.end' | translate }}:</label
              >
              <div class="col-xl-6">
                <timepicker
                  [showMeridian]="false"
                  [(ngModel)]="seancesEndDateTime"
                  (ngModelChange)="onEndTimeChange($event)"
                ></timepicker>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12">
              <button
                class="btn btn-labeled btn-primary"
                type="button"
                [tooltip]="'global.save' | translate"
                placement="right"
                (click)="saveSeance()"
                [disabled]="isSaveEditsDisabled"
              >
                <span class="btn-label"><i class="fal fa-save"></i></span>
                {{ 'global.save' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- TEACHER ANIMATIONS -->
  <div class="row">
    <div class="col-xl-12">
      <h4>{{ 'cours.details.seances.edit.intervenants.title' | translate }}</h4>
    </div>
    <div class="col-xl-12">
      <div class="card card-default">
        <div class="col-xl-12 mb-3">
          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>{{ 'person.lastname' | translate }}</th>
                  <th>{{ 'person.firstname' | translate }}</th>
                  <th>{{ 'person.intervenant.function' | translate }}</th>
                  <th>{{ 'person.isPresent' | translate }}</th>
                  <th>{{ 'global.actions' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngFor="let animation of this.currentSeance.animations"
                >
                  <tr>
                      <td>{{ animation.intervenant.lastname }}</td>
                      <td>{{ animation.intervenant.firstname }}</td>
                      <td *ngIf="animation.intervenant.function; else noFunction">
                          {{ animation.intervenant.function }}
                      </td>
                      <ng-template #noFunction>
                          <td class="unspecified">
                              {{ 'global.unspecified' | translate }}e
                          </td>
                      </ng-template>
                      <td>
                      <span *ngIf="animation.intervenantPresence"
                      >{{
                        'global.enums.intervenantPresence.' +
                        animation.intervenantPresence | translate
                        }}
                      </span>
                        <span *ngIf="!animation.intervenantPresence">{{
                          'person.presences.null' | translate
                          }}</span>
                    </td>
                    <td>
                      <button
                        (click)="deleteAnimation(animation.id)"
                        type="button"
                        class="custom-transparent"
                        [tooltip]="'global.delete' | translate"
                      >
                        <span><em class="fas fa-trash"></em></span>
                      </button>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- SEARCH TEACHER TYPEAHEAD -->
  <div class="row">
    <div class="custom-flex-display custom-max-width">
      <div class="col-xl-12">
        <app-static-typeahead
          (onStateChanged)="intervenantStateChange($event)"
          [dataSource]="intervenants"
          [field]="'search'"
          [placeholderText]="'person.intervenant.search' | translate"
        >
        </app-static-typeahead>
      </div>
    </div>
  </div>
  <!-- ADD ANIMATIONS (intervenant) -->
  <div class="row custom-margin-bottom">
    <div class="custom-flex-column custom-padding-left">
      <button
        class="btn btn-labeled btn-primary"
        type="button"
        [tooltip]="'global.add' | translate"
        placement="right"
        (click)="addAnimation()"
        [disabled]="isTeacherAddDisabled"
      >
        <span class="btn-label"><i class="fal fa-plus"></i></span>
        {{ 'global.add' | translate }}
      </button>
    </div>
  </div>
  <!-- STUDENT PARTICIPATIONS -->
  <div class="row">
    <div class="col-xl-12">
      <h4>
        {{ 'cours.details.seances.edit.participants.title' | translate }}
      </h4>
    </div>
    <div class="col-xl-12">
      <div class="card card-default">
        <div class="col-xl-12 mb-3">
          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>{{ 'person.lastname' | translate }}</th>
                  <th>{{ 'person.firstname' | translate }}</th>
                  <th>{{ 'person.isPresent' | translate }}</th>
                  <th>{{ 'global.actions' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngFor="
                    let participation of this.currentSeance.participations
                  "
                >
                  <tr>
                    <td>{{ participation.participant.lastname }}</td>
                    <td>{{ participation.participant.firstname }}</td>
                    <td *ngIf="participation.participantPresence; else notDefined">
                      {{
                      'global.enums.participantPresence.' +
                      participation.participantPresence | translate
                      }}
                      <button
                        *ngIf="participation.participantPresence == ParticipantPresence.TRIAL"
                        (click)="registerTrialForNextSeances(participation)"
                        type="button"
                        class="custom-transparent"
                        [tooltip]="'cours.details.seances.edit.participants.registerTrial' | translate"
                        >
                          <span><em class="fas fa-share"></em></span>
                        </button>
                    </td>
                    <ng-template #notDefined>
                      <td>{{ 'person.presences.null' | translate }}</td>
                    </ng-template>

                    <td>
                      <button
                        (click)="deleteParticipation(participation.id)"
                        type="button"
                        [tooltip]="'global.delete' | translate"
                        class="custom-transparent"
                      >
                        <span><em class="fas fa-trash"></em></span>
                      </button>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- SEARCH STUDENT TYPEAHEAD -->
  <div class="row">
    <div class="custom-flex-display custom-max-width">
      <div class="col-xl-6">
        <app-static-typeahead
                (onStateChanged)="studentStateChange($event)"
                [dataSource]="participants"
                [field]="'search'"
                [placeholderText]="'person.client.search' | translate"
        >
        </app-static-typeahead>
      </div>
      <div class="checkbox c-checkbox custom-max-width">
        <label class="custom-no-margin-bottom">
          <input
            type="checkbox"
            [checked]="isTrial"
            (change)="onTrialChange($event)"
            disabled
          />
          <span class="fa fa-check"></span
          >{{ 'person.client.isTrial' | translate }}</label
        >
      </div>
    </div>
  </div>
  <!-- ADD PARTICIPATIONS BTN -->
  <div class="row custom-margin-bottom">
    <div class="custom-flex-column custom-padding-left">
      <button
        class="btn btn-labeled btn-primary"
        type="button"
        [tooltip]="'cours.details.seances.edit.participants.add' | translate"
        placement="right"
        (click)="addParticipation()"
        [disabled]="isParticipantAddDisabled"
      >
        <span class="btn-label"><i class="fal fa-plus"></i></span>
        {{ 'cours.details.seances.edit.participants.add' | translate }}
      </button>
      <label *ngIf="isSeanceFull" class="text-danger"
      >{{ 'cours.details.seances.isFull' | translate }} !</label
      >
    </div>
  </div>
  <div class="modal-footer">
    <button
      class="mr-1 mb-1 btn btn-outline-secondary"
      type="button"
      (click)="close()"
    >
      {{ 'global.close' | translate }}
    </button>
  </div>
</div>
