import {LiteInscription} from './lite-inscription';
import {Cours} from './cours';

export class Inscription extends LiteInscription {

  public cours: Cours;

  constructor(json?: any) {
    super(json);
    this.cours = new Cours(json?.cours);
  }

}
