<div *ngIf="config" class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text">{{ config.currency }}</span>
  </div>
  <input
    [(ngModel)]="pricing.price"
    id="{{ pricing.method }}price"
    name="{{ pricing.method }}price"
    class="col-xl-12 form-control"
    type="number"
    [placeholder]="placeholderTxt"
    [attr.required]="required ? '' : null"
    [attr.disabled]="disabled ? '' : null"
  />
</div>
<div
  *ngIf="mandatory && pricing.price == null"
  class="text-danger"
>
  {{ 'form.mandatoryField' | translate }}
</div>
