<div class="modal-header">
  <h4 class="modal-title pull-left">
    {{ 'billing.details.title' | translate }}
  </h4>
  <button
    (click)="bsModalRef.hide()"
    aria-label="Close"
    class="close pull-right"
    type="button"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form
  #teacherEditForm="ngForm"
  (ngSubmit)="saveRegistration()"
  novalidate
  role="form"
>
  <div *ngIf="registrationExtended" class="modal-body">
      <div class="form-group row">
        <label class="col-xl-4 col-form-label" for="cours"
        >{{ 'global.cours' | translate }}:
        </label>
        <div class="col-xl-8">
          <input
            [ngModel]="registrationExtended.coursFormatted"
            class="form-control"
            id="cours"
            name="cours"
            type="text"
            disabled
          />
        </div>
      </div>
      <div class="form-group row">
        <label for="chosenBillingAddress" class="col-xl-4 col-form-label"
          >{{ 'billing.chosenBillingAddress' | translate }}:
        </label>
        <div class="col-xl-8">
          <input
            [(ngModel)]="registrationExtended.billingAddress"
            class="form-control"
            id="chosenBillingAddress"
            name="chosenBillingAddress"
            type="text"
            disabled
          />
        </div>
      </div>
      <div class="form-group row">
        <label for="defaultBillingAddress" class="col-xl-4 col-form-label"
          >{{ 'billing.to.list.defaultBillingAddress' | translate }}:
        </label>
        <div class="col-xl-8">
          <input
            [ngModel]="registrationExtended.participant.billingAddress"
            class="form-control"
            id="defaultBillingAddress"
            name="defaultBillingAddress"
            type="text"
            disabled
          />
        </div>
      </div>
      <div class="form-group row">
        <label for="pricing" class="col-xl-4 col-form-label"
          >{{ 'billing.to.list.clientPricing' | translate }}:
        </label>
        <div class="col-xl-8">
          <input
            [ngModel]="
              'global.enums.pricingMethod.' + registrationExtended.method
                | translate
            "
            class="form-control"
            id="pricing"
            name="pricing"
            type="text"
            disabled
          />
        </div>
      </div>
      <div
        *ngFor="let pricing of registrationExtended.cours.pricings"
        class="form-group row"
      >
        <label for="pricing" class="col-xl-4 col-form-label"
        >{{ 'cours.create.rate' | translate }}
          {{ 'global.enums.pricingMethod.' + pricing.method | translate }}
          {{ 'billing.to.list.ofCours' | translate }}:
        </label>
        <div class="col-xl-8">
          <app-price-input
            [pricing]="pricing"
            [disabled]="true"
          ></app-price-input>
        </div>
      </div>

      <!-- Separator-->
      <br />

    <div class="form-group row">
      <label for="newBillingAddress" class="col-xl-4 col-form-label"
        ><strong>{{ 'billing.newBillingAddress' | translate }}: </strong>
      </label>
      <div class="col-xl-8">
        <input
          [(ngModel)]="selectedBillingAddress"
          class="form-control"
          id="newBillingAddress"
          name="newBillingAddress"
          type="text"
          (ngModelChange)="onBillingAddressChange($event)"
        />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      class="mr-1 mb-1 btn btn-outline-secondary"
      type="button"
      (click)="bsModalRef.hide()"
    >
      {{ 'global.cancel' | translate }}
    </button>
    <button
      class="mr-1 btn btn-labeled btn-success mb-1"
      type="submit"
      [disabled]="saveDisabled"
    >
      <span class="btn-label"><i class="fa fa-save"></i></span>
      {{ 'global.save' | translate }}
    </button>
  </div>
</form>
