import {Component, OnInit} from '@angular/core';
import {SettingsService} from '../../../core/settings/settings.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from 'src/app/auth/auth.service';
import {TranslateService} from '@ngx-translate/core';
import {TranslatorService} from 'src/app/core/translator/translator.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  spinner = false;
  firstTime = true;

  valForm: UntypedFormGroup;

  qrCode: string;
  message: string;

  constructor(public settings: SettingsService,
              private toasterService: ToastrService,
              private router: Router,
              private authService: AuthService,
              fb: UntypedFormBuilder, private translateService: TranslateService, private translatorService: TranslatorService) {

    this.valForm = fb.group({
      'email': [null, [Validators.required, Validators.email]],
      'password': [null, Validators.required],
      'otp': [null]
    });

  }

  submitForm($ev, value: any) {
    $ev.preventDefault();
    for (let c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    if (this.valForm.valid) {
      if (value.otp) {
        this.authService.login(value.email, value.password, value.otp);
      } else {
        this.authService.enabledOtp(value.email).subscribe((enabled: boolean) => {
          if (enabled) {
            this.message = 'Mot de passe unique nécessaire';
          } else {
            this.authService.otp(value.email).subscribe(qrCode => this.qrCode = qrCode);
          }
        })
      }
    }
  }

  ngOnInit() {
    this.translatorService.useLanguage('fr');
    this.authService.getConnectedObservable().subscribe((loggedIn: boolean) => {
      if (loggedIn) {
        if (!this.firstTime) {
          this.toasterService.success(this.translateService.instant('toasters.success.login'));
          this.router.navigateByUrl('/');
        }
      } else {
        this.spinner = false;
        if (!this.firstTime) {
          this.toasterService.error(this.translateService.instant('toasters.error.login'));
        } else {
          this.firstTime = false;
        }
      }
    });
  }

}
