import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {LiteInscription} from '../models/lite-inscription';
import {Inscription} from '../models/inscription';
import {PricingMethod} from '../models/enums/pricing-method.enum';
import {Cours} from '../models/cours';
import {RegistrationExportStatus} from '../models/enums/registration-export-status.enum';

const API_ROUTE = '/api/inscription';

@Injectable({
  providedIn: 'root'
})
export class InscriptionService {

  constructor(private readonly http: HttpClient) {
  }

  editInscription(inscription: LiteInscription): Observable<LiteInscription> {
    return this.http.post(`${API_ROUTE}/${inscription.id}`, inscription).pipe(map(l => new LiteInscription(l)));
  }

  setCancelled(inscriptionId: number): Observable<LiteInscription> {
    return this.http.put(`${API_ROUTE}/${inscriptionId}/cancel`, null).pipe(map(l => new LiteInscription(l)));
  }

  setPreRegistred(inscriptionId: number): Observable<LiteInscription> {
    return this.http.put(`${API_ROUTE}/${inscriptionId}/preregister`, null).pipe(map(l => new LiteInscription(l)));
  }

  setValidated(inscriptionId: number): Observable<LiteInscription> {
    return this.http.put(`${API_ROUTE}/${inscriptionId}/validate`, null).pipe(map(l => new LiteInscription(l)));
  }

  setAllValidated(inscriptionIds: number[]): Observable<LiteInscription[]> {
    return this.http.put(`${API_ROUTE}/validateAll`, inscriptionIds).pipe(map((values: any) => values.map(l => new LiteInscription(l))));
  }

  setBilled(inscriptionId: number): Observable<LiteInscription> {
    return this.http.put(`${API_ROUTE}/${inscriptionId}/bill`, null).pipe(map(l => new LiteInscription(l)));
  }

  setUnbilled(inscriptionId: number): Observable<LiteInscription> {
    return this.http.put(`${API_ROUTE}/${inscriptionId}/unbill`, null).pipe(map(l => new LiteInscription(l)));
  }

  setAllBilled(inscriptionIds: number[]): Observable<LiteInscription[]> {
    return this.http.put(`${API_ROUTE}/billAll`, inscriptionIds).pipe(map((values: any) => values.map(l => new LiteInscription(l))));
  }

  updateAllExportStatus(inscriptionIds: number[], exportStatus: RegistrationExportStatus, billingAddress: string = null): Observable<Inscription[]> {
    return this.http.put(`${API_ROUTE}/updateAllExportStatus`, {
      inscriptionIds: inscriptionIds,
      exportStatus: exportStatus,
      billingAddress: billingAddress
    }).pipe(map((values: any) => values.map(i => new Inscription(i))));
  }

  getInscriptionsFromParticipant(participantId: number): Observable<Inscription[]> {
    return this.http.get(`${API_ROUTE}/participant/${participantId}`).pipe(map((values: any) => values.map(i => new Inscription(i))));
  }

  getInscriptionsToExport(status: RegistrationExportStatus): Observable<Inscription[]> {
    const params = new HttpParams().append('status', status);
    return this.http.get(`${API_ROUTE}/export`, {params: params}).pipe(map((values: any) => values.map(i => new Inscription(i))));
  }

  registerTrialForNextSeances(seanceId: number, participantId: number, method: PricingMethod): Observable<Cours> {
    let body = {
      seanceId: seanceId,
      participantId: participantId,
      method: method
    }
    return this.http.post(`${API_ROUTE}/registerTrialForNextSeances`, body).pipe(map(l => new Cours(l)));
  }

  /**
   * The response is observed in order to get the generated filename which is set in the reponse's headers.
   */
  exportInscriptions(inscriptions: Inscription[]): Observable<HttpResponse<any>> {
    return this.http.post(`${API_ROUTE}/export`, inscriptions.map(value => value.id), {
      responseType: 'blob',
      observe: 'response'
    });
  }

  generateCertificate(inscription: LiteInscription): Observable<HttpResponse<any>> {
    return this.http.get(`${API_ROUTE}/generateCertificate/${inscription.id}`, {
      responseType: 'blob',
      observe: 'response'
    });
  }

  generateLetters(coursId: number, lettersDate: Date, nbDaysDeadline: number): Observable<HttpResponse<any>> {
    let data: any = {
      coursId: coursId,
      lettersDate: lettersDate,
      nbDaysDeadline: nbDaysDeadline
    }
    return this.http.post(`${API_ROUTE}/generateLetters`, data, {responseType: 'blob', observe: 'response'});
  }

  deleteInscription(registration: LiteInscription) {
    return this.http.delete(`${API_ROUTE}/${registration.id}`);
  }
}
