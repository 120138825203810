import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {LiteInscription} from 'src/app/models/lite-inscription';
import {RegistrationStatus} from 'src/app/models/enums/registration-status.enum';
import {InscriptionService} from 'src/app/services/inscription.service';
import {CoursEditComponent} from '../cours-edit/cours-edit.component';
import {WaitingListRegistrationComponent} from './waiting-list-registration/waiting-list-registration.component';
import {InscriptionEditComponent} from 'src/app/shared/components/inscription-edit/inscription-edit.component';
import {saveAs} from 'file-saver';
import {CoursChangeTeacherComponent} from '../cours-change-teacher/cours-change-teacher.component';
import {CoursStatus} from 'src/app/models/enums/cours-status.enum';
import {InscriptionParticipantComponent} from './inscription/inscription-participants.component';
import {TranslateService} from '@ngx-translate/core';
import {SeanceCreateComponent} from '../../seance/seance-create/seance-create.component';
import {SeanceService} from '../../../services/seance.service';
import {ToastrService} from 'ngx-toastr';
import {SwalService} from '../../../services/swal.service';
import {DownloadPreLettersComponent} from '../download-pre-letters/download-pre-letters.component';
import {Config} from '../../../models/config';
import {ConfigService} from '../../../services/config.service';
import {AgcProfile} from 'src/app/models/enums/agc-profile-enum';
import {RegistrationExportStatus} from 'src/app/models/enums/registration-export-status.enum';
import {CoursCopyRegistrationsComponent} from '../cours-copy-registrations/cours-copy-registrations.component';
import {CoursStatusUpdaterComponent} from '../cours-status-updater/cours-status-updater.component';
import {Cours} from '../../../models/cours';
import {CoursService} from '../../../services/cours.service';

@Component({
  selector: 'app-cours-details',
  templateUrl: './cours-details.component.html',
  styleUrls: ['./cours-details.component.scss']
})
export class CoursDetailsComponent implements OnInit {
  config: Config;
  AgcProfile = AgcProfile;

  cours: Cours;
  coursCopy: Cours;
  bsModalRef: BsModalRef;

  inscriptions: LiteInscription[] = [];
  preRegistredRegistrations: LiteInscription[] = [];
  registredAndBilledregistrations: LiteInscription[] = [];
  waitingRegistrations: LiteInscription[] = [];
  cancelledRegistrations: LiteInscription[] = [];

  coursId: number;

  showSeances: boolean = true;

  RegistrationStatus = RegistrationStatus;

  status: RegistrationStatus;

  CoursStatus = CoursStatus;

  static isEditTeacherDisabled: boolean = false;

  constructor(
    private coursService: CoursService,
    private modalService: BsModalService,
    private swalService: SwalService,
    private activatedRoute: ActivatedRoute,
    private inscriptionService: InscriptionService,
    private toasterService: ToastrService,
    private translateService: TranslateService,
    private configService: ConfigService,
    private router: Router
  ) {
  }

  /**
   * workaround to use a static variable in a template.
   * As this function is used in the template, it will be called at each angular cycle.
   * This may cause Angular's ExpressionChangedAfterItHasBeenCheckedError.
   */
  get isEditTeacherDisabled() {
    return CoursDetailsComponent.isEditTeacherDisabled;
  }

  ngOnInit() {
    this.configService.getConfig().subscribe(config => {
      this.config = config;
    });
    this.activatedRoute.paramMap.subscribe(value => {
      this.coursId = +value.get('coursId');
      this._initCours();
    });
  }

  checkTeacherDisabled(): boolean {
    let intervenantId: number = null;
    for (let seance of this.cours.seances) {
      if (seance.animations) {
        for (let animation of seance.animations) {
          if (animation.intervenant == null) {
            return true;
          }
          if (intervenantId == null) {
            intervenantId = animation.intervenant.id;
          } else {
            if (animation.intervenant.id != intervenantId) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  onPreRegValidation(registration: LiteInscription) {
    if (this.coursService.getNbPlacesAvailable(this.cours) == 0) {
      this._showRegisterWaitingConfirmation(registration);
    } else {
      this.validateRegistration(registration);
    }
  }

  onCreate() {
    // with callback to show the Modal after the cours has been loaded
    this._initCours(this._showCreateSeanceModal);
  }

  toggleShowSeances() {
    this.showSeances = !this.showSeances;
    if (this.showSeances) {
      this._initCours();
    }
  }

  editCours() {
    this._showEditModal(this.coursCopy);
  }

  // gets the nb places available of the seance that has the most participations
  getNbPlacesAvailable(): number {
    let nbParticipations = 0;
    for (let seance of this.cours.seances) {
      if (seance.participations.length > nbParticipations) {
        nbParticipations = seance.participations.length;
      }
    }
    return this.cours.nbPlaces - nbParticipations;
  }

  onRegisterBackToPreregistered(registration: LiteInscription) {
    // [AGC-243]
    this.inscriptionService
      .setPreRegistred(registration.id)
      .subscribe((registration: LiteInscription) => {
        this.toasterService.success(
          registration.participant.firstname +
          ' ' +
          registration.participant.lastname +
          this.translateService.instant(
            'toasters.success.inscriptionPreRegistered'
          )
        );
        this._initCours();
      });
  }

  onRegCancel(registration: LiteInscription) {
    if (
      registration.status == RegistrationStatus.REGISTERED ||
      registration.status == RegistrationStatus.PRE_REGISTERED ||
      registration.status == RegistrationStatus.WAITING
    ) {
      this._showConfirmationModal(registration);
    } else {
      this.toasterService.error(
        this.translateService.instant(
          'toasters.error.cannotCancelBilledRegistration'
        )
      );
    }
  }

  validateRegistration(inscription: LiteInscription) {
    this.inscriptionService
      .setValidated(inscription.id)
      .subscribe(($registration: LiteInscription) => {
        this.toasterService.success(
          this.translateService.instant(
            'toasters.success.inscriptionValidated'
          ) +
          inscription.participant.firstname +
          ' ' +
          inscription.participant.lastname +
          '.'
        );
        this._initCours();
      });
  }

  _showConfirmationModal(registration: LiteInscription) {
    this.swalService.warning(
      () => {
        this.updateRegistration(registration);
      },
      null,
      'global.cancel',
      null,
      'global.warningModal.cancelRegistration.confirmText'
    );
  }

  _showRegisterWaitingConfirmation(registration: LiteInscription) {
    this.swalService.warning(
      () => {
        registration.status = RegistrationStatus.WAITING;
        this.inscriptionService.editInscription(registration).subscribe(reg => {
          this.translateService
            .get('cours.details.registrations.waiting.isNow', {
              client: registration.participant
            })
            .subscribe(text => {
              this._initCours();
              this.toasterService.success(text);
            });
        });
      },
      null,
      'global.warningModal.reRegister.text',
      null,
      'global.warningModal.reRegister.confirmText'
    );
  }

  updateRegistration(registration: LiteInscription) {
    // actions on confirm
    this.inscriptionService
      .setCancelled(registration.id)
      .subscribe(($registration: LiteInscription) => {
        this.toasterService.success(
          this.translateService.instant(
            'toasters.success.inscriptionCancelled'
          ) +
          registration.participant.firstname +
          ' ' +
          registration.participant.lastname +
          '.'
        );
        if (
          this.arePendingRegistration() &&
          registration.status == RegistrationStatus.REGISTERED
        ) {
          this._initCours();
        }
      });
  }

  /**
   * Tests if the cours contains pending registrations (registration with status "WAITING")
   */
  arePendingRegistration() {
    for (let registration of this.cours.inscriptions) {
      if (registration.status == RegistrationStatus.WAITING) {
        return true;
      }
    }
    return false;
  }

  onBilling(registration: LiteInscription) {
    this.inscriptionService
      .setBilled(registration.id)
      .subscribe(($registration: LiteInscription) => {
        this.toasterService.success(
          this.translateService.instant('toasters.success.inscriptionBilled') +
          registration.participant.firstname +
          ' ' +
          registration.participant.lastname +
          '.'
        );
        this._initCours();
      });
  }

  listIds(registrations: LiteInscription[]) {
    let registrationsIds = [];
    for (let registration of registrations) {
      if (registration.status != RegistrationStatus.REGISTERED_BILLED) {
        registrationsIds.push(registration.id);
      }
    }
    return registrationsIds;
  }

  onBillingAll() {
    let filteredInscriptions = this.cours.inscriptions.filter(
      reg => reg.status == RegistrationStatus.REGISTERED
    );
    let inscriptionIds = this.listIds(filteredInscriptions);

    this.inscriptionService
      .setAllBilled(inscriptionIds)
      .subscribe(($registrations: LiteInscription[]) => {
        this.toasterService.success(
          this.translateService.instant(
            'toasters.success.allRegistrationsBilled'
          ) +
          this.cours.number +
          '.'
        );
        this._initCours();
      });
  }

  cancelBilling(registration: LiteInscription) {
    if (
      registration.exportStatus == RegistrationExportStatus.ENTERED ||
      registration.exportStatus == RegistrationExportStatus.EXPORTED ||
      registration.exportStatus == RegistrationExportStatus.ARCHIVED
    ) {
      this.toasterService.warning(
        this.translateService.instant('toasters.warning.unbillExportedBill')
      );
    }
    this.inscriptionService
      .setUnbilled(registration.id)
      .subscribe(($registration: LiteInscription) => {
        this.toasterService.success(
          this.translateService.instant(
            'toasters.success.inscriptionUnbilled'
          ) +
          registration.participant.firstname +
          ' ' +
          registration.participant.lastname +
          '.'
        );
        this._initCours();
      });
  }

  onRegistrationEdit(registration: LiteInscription) {
    this._showRegistrationEditModal(registration);
  }

  isDisabled(): boolean {
    for (let registration of this.cours.inscriptions) {
      if (registration.status !== RegistrationStatus.REGISTERED_BILLED) {
        return false;
      }
    }
    return true;
  }

  onDownloadCertificate(registration: LiteInscription) {
    const filename = registration?.participant?.getFullname() + '.pdf';

    this.inscriptionService
      .generateCertificate(registration)
      .subscribe(resp => {
        const blob = new Blob([resp.body], {type: 'application/pdf'});
        saveAs(blob, filename);
        // La vraie date de génération est stockée en base. Ici on met un 'new Date()' temporaire qui permet de changer l'état du bouton PDF dans la page visible sans faire d'appel server juste pour ça.
        registration.certificateGenerationDate = new Date();
      });
  }

  onOpenRegistration() {
    this._showOpenRegConfirmationModal(this);
  }

  onEditTeacherAllSeances() {
    this._showCurrentChangeTeacher(this);
  }

  onCopyCoursRegistrations() {
    this._showCopyRegistrationsModal(this.cours);
  }

  onGenerateParticipations() {
    this.coursService.generateParticipations(this.coursId).subscribe(resp => {
      const filename = 'participations.pdf';
      const blob = new Blob([resp.body], {type: 'application/pdf'});
      saveAs(blob, filename);
    });
  }

  onGeneratePreLetters() {
    const config: any = { class: 'modal-sm' };
    const initialState: any = (config.initialState = {});
    config.backdrop = true;
    config.ignoreBackdropClick = true;
    initialState.coursId = this.coursId;
    this.bsModalRef = this.modalService.show(
      DownloadPreLettersComponent,
      config
    );
    this.bsModalRef.content.onClose.subscribe(result => {
      this.bsModalRef.hide();
    });
  }

  onRegisterParticipants() {
    this._showRegisterModal(this.cours);
  }

  onShowPresenceEntries() {
    this.router.navigate(['/presence/', this.cours.id]);
  }

  onDelete() {
    this._showDeleteConfirmationModal();
  }

  onCancel() {
    this._showCancelConfirmationModal();
  }

  onReactivate() {
    this._showReactivateConfirmationModal();
  }

  statusModal() {
    const config: any = {class: 'modal-lg'};
    const initialState: any = (config.initialState = {});
    config.backdrop = true;
    config.ignoreBackdropClick = true;
    initialState.cours = this.cours;
    this.bsModalRef = this.modalService.show(
      CoursStatusUpdaterComponent,
      config
    );
    this.bsModalRef.content.coursUpdated.subscribe(cours => {
      this._initCours();
    });
  }

  private _showCreateSeanceModal(that) {
    const config: any = {class: 'modal-lg'};
    const initialState: any = (config.initialState = {});
    config.backdrop = true;
    config.ignoreBackdropClick = true;
    initialState.cours = that.cours;
    that.bsModalRef = that.modalService.show(SeanceCreateComponent, config);
    that.bsModalRef.content.closeBtnName = 'Fermer';
    that.bsModalRef.content.onClose.subscribe(() => {
      that._initCours();
    });
  }

  private _showDeleteConfirmationModal() {
    this.swalService.warning(
      () => {
        this.deleteConfirmed();
      },
      null,
      'global.warningModal.coursDelete.text',
      null,
      'global.warningModal.coursDelete.confirmText'
    );
  }

  private _showCancelConfirmationModal() {
    this.swalService.warning(
      () => {
        this.cancelConfirmed();
      },
      null,
      'global.warningModal.coursCancel.text',
      null,
      'global.warningModal.coursCancel.confirmText'
    );
  }

  private _showReactivateConfirmationModal() {
    this.swalService.warning(
      () => {
        this.reactivateConfirmed();
      },
      null,
      'global.warningModal.coursReactivated.text',
      null,
      'global.warningModal.coursReactivated.confirmText'
    );
  }

  private reactivateConfirmed() {
    let coursCopy: Cours = new Cours(this.cours);
    this.setCoursStatus(coursCopy);
    this.coursService.saveCours(coursCopy).subscribe((cours: Cours) => {
      this._initCours();
      let statusTranslated = this.translateService.instant(
        'global.enums.coursStatus.' + cours.status
      );
      this.translateService
        .get('toasters.success.coursReactivated', {
          cours: cours,
          status: statusTranslated
        })
        .subscribe(text => {
          this.toasterService.success(text);
        });
    });
  }

  public static disableTeacherEdit() {
    this.isEditTeacherDisabled = true;
  }

  public static enableTeacherEdit() {
    this.isEditTeacherDisabled = false;
  }

  private deleteConfirmed() {
    const coursId = this.cours.id;
    this.coursService.deleteById(coursId).subscribe(response => {
      if (response.status === 200) {
        this.router.navigate(['/cours']);
        this.toasterService.success(
          this.translateService.instant('toasters.success.coursDeleted')
        );
      }
    });
  }

  private cancelConfirmed() {
    let coursCopy: Cours = JSON.parse(JSON.stringify(this.cours));
    coursCopy.status = CoursStatus.CANCELLED;
    this.coursService.saveCours(coursCopy).subscribe((cours: Cours) => {
      this._initCours();
      this.toasterService.success(
        this.translateService.instant('toasters.success.coursCancelled')
      );
    });
  }

  private _showRegisterModal(currentCours: Cours) {
    const config: any = {class: 'modal-lg'};
    const initialState: any = (config.initialState = {});
    config.backdrop = true;
    config.ignoreBackdropClick = true;
    initialState.currentCours = currentCours;
    this.bsModalRef = this.modalService.show(
      InscriptionParticipantComponent,
      config
    );
    this.bsModalRef.content.onClose.subscribe(result => {
      this._initCours();
    });
  }

  private _showCurrentChangeTeacher(that: any) {
    const config: any = {class: 'modal-lg'};
    const initialState: any = (config.initialState = {});
    config.backdrop = true;
    config.ignoreBackdropClick = true;
    initialState.currentCours = that.cours;
    that.bsModalRef = that.modalService.show(
      CoursChangeTeacherComponent,
      config
    );
    that.bsModalRef.content.closeBtnName = 'Fermer';
    that.bsModalRef.content.onClose.subscribe(() => {
      that._initCours();
    });
  }

  private _showEditModal(cours: Cours) {
    const config: any = {class: 'modal-lg'};
    const initialState: any = (config.initialState = {});
    config.backdrop = true;
    config.ignoreBackdropClick = true;
    initialState.cours = cours;
    this.bsModalRef = this.modalService.show(CoursEditComponent, config);
    this.bsModalRef.content.closeBtnName = 'Fermer';
    this.bsModalRef.content.onClose.subscribe(() => {
      // Refresh the list

      this._initCours();
    });
  }

  private _showWaitingListModal(that: CoursDetailsComponent) {
    const config: any = {class: 'modal-lg'};
    const initialState: any = (config.initialState = {});
    config.backdrop = true;
    config.ignoreBackdropClick = true;
    initialState.waitingList = this.waitingRegistrations;
    initialState.nbPlacesAvailable = that.getNbPlacesAvailable();
    that.bsModalRef = that.modalService.show(
      WaitingListRegistrationComponent,
      config
    );
    that.bsModalRef.content.closeBtnName = 'Fermer';
    that.bsModalRef.content.onClose.subscribe(() => {
      // Refresh the list
      that._initCours();
    });
  }

  private _initCours(callback?: any) {
    const that = this;
    this.coursService.getCours(this.coursId).subscribe(cours => {
      this.cours = cours;
      SeanceService.sortSeances(this.cours.seances);
      this.coursCopy = cours;
      this.inscriptions = cours.inscriptions;
      CoursDetailsComponent.isEditTeacherDisabled = this.checkTeacherDisabled();
      if (typeof callback !== 'undefined') {
        // run the callback if specified
        callback(that);
      }

      this.registredAndBilledregistrations = this.inscriptions.filter(
        reg =>
          reg.status == RegistrationStatus.REGISTERED ||
          reg.status == RegistrationStatus.REGISTERED_BILLED
      );
      this.preRegistredRegistrations = this.inscriptions.filter(
        reg => reg.status == RegistrationStatus.PRE_REGISTERED
      );
      this.waitingRegistrations = this.inscriptions.filter(
        reg => reg.status == RegistrationStatus.WAITING
      );
      this.cancelledRegistrations = this.inscriptions.filter(
        reg => reg.status == RegistrationStatus.CANCELLED
      );
    });
  }

  private _showOpenRegConfirmationModal(component: CoursDetailsComponent) {
    this.swalService.warning(
      () => {
        component.openRegistrationConfirmed();
      },
      null,
      'global.warningModal.coursOpenRegistration.text',
      null,
      'global.warningModal.coursOpenRegistration.confirmText'
    );
  }

  private openRegistrationConfirmed() {
    this.coursCopy.status = CoursStatus.REGISTRATIONS_OPENED;
    this.coursService.saveCours(this.coursCopy).subscribe((cours: Cours) => {
      this.toasterService.success(
        this.translateService.instant(
          'toasters.success.coursOpenRegistrations'
        ) +
        cours.coursType.name +
        ' (n°' +
        cours.number +
        ').'
      );
    });
  }

  private setCoursStatus(cours: Cours) {
    // default status when reactivated
    let status = CoursStatus.REGISTRATIONS_OPENED;

    if (cours.seances.length > 0) {
      SeanceService.sortSeances(cours.seances);
      let first = cours.seances[0];
      let last = cours.seances[cours.seances.length - 1];

      let now = new Date();

      // check now if the cours should be FINISHED or IN_PROGRESS instead of waiting on the cron task
      if (last.endDate.getTime() < now.getTime()) {
        status = CoursStatus.FINISHED;
      } else if (first.startDate.getTime() < now.getTime()) {
        status = CoursStatus.IN_PROGRESS;
      }
    } else {
      status = CoursStatus.COPIED;
    }

    cours.status = status;
  }

  private _showCopyRegistrationsModal(cours: Cours) {
    const config: any = {class: 'modal-lg'};
    const initialState: any = (config.initialState = {});
    config.backdrop = true;
    config.ignoreBackdropClick = true;
    initialState.selectedCours = cours;
    this.bsModalRef = this.modalService.show(
      CoursCopyRegistrationsComponent,
      config
    );
    this.bsModalRef.content.closeBtnName = 'Fermer';
    this.bsModalRef.content.onClose.subscribe(() => {
      this._initCours();
    });
  }

  onParticipationDelete(registration: LiteInscription) {
    this.swalService.warning(
      () => {
        this.inscriptionService.deleteInscription(registration).subscribe(reg => {
          this.toasterService.success('Participant retiré');
          this._initCours();
        });
      },
      null,
      'Supprimer cette participation',
      null,
      'Supprimer'
    );
  }

  private _showRegistrationEditModal(inscription: LiteInscription) {
    const config: any = {class: 'modal-lg'};
    const initialState: any = (config.initialState = {});
    config.backdrop = true;
    config.ignoreBackdropClick = true;
    initialState.inscription = inscription;
    this.bsModalRef = this.modalService.show(InscriptionEditComponent, config);
    this.bsModalRef.content.closeBtnName = 'Fermer';
    this.bsModalRef.content.onClose.subscribe(() => {
      // Refresh the list
      this.toasterService.success(
        this.translateService.instant('toasters.success.inscriptionUpdated')
      );
      this._initCours();
    });
  }

  onAllDownloadCertificate() {
    this.cours.inscriptions.forEach(registration => {
      const filename = registration?.participant?.getFullname() + '.pdf';

      this.inscriptionService
        .generateCertificate(registration)
        .subscribe(resp => {
          const blob = new Blob([resp.body], {type: 'application/pdf'});
          saveAs(blob, filename);
          // La vraie date de génération est stockée en base. Ici on met un 'new Date()' temporaire qui permet de changer l'état du bouton PDF dans la page visible sans faire d'appel server juste pour ça.
          registration.certificateGenerationDate = new Date();
        });
    })


  }
}
