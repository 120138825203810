import {Seance} from './seance';
import {LiteInscription} from './lite-inscription';
import {LiteCours} from './lite-cours';
import {Inscription} from "./inscription";

export class Cours extends LiteCours {

  public seances: Seance[] = [];
  public inscriptions: LiteInscription[] = [];


  constructor(
    data?: any
  ) {
    super(data);
    this.seances = data?.seances ? data.seances.map(seance => new Seance(seance)) : [];
    this.inscriptions = data?.inscriptions ? data.inscriptions.map(reg => new Inscription(reg)) : null;
  }

}
