import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';

export const AGC_TOKEN_NAME = 'agc_token';

@Injectable()
export class JwtService implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem(AGC_TOKEN_NAME);
    if (req.url.includes('api')) {
      if (token) {
        const headers = {
          'Authorization': `Bearer ${token}`
        };

        const clone = req.clone({setHeaders: headers});

        return next.handle(clone);
      } else {
        // /api/lost-password doesn't require token
        return next.handle(req);
      }

    } else {
      return next.handle(req);
    }

  }
}
