import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  private spin = new BehaviorSubject(false);

  constructor() { }

  getSpinnerObservable(): Observable<boolean> {
    return this.spin.asObservable();
  }


  start() {
    this.spin.next(true);
  }

  stop() {
    this.spin.next(false);
  }
}
