<button
  [disabled]="disabled"
  class="btn btn-labeled btn-primary"
  type="button"
  tooltip="{{ tip }}"
  placement="right"
>
  <span class="btn-label"><i class="far fa-plus"></i></span>
  <span *ngIf="text == null; else customText"> {{ 'global.new' | translate }} </span>
  <ng-template #customText>
    <span> {{ text }} </span>
  </ng-template>
</button>
