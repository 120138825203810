import {LiteParticipant} from './lite-participant';
import {LiteParticipation} from './lite-participation';

export class Participation extends LiteParticipation {

  public participant: LiteParticipant;

  constructor(json?: any) {
    super(json);
    this.participant = new LiteParticipant(json.participant);
  }

}
