<!-- LEFT HEADING -->
<fieldset>
  <div class="row form-group">
    <div class="col-xl-6 custom-row">
      <label class="col-xl-6 custom-label"
      >{{ 'cours.create.seancesStart' | translate }}:</label
      >
      <div class="col-xl-6">
        <timepicker
          [showMeridian]="false"
          [(ngModel)]="seancesStartTime"
          name="timepickerStartDate"
          (ngModelChange)="onStartTimeChange($event)"
        ></timepicker>
      </div>
    </div>
    <!-- RIGHT HEADING -->
    <div class="col-xl-6 custom-row">
      <label class="col-xl-6 custom-label"
      >{{ 'cours.create.seancesEnd' | translate }}:</label
      >
      <div class="col-xl-6">
        <timepicker
          [showMeridian]="false"
          [(ngModel)]="seancesEndTime"
          name="timepickerEndDate"
          (ngModelChange)="onEndTimeChange($event)"
        ></timepicker>
      </div>
    </div>

    <div class="col-xl-12 custom-row">
      <label class="col-xl-3 custom-label">{{
        'cours.intervenant' | translate
      }}</label>
      <div class="col-xl-8">
        <select
          #intervenant="ngModel"
          [(ngModel)]="seancesTeacher"
          class="form-control custom-select"
          id="intervenant"
          name="intervenant"
          (ngModelChange)="onTeacherChange($event)"
        >
          <option *ngFor="let intervenant of intervenants" [ngValue]="intervenant"
          ><span *ngIf="intervenant.function; else noFunction">
              {{ intervenant.lastname }} {{ intervenant.firstname }} ({{
            intervenant.function
            }})
            </span>
            <ng-template #noFunction>
              <span>
                {{ intervenant.lastname }} {{ intervenant.firstname }} ({{
                'person.intervenant.noFunctionSpecified' | translate
                }})
              </span>
            </ng-template>
          </option
          >
        </select>
      </div>
    </div>
  </div>
</fieldset>
<div
  *ngFor="let seance of seancesToCreate; let i = index"
  class="row form-group"
>
  <label class="col-xl-3 custom-label">{{ 'cours.create.seanceDate' | translate }} {{ i + 1 }}</label>
  <input
    type="text"
    id="{{ i }}"
    bsDatepicker
    class="form-control col-xl-3"
    #seanceDate="ngModel"
    placeholder="dd.mm.yyyy"
    [(ngModel)]="seancesDates[i]"
    [bsValue]="seancesDates[i]"
    name="seanceDate"
    [bsConfig]="datepickerConfig"
    (bsValueChange)="onDateChange($event, i)"
    required
    placement="right"
  />
</div>
<app-add-button
  (click)="addSeance()"
  [text]="'cours.create.seanceAdd' | translate"
  [tip]="'cours.create.seanceAdd' | translate"
></app-add-button>
