import {LayoutComponent} from '../layout/layout.component';

import {AuthGuard} from '../auth/auth.guard';
import {LoginComponent} from './pages/login/login.component';
import {RecoverComponent} from './pages/recover/recover.component';
import {Routes} from "@angular/router";

export const routes: Routes = [

  {
    path: '',
    component: LayoutComponent,
    children: [
      {path: '', redirectTo: 'home', pathMatch: 'full'},
      {path: 'bexio', loadChildren: () => import('./bexio/bexio.module').then(m => m.BexioModule)},
      {path: 'home', loadChildren: () => import('./cours/cours.module').then(m => m.CoursModules)},
      {
        path: 'cours-type',
        loadChildren: () => import('./cours-type/cours-type.module').then(m => m.CoursTypeModule)
      },
      {path: 'cours', loadChildren: () => import('./cours/cours.module').then(m => m.CoursModules)},
      {path: 'seance', loadChildren: () => import('./seance/seance.module').then(m => m.SeanceModule)},
      {
        path: 'participation',
        loadChildren: () => import('./participation/participation.module').then(m => m.ParticipationModule)
      },
      {
        path: 'participant',
        loadChildren: () => import('./participant/participant.module').then(m => m.ParticipantModule)
      },
      {
        path: 'intervenant',
        loadChildren: () => import('./intervenant/intervenant.module').then(m => m.IntervenantModule)
      },
      {path: 'location', loadChildren: () => import('./location/location.module').then(m => m.LocationModule)},
      {path: 'presence', loadChildren: () => import('./presence/presence.module').then(m => m.PresenceModule)},
      {path: 'stats', loadChildren: () => import('./stats/stats.module').then(m => m.StatsModule)},
      {path: 'billing', loadChildren: () => import('./billing/billing.module').then(m => m.BillingModule)},
      {path: 'profile', loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)},
      {path: 'user', loadChildren: () => import('./user/user.module').then(m => m.UserModule)}
    ],
    canActivate: [AuthGuard]
  },

  // Not lazy-loaded routes
  { path: 'login', component: LoginComponent },
  { path: 'recover', component: RecoverComponent },


  // Not found
  { path: '**', redirectTo: 'home' }

];
