<div class="modal-header">
  <h4 class="modal-title pull-left">
    {{ 'cours.archiveAll.title' | translate }}...
  </h4>
  <button
    (click)="bsModalRef.hide()"
    aria-label="Close"
    class="close pull-right"
    type="button"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form #coursArchive="ngForm" (ngSubmit)="archiveAll()" novalidate role="form">
  <div class="modal-body">
    <div class="form-group row">
      <label for="trimester" class="col-xl-12 col-form-label"
      >{{ 'cours.archiveAll.toDate' | translate }}:
      </label>
      <div class="col-xl-12">
        <input
          type="text"
          bsDatepicker
          class="form-control col-xl-3"
          #seanceDate="ngModel"
          [(ngModel)]="selectedDate"
          [bsValue]="selectedDate"
          name="seanceDate"
          [bsConfig]="datepickerConfig"
          (bsValueChange)="onDateChange($event)"
          placeholder="jj/mm/aaaa"
        />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <!-- Cancel button -->
    <button
      class="mr-1 mb-1 btn btn-outline-secondary"
      type="button"
      (click)="bsModalRef.hide()"
    >
      {{ 'global.cancel' | translate }}
    </button>

    <!-- Save or Continue button -->
    <button
      class="mr-1 btn btn-labeled btn-success mb-1"
      type="submit"
      [disabled]="coursArchive.invalid"
    >
      <span class="btn-label"><i class="far fa-save"></i></span>
      {{ 'cours.archiveAll.archiveAll' | translate }}
    </button>
  </div>
</form>
