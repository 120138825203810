export class LiteLocation {

  public id: number;
  public name: string;
  public address: string;
  public zip: string;
  public location: string;
  public country: string = 'Suisse';
  public capacity: string;
  public reference: string;
  public description: string;
  public pricingInfo: string;

  constructor(
    data?: any
  ) {
    if (data) {
      this.id = data.id;
      this.name = data.name;
      this.address = data.address;
      this.zip = data.zip;
      this.location = data.location;
      this.country = data.country;
      this.capacity = data.capacity;
      this.reference = data.reference;
      this.description = data.description;
      this.pricingInfo = data.pricingInfo;
    }

  }

  static fromJson(json: any = {}): LiteLocation {
    return new LiteLocation(
      json
    );
  }

}
