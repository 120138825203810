<div class="modal-header">
  <h4 class="modal-title pull-left">
    <span *ngIf="!currentSeance.isCancelled()">
      {{ 'cours.details.seances.cancel' | translate }}
    </span>
    <span *ngIf="currentSeance.isCancelled()">
      {{ 'cours.details.seances.reactivate' | translate }}
    </span>
    {{ currentSeance.startDate | date: 'dd.MM.yyyy' }}
    {{ 'global.at' | translate }}
    {{ currentSeance.startDate | date: 'HH:mm' }}
  </h4>
  <button
    (click)="bsModalRef.hide()"
    aria-label="Close"
    class="close pull-right"
    type="button"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-footer">
  <button
    class="mr-1 mb-1 btn btn-outline-secondary"
    type="button"
    (click)="bsModalRef.hide()"
  >
    {{ 'global.cancel' | translate }}
  </button>

  <!-- Save or Continue button -->
  <button
    class="mr-1 btn btn-labeled btn-success mb-1"
    type="button"
    (click)="onStatusChange()"
  >
    <span class="btn-label"><i class="fa fa-save"></i></span>
    {{ 'global.save' | translate }}
  </button>
</div>
