import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';
import {CoursType} from 'src/app/models/cours-type';
import {CoursTypeService} from 'src/app/services/cours-type.service';
import {LocationService} from 'src/app/services/location.service';
import {LiteLocation} from 'src/app/models/lite-location';
import {Pricing} from 'src/app/models/pricing';
import {PricingMethod} from 'src/app/models/enums/pricing-method.enum';
import {LiteIntervenant} from 'src/app/models/lite-intervenant';
import {IntervenantService} from 'src/app/services/intervenant.service';
import {Intervenant} from 'src/app/models/intervenant';
import {Seance} from 'src/app/models/seance';
import {CoursStatus} from 'src/app/models/enums/cours-status.enum';
import {TranslateService} from '@ngx-translate/core';
import {ConfigService} from 'src/app/services/config.service';
import {Config} from 'src/app/models/config';
import {AgcProfile} from 'src/app/models/enums/agc-profile-enum';
import {SeanceService} from '../../../services/seance.service';
import {ToastrService} from 'ngx-toastr';
import {Animation} from 'src/app/models/animation';
import {CoursService} from "../../../services/cours.service";
import {Cours} from "../../../models/cours";

@Component({
  selector: 'app-cours-create',
  templateUrl: './cours-create.component.html',
  styleUrls: ['./cours-create.component.scss']
})
export class CoursCreateComponent implements OnInit {

  title: string;
  closeBtnName: string;
  cours: Cours;
  isUpdate = false;

  coursTypes: CoursType[] = [];
  locations: LiteLocation[] = [];
  teachers: LiteIntervenant[] = [];

  currentStep: number;

  nbSeancesToCreate: number = 9;

  seancesToCreate: Seance[] = [];

  isSaveDisabled: boolean = false;

  public onClose: Subject<boolean>;

  config: Config;
  AgcProfile = AgcProfile;


  constructor(
    public bsModalRef: BsModalRef,
    private toasterService: ToastrService,
    private coursService: CoursService,
    private coursTypeService: CoursTypeService,
    private locationService: LocationService,
    private teacherService: IntervenantService,
    private translateService: TranslateService,
    private configService: ConfigService
  ) {

  }

  ngOnInit() {
    this.configService.getConfig().subscribe(config => this.config = config);
    this.coursTypeService.getAll().subscribe((coursType: CoursType[]) => {
      this.coursTypes = coursType;
    });
    this.locationService.getAll().subscribe(($locations: LiteLocation[]) => {
      this.locations = $locations;
    });
    this.teacherService.getAll().subscribe(($teachers: Intervenant[]) => {
      this.teachers = $teachers.map(i => new LiteIntervenant(i));
    });

    this.currentStep = 1;
    this.onClose = new Subject();

    this.cours = new Cours();
    this.cours.nbPlaces = 20;
    this.cours.status = CoursStatus.PLANNED;
    this.cours.year = new Date().getFullYear();
    this.cours.pricings = [new Pricing(null, 20, PricingMethod.NORMAL, true), new Pricing(null, 15, PricingMethod.AVS, true), new Pricing(null, 10, PricingMethod.STUDENT, true), new Pricing(null, 15, PricingMethod.AI, true)];
  }

  saveCours() {
    let allSeances = [];
    allSeances.push(...this.cours.seances);
    allSeances.push(...this.seancesToCreate);
    if (this.coursService.areSeancesValid(allSeances)) {
      if (this.cours.intervenant) {
        this.removeUnsetAnimations(allSeances);
        this.cours.seances = allSeances;
        SeanceService.sortSeances(this.cours.seances);
        this.coursService.saveCours(this.cours).subscribe((savedCours: Cours) => {
          this._closeModal();
          this.toasterService.success(this.translateService.instant('toasters.success.coursCreated') + ' (n° ' + savedCours.number + ')');
        });
      }
    } else {
      // dates validation error messages are shown by the service
    }
  }

  updateSeances(seancesToCreate: Seance[]) {
    this.seancesToCreate = seancesToCreate;
  }

  private _closeModal() {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

  nextStep() {
    this.currentStep++;
  }

  back() {
    this.currentStep--;
  }

  seancesNbrChange(nbSeancesToCreate: number) {
    this.nbSeancesToCreate = nbSeancesToCreate;
  }

  /**
   * remove animation where the teacher is null. That case can happen if the user add a new animation without specifying a teacher.
   */
  private removeUnsetAnimations(seances: Seance[]) {
    for (let seance of seances) {
      let animationFiltered: Animation[] = [];
      animationFiltered = seance.animations.filter(animation => {
        return animation.intervenant != null;
      });
      seance.animations = animationFiltered;
    }
  }

}
