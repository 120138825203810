import {Person} from './person';

export class LiteIntervenant extends Person {

  public function: string;
  public profAddress: string;
  public profZip: string;
  public profLocation: string;
  public profCountry: string;

  // special field added for research only in Typeahead element.
  // Typeaheads don't allow to search on multiple field so we have to create
  // a search field that will concat teachers's name and firstname (and more fields if wanted in the future)
  public search: string = '';

  constructor(data?: any) {
    super(data);
    this.function = data?.function;
    this.profAddress = data?.profAddress;
    this.profZip = data?.profZip;
    this.profLocation = data?.profLocation;
    this.profCountry = data?.profCountry;
    if (data?.birthDate) {
      let formattedBirthDate = Person.formatBirthDate(data?.birthDate);
      this.search = data?.lastname + ' ' + data?.firstname + ' (' + formattedBirthDate + ')';
    } else {
      this.search = data?.lastname + ' ' + data?.firstname;
    }
  }

}
