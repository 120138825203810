<div class="card border-0" [ngClass]="bgDarker" role="button">
  <div class="row align-items-center mx-0">
    <div class="col-4 text-center">
      <em class="fal fa-sync fa-2x"></em>
    </div>
    <div class="col-8 py-4 rounded-right" [ngClass]="bg">
      <div class="h2 m-0 text-bold">{{ title }}</div>
      <div class="text-uppercase">{{ text }}</div>
    </div>
  </div>
</div>
