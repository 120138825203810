import {PricingMethod} from './enums/pricing-method.enum';

export class Pricing {

  public id: number;
  public price: number;
  public method: PricingMethod;
  public taxIncluded: boolean;

  constructor(
    id: number,
    price: number,
    method: PricingMethod,
    taxIncluded: boolean
  ) {
    this.id = id;
    this.price = price;
    this.method = method;
    this.taxIncluded = taxIncluded;
  }

  static fromJson(json: any = {}): Pricing {
    return new Pricing(
      json.id,
      json.price,
      json.method,
      json.taxIncluded
    );
  }

}
