import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';
import {CoursTypeService} from 'src/app/services/cours-type.service';
import {LocationService} from 'src/app/services/location.service';
import {LiteIntervenant} from 'src/app/models/lite-intervenant';
import {IntervenantService} from 'src/app/services/intervenant.service';
import {Intervenant} from 'src/app/models/intervenant';
import {Seance} from 'src/app/models/seance';
import {TranslateService} from '@ngx-translate/core';
import {ConfigService} from 'src/app/services/config.service';
import {Config} from 'src/app/models/config';
import {AgcProfile} from 'src/app/models/enums/agc-profile-enum';
import {ToastrService} from 'ngx-toastr';
import {Cours} from "../../../models/cours";
import {CoursService} from "../../../services/cours.service";

@Component({
  selector: 'app-seance-create',
  templateUrl: './seance-create.component.html',
  styleUrls: ['./seance-create.component.scss']
})
export class SeanceCreateComponent implements OnInit {
  title: string;
  closeBtnName: string;
  cours: Cours;
  coursCopy: Cours = null;

  intervenants: LiteIntervenant[];

  nbSeancesToCreate: number = 1;

  seancesToCreate: Seance[] = [];

  public onClose: Subject<boolean>;

  config: Config;
  AgcProfile = AgcProfile;

  constructor(
    public bsModalRef: BsModalRef,
    private toasterService: ToastrService,
    private coursService: CoursService,
    private coursTypeService: CoursTypeService,
    private locationService: LocationService,
    private intervenantService: IntervenantService,
    private translateService: TranslateService,
    private configService: ConfigService
  ) { }

  ngOnInit() {
    this.coursCopy = new Cours(this.cours);
    this.configService.getConfig().subscribe(config => {
      this.config = config;
    });
    this.intervenantService.getAll().subscribe((intervenants: Intervenant[]) => {
      this.intervenants = intervenants.map(i => new LiteIntervenant(i));
    });

    this.onClose = new Subject();
  }

  addSeances() {
    let allSeances = [];
    allSeances.push(...this.coursCopy.seances);
    allSeances.push(...this.seancesToCreate);
    if (this.coursService.areSeancesValid(allSeances)) {
      this.coursCopy.seances = allSeances;
      this.sortSeances(this.coursCopy.seances);
      this.coursService
        .addSeances(this.coursCopy.id, this.coursCopy.seances)
        .subscribe((savedCours: Cours) => {
          this._closeModal();
          this.toasterService.success(this.translateService.instant('toasters.success.seancesCreate'));
        });
    }
  }

  updateSeances(seancesToCreate: Seance[]) {
    this.seancesToCreate = seancesToCreate;
  }

  private _closeModal() {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

  seancesNbrChange(nbSeancesToCreate: number) {
    this.nbSeancesToCreate = nbSeancesToCreate;
  }

  // sort seance by startDate
  private sortSeances(seances: Seance[]) {
    seances.sort((a: Seance, b: Seance) => {
      return a.startDate.getTime() - b.startDate.getTime();
    });
  }
}
