<div class="modal-header">
  <h4 class="modal-title pull-left">
    {{ 'cours.details.seances.create.title' | translate }} "{{
    coursCopy.coursType.name
    }}" (n° {{ coursCopy.number }})
  </h4>
  <button
    (click)="bsModalRef.hide()"
    aria-label="Close"
    class="close pull-right"
    type="button"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form
  #seanceCreateForm="ngForm"
  (ngSubmit)="addSeances()"
  novalidate
  role="form"
>
  <div *ngIf="intervenants" class="modal-body">
    <app-cours-create-step2
      (seancesUpdate)="updateSeances($event)"
      [cours]="coursCopy"
      [nbSeancesToCreate]="nbSeancesToCreate"
      [intervenants]="intervenants"
    ></app-cours-create-step2>
  </div>
  <div class="modal-footer">
    <!-- Cancel button -->
    <button
      class="mr-1 mb-1 btn btn-outline-secondary"
      type="button"
      (click)="bsModalRef.hide()"
    >
      {{ 'global.cancel' | translate }}
    </button>

    <!-- Save or Continue button -->
    <button
      class="mr-1 btn btn-labeled btn-success mb-1"
      type="submit"
      [disabled]="seanceCreateForm.invalid"
    >
      <span class="btn-label"><i class="fa fa-save"></i></span>
      {{ 'cours.details.seances.create.createAll' | translate }}
    </button>
  </div>
</form>
