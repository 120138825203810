import {Component, OnInit} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';
import {SeanceStatus} from 'src/app/models/enums/seance-status.enum';
import {SeanceService} from 'src/app/services/seance.service';
import {Seance} from 'src/app/models/seance';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-seance-cancel',
  templateUrl: './seance-cancel.component.html',
  styleUrls: ['./seance-cancel.component.scss']
})
export class SeanceCancelComponent implements OnInit {
  onClose: Subject<boolean>;
  currentSeance: Seance;

  constructor(
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private toasterService: ToastrService,
    private seanceService: SeanceService
  ) {
  }

  ngOnInit() {
    this.onClose = new Subject();
  }

  onStatusChange() {
    const newStatus = this.currentSeance.status == SeanceStatus.CANCELLED ? SeanceStatus.PLANNED : SeanceStatus.CANCELLED;
    this.seanceService
      .setStatus(this.currentSeance.id, newStatus)
      .subscribe((seance: Seance) => {
        this.currentSeance = seance;
        this.onClose.next(true);
        this.bsModalRef.hide();
      });
  }
}
