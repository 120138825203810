import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {SettingsService} from '../../core/settings/settings.service';
import {MenuService} from '../../core/menu/menu.service';
import {AuthService} from '../../auth/auth.service';
import {ApiService} from 'src/app/services/api.service';
import {User} from 'src/app/models/user';
import {TranslatorService} from 'src/app/core/translator/translator.service';
import {Langage} from 'src/app/models/enums/langage.enum';
import {ConfigService} from 'src/app/services/config.service';
import {Config} from 'src/app/models/config';
import {SpinnerService} from '../../services/spinner.service';
import {delay} from "rxjs/operators";


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  showSpinner = false;

  navCollapsed = true; // for horizontal layout
  menuItems = []; // for horizontal layout
  router: Router;

  serverInfo: any;
  isStagingBanner = false;

  user: User;

  availableLangs: any[] = [];

  config: Config;


  isNavSearchVisible: boolean;
  @ViewChild('fsbutton', { static: true }) fsbutton;  // the fullscreen button

  constructor(public menu: MenuService, public settings: SettingsService, public injector: Injector, protected authService: AuthService, private apiService: ApiService, private translatorService: TranslatorService, private configService: ConfigService, private spinnerService: SpinnerService) {

    // show only a few items on demo
    // this.menuItems = menu.getMenu().slice(0, 4); // for horizontal layout

  }

  ngOnInit() {
    this.configService.getConfig().subscribe(config => {
      this.config = config;
    })
    this.authService.getLoggedUser().subscribe((user: User) => this.user = user);
    this.apiService.getApiInfo().subscribe((data: any) => {
      this.serverInfo = data;
      if (data.environment == "ST") {
        this.isStagingBanner = true;
      }
    });
    this.isNavSearchVisible = false;
    if (this.fsbutton) {
      var ua = window.navigator.userAgent;
      if (ua.indexOf("MSIE ") > 0 || !!ua.match(/Trident.*rv\:11\./)) { // Not supported under IE
        this.fsbutton.nativeElement.style.display = 'none';
      }


    }
    this.spinnerService.getSpinnerObservable().pipe(delay(300)).subscribe((spinnerStatus) => this.showSpinner = spinnerStatus);

    this.router = this.injector.get(Router);

    // Autoclose navbar on mobile when route change
    this.router.events.subscribe((val) => {
      // scroll view to top
      window.scrollTo(0, 0);
      // close collapse menu
      this.navCollapsed = true;
    });

  }

  openNavSearch(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setNavSearchVisible(true);
  }

  setNavSearchVisible(stat: boolean) {
    this.isNavSearchVisible = stat;
  }

  getNavSearchVisible() {
    return this.isNavSearchVisible;
  }

  toggleCollapsedSideabar() {
    this.settings.toggleLayoutSetting('isCollapsed');
  }

  isCollapsedText() {
    return this.settings.getLayoutSetting('isCollapsedText');
  }

  selectLang(key: Langage) {
    this.translatorService.useLanguage(key);
    this.user.preferredLang = key;
    this.authService.updateUser(this.user).subscribe();
  }


}
