import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {LiteLocation} from '../models/lite-location';

const API_ROUTE = '/api/location';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private readonly http: HttpClient) {
  }

  saveLocation(location: LiteLocation): Observable<LiteLocation> {
    if (location.id) {
      return this.http.put(`${API_ROUTE}/${location.id}`, location).pipe(map(LiteLocation.fromJson));
    } else {
      return this.http.post(API_ROUTE, location).pipe(map(LiteLocation.fromJson));
    }
  }

  getAll(): Observable<LiteLocation[]> {
    return this.http.get(`${API_ROUTE}`).pipe(map((values: any) => values.map(LiteLocation.fromJson)));
  }

  getLocationById(locationId: number,): Observable<LiteLocation> {
    return this.http.get(`${API_ROUTE}/${locationId}`)
      .pipe(map(LiteLocation.fromJson));
  }

  deleteById(locationId: number) {
    return this.http.delete(`${API_ROUTE}/${locationId}`, { observe: 'response' });
  }

}
