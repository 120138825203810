import {Component, Input} from '@angular/core';
import {Cours} from '../../../../models/cours';
import {CoursStatus} from '../../../../models/enums/cours-status.enum';

@Component({
  selector: 'app-current-status-block',
  templateUrl: './current-status-block.component.html',
  styleUrls: ['./current-status-block.component.scss']
})
export class CurrentStatusBlockComponent {

  @Input()
  status: CoursStatus;

  @Input()
  text: string;

  @Input()
  cours: Cours;

}
