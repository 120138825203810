<!-- START Top Navbar-->
<nav
  class="navbar topnavbar"
  role="navigation"
  [ngClass]="isStagingBanner ? 'custom-staging-nav' : ''"
>
  <!-- START navbar header-->
  <div *ngIf="config" class="navbar-header">
    <a
      *ngIf="settings.getCurrentLayout().isCollapsed; else displayOrganization"
      style="color:white;"
      class="navbar-brand"
      href="/"
    >
      AGC
    </a>
    <ng-template #displayOrganization>
      <a style="color:white;" class="navbar-brand" href="/">
        <span *ngIf="!isStagingBanner">AGC -  {{ config?.organization }}</span>
        <span *ngIf="isStagingBanner">[STAGING] {{ config?.organization }}</span>
      </a></ng-template>
  </div>
  <!-- END navbar header-->
  <!-- START Left navbar-->
  <ul class="navbar-nav mr-auto flex-row">
    <li class="nav-item">
      <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops-->
      <a
        class="nav-link d-none d-md-block d-lg-block d-xl-block"
        trigger-resize=""
        (click)="toggleCollapsedSideabar()"
        *ngIf="!isCollapsedText()"
      >
        <em class="fas fa-bars"></em>
      </a>

      <!-- Button to show/hide the sidebar on mobile. Visible on mobile only.-->
      <a
        class="nav-link sidebar-toggle d-md-none"
        (click)="
          settings.toggleLayoutSetting('asideToggled'); $event.stopPropagation()
        "
      >
        <em class="fas fa-bars"></em>
      </a>
    </li>

    <li class="nav-item">
      <a class="nav-link" (click)="openNavSearch($event)">
        <em class="fas fa-search"></em>
      </a>
    </li>

    <li class="nav-item" *ngIf="showSpinner">
      <a class="nav-link">
        <em class="fal fa-spinner-third fa-spin"></em> {{ 'global.loading' | translate}}
      </a>
    </li>
  </ul>
  <!-- END Left navbar-->
  <!-- START Right Navbar-->

  <ul class="navbar-nav flex-row">
    <li class="nav-item dropdown dropdown-list" dropdown>
      <a
        class="nav-link dropdown-toggle"
        dropdownToggle
        style="cursor: pointer"
      >
        {{ user?.getFullname() }}
      </a>
      <!-- START Dropdown menu-->
      <div *dropdownMenu class="dropdown-menu dropdown-menu-right">
        <div class="dropdown-item">
          <!-- START list group-->
          <div class="list-group">
            <!-- list item-->
            <div class="list-group-item list-group-item-action">
              <div class="media">
                <div class="align-self-start mr-2"></div>
                <div class="media-body">
                  <p class="m-0">{{ user.role | role }}</p>
                  <p class="m-0 text-muted text-sm">
                    {{ 'header.yourRole' | translate }}
                  </p>
                </div>
              </div>
            </div>
            <!-- list item-->
            <div class="list-group-item list-group-item-action">
              <div class="media">
                <div class="align-self-start mr-2">
                  <em class="fa fa-user fa-2x text-primary"></em>
                </div>
                <div class="media-body">
                  <p
                    [routerLink]="['/profile']"
                    class="m-0"
                    style="cursor: pointer"
                  >
                    Profil
                  </p>
                </div>
              </div>
            </div>
            <!-- last list item-->
            <div class="list-group-item list-group-item-action">
              <div class="media">
                <div class="align-self-start mr-2">
                  <em class="fa fa-sign-out fa-2x text-danger"></em>
                </div>
                <div class="media-body">
                  <p
                    (click)="authService.logout()"
                    class="m-0"
                    style="cursor: pointer"
                  >
                    {{ 'header.disconnect' | translate }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- END list group-->
        </div>
      </div>
      <!-- END Dropdown menu-->
    </li>
  </ul>

  <!-- END Right Navbar-->
  <app-navsearch
    [visible]="getNavSearchVisible()"
    (onclose)="setNavSearchVisible(false)"
  ></app-navsearch>
</nav>
<!-- END Top Navbar-->
