import {Component, Input, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {BsLocaleService} from 'ngx-bootstrap/datepicker';
import {Subject} from 'rxjs';
import {RegistrationStatus} from 'src/app/models/enums/registration-status.enum';
import {PricingMethod} from 'src/app/models/enums/pricing-method.enum';
import {InscriptionService} from 'src/app/services/inscription.service';
import {LiteInscription} from 'src/app/models/lite-inscription';

@Component({
  selector: 'app-inscription-edit',
  templateUrl: './inscription-edit.component.html',
  styleUrls: ['./inscription-edit.component.scss']
})
export class InscriptionEditComponent implements OnInit {

  @Input()
  inscription: LiteInscription;

  inscriptionCopy: LiteInscription;

  PricingMethod = PricingMethod;

  inscriptionStatus = RegistrationStatus;

  isSaveDisabled = true;

  datepickerConfig = {
    containerClass: 'theme-angle',
    dateInputFormat: 'DD.MM.YYYY'
  };

  public onClose: Subject<boolean> = new Subject();

  constructor(
    public bsModalRef: BsModalRef,
    private localeService: BsLocaleService,
    private inscriptionService: InscriptionService
  ) {

  }

  ngOnInit() {
    // set langage for the calendar
    this.localeService.use('fr');
    this.inscriptionCopy = JSON.parse(JSON.stringify(this.inscription));
  }

  saveInscription() {
    if (this.isRegistrationValid()) {
      this.inscriptionService.editInscription(this.inscriptionCopy).subscribe(($inscription: LiteInscription) => {
        this._closeModal();
      });
    }
  }

  isRegistrationValid() {

    // validation tests
    // ...

    return true;
  }

  onMethodChange($event) {
    if (this.inscriptionCopy.method != this.inscription.method) {
      this.isSaveDisabled = false;
    } else {
      this.isSaveDisabled = true;
    }
  }

  _closeModal() {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }
}
