import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Langage} from 'src/app/models/enums/langage.enum';

@Injectable()
export class TranslatorService {

  private availablelangs = [
    {code: Langage.fr, text: 'Français'}, // default
    {code: Langage.en, text: 'English'}
  ];

    constructor(public translate: TranslateService) {
        if (!translate.getDefaultLang())
          translate.setDefaultLang('fr');
        this.useLanguage();
    }

    useLanguage(lang: string = null) {
        this.translate.use(lang || this.translate.getDefaultLang()).subscribe(data => console.log("Now using langage: " + this.translate.currentLang));
    }

    getAvailableLanguages() {
        return this.availablelangs;
    }

}
