import {LiteCours} from './lite-cours';
import {SeanceStatus} from './enums/seance-status.enum';

export class LiteSeance {

  public id: number;
  public startDate: Date;
  public endDate: Date;
  public status: SeanceStatus;
  public cours: LiteCours;
  public paid: boolean;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.startDate = data.startDate ? new Date(data.startDate) : null;
      this.endDate = data.endDate ? new Date(data.endDate) : null;
      this.status = data.status;
      this.cours = data.cours;
      this.paid = data.paid;
    }
  }

  static fromJson(json: any = {}): LiteSeance {
    return new LiteSeance(
      json
    );
  }

}
