<div class="wrapper">
  <div class="block-center mt-4 wd-xl">
    <!-- START card-->
    <div class="card card-flat">
      <div class="card-header text-center">
        <img alt="Image" class="block-center" src="assets/img/square.png"/>
      </div>
      <div class="card-body">
        <p class="text-center py-2">{{ 'login.lostPassword' | translate }}</p>
        <form
          (submit)="submitForm(email.value)"
          class="form-validate"
          name="recoverForm"
          novalidate=""
          role="form"
        >
          <!--                    <p class="text-center">Fill with your mail to receive instructions on how to reset your password.</p>-->
          <div class="form-group">
            <!--                        <label class="text-muted">{{ 'login.emailPlaceholder' | translate }}</label>-->
            <div class="input-group with-focus">
              <input
                #email
                autocomplete="off"
                class="form-control border-right-0"
                name="email"
                placeholder="{{ 'login.emailPlaceholder' | translate }}"
                type="email"
              />
              <div class="input-group-append">
                <span
                  class="input-group-text text-muted bg-transparent border-left-0"
                >
                  <em class="fal fa-envelope"></em>
                </span>
              </div>
            </div>
          </div>
          <button [disabled]="spinner" class="btn btn-block btn-info" type="submit">
            <em *ngIf="spinner" class="fal fa-spin fa-spinner"></em>
            Envoyer le nouveau mot de passe
          </button>
        </form>
      </div>
    </div>
    <a [routerLink]="['/login']">Connexion</a>
    <!-- END card-->
    <div class="p-3 text-center">
      <span>&copy;</span>
      <span>{{ settings.getAppSetting('year') }}</span>
      <span class="mx-2">-</span>
      <span>{{ settings.getAppSetting('name') }}</span>
      <br />
      <span>{{ settings.getAppSetting('description') }}</span>
    </div>
  </div>
</div>
