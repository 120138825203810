import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Participant} from 'src/app/models/participant';
import {Subject} from 'rxjs';
import {Inscription} from 'src/app/models/inscription';
import {PricingMethod} from 'src/app/models/enums/pricing-method.enum';
import {ParticipantService} from 'src/app/services/participant.service';
import {RegistrationStatus} from 'src/app/models/enums/registration-status.enum';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {SwalService} from '../../../../services/swal.service';
import {RegistrationExportStatus} from 'src/app/models/enums/registration-export-status.enum';
import {CoursService} from "../../../../services/cours.service";
import {Cours} from "../../../../models/cours";

@Component({
  selector: 'app-inscription-participants',
  templateUrl: './inscription-participants.component.html',
  styleUrls: ['./inscription-participants.component.scss']
})
export class InscriptionParticipantComponent implements OnInit {

  onClose: Subject<boolean>;
  currentCours: Cours;

  participants: Participant[];

  isWaitingParticipantAddDisabled = true;

  selectedStudent: Participant;
  selectedPricingMethod: PricingMethod;

  selectedWaitingStudent: Participant;
  selectedWaitingPricingMethod: PricingMethod;

  inscriptionsToSave: Inscription[] = [];

  RegistrationStatus = RegistrationStatus;
  PricingMethod = PricingMethod;

  constructor(
    private coursService: CoursService,
    public bsModalRef: BsModalRef,
    private swalService: SwalService,
    private toasterService: ToastrService,
    private participantService: ParticipantService,
    private translateService: TranslateService,
  ) {
  }

  ngOnInit() {
    this.participantService.getAll().subscribe((data: Participant[]) => {
      this.participants = data;
      this.participants.sort((a, b) => a.lastname.localeCompare(b.lastname, 'de', {sensitivity: 'base'}))
    });
    this.onClose = new Subject();
    this.currentCours.inscriptions.sort((a, b) => a.participant.lastname.localeCompare(b.participant.lastname));
  }

  onParticipantStateChanged(event) {
    this.selectedStudent = event;

  }

  onWaitingParticipantStateChanged(event) {
    this.selectedWaitingStudent = event;
  }

  addRegistration(status: RegistrationStatus) {
    let currentDate = new Date();
    let pricingMethod;
    let student;
    switch (status) {
      case RegistrationStatus.REGISTERED:
        pricingMethod = this.selectedPricingMethod;
        student = this.selectedStudent;
        break;
      case RegistrationStatus.WAITING:
        pricingMethod = this.selectedWaitingPricingMethod;
        student = this.selectedWaitingStudent;
        break;
      default:
        break;
    }
    if (this.canStudentBeRegistered(student, status)) {
      let newInscription = new Inscription();
      newInscription.status = status;
      newInscription.exportStatus = RegistrationExportStatus.NOT_EXPORTED;
      newInscription.cours = this.currentCours;
      newInscription.method = pricingMethod;
      newInscription.validationDate = currentDate;
      newInscription.participant = student;
      this.inscriptionsToSave.push(newInscription);
    }
  }

  onRemove(registration: Inscription) {
    this.swalService.warning(() => {
      this.inscriptionsToSave = this.inscriptionsToSave.filter(obj => obj !== registration);
    }, null, 'Supprimer le participant', null, 'global.warningModal.confirmText');
  }


  canStudentBeRegistered(student: Participant, registrationStatus) {
    if (this.isCoursFull()) {
      if (registrationStatus == RegistrationStatus.REGISTERED) {
        this.toasterService.error(this.translateService.instant('toasters.error.coursIsFull'));
        return false;
      }
    }
    for (let registrationToSave of this.inscriptionsToSave) {
      if (registrationToSave.participant.id == student.id) {
        // [AGC-93] If a student already added in waiting list is added again, the error message is more accurate.
        if (registrationToSave.status == RegistrationStatus.WAITING) {
          this.toasterService.error(this.translateService.instant('toasters.error.clientAlreadyAddedWaiting'));
        } else {
          this.toasterService.error(this.translateService.instant('toasters.error.clientAlreadyAdded'));
        }
        return false;
      }
    }

    for (let existing of this.currentCours.inscriptions) {
      if (existing.participant.id == student.id) {
        this.toasterService.error(this.translateService.instant('toasters.error.clientAlreadyRegistered'));
        return false;
      }
    }
    return true;
  }

  isCoursFull() {
    let registrationsWithoutWaiting = this.currentCours.inscriptions.filter(reg => reg.status != RegistrationStatus.WAITING);
    let registrationsWithoutCancelled = registrationsWithoutWaiting.filter(reg => reg.status != RegistrationStatus.CANCELLED);
    let registrationsToSaveWithoutWaiting = this.inscriptionsToSave.filter(reg => reg.status != RegistrationStatus.WAITING);
    return (this.currentCours.nbPlaces - registrationsWithoutCancelled?.length - registrationsToSaveWithoutWaiting?.length) <= 0;
  }



  saveRegistrations() {
    this.coursService.saveRegistrations(this.currentCours.id, this.inscriptionsToSave).subscribe(data => {
      if (data.id) {
        this.toasterService.success(this.translateService.instant('toasters.success.inscriptionsSaved'));
        this.closeModalActions();
      } else {
        this.toasterService.error(this.translateService.instant('toasters.error.inscriptionsNotSaved'));
      }
    });
  }

  closeModal() {
    this.swalService.warning(() => {
      this.closeModalActions();
    }, 'global.warningModal.closeRegisterParticipants.title', 'global.warningModal.closeRegisterParticipants.text', null, 'global.warningModal.closeRegisterParticipants.confirmText');
  }

  closeModalActions() {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

}
