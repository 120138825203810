<div class="modal-header">
  <h4 class="modal-title pull-left">
    {{ 'cdetails.inscriptions.edit.title' | translate }}
  </h4>
  <button
    (click)="bsModalRef.hide()"
    aria-label="Close"
    class="close pull-right"
    type="button"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form
  #inscriptionEditForm="ngForm"
  (ngSubmit)="saveInscription()"
  novalidate
  role="form"
>
  <div class="modal-body">
    <div class="alert alert-info" role="alert">
      <i class="fal fa-info-square"></i>
      {{ 'form.indicatesRequiredFields' | translate }}
    </div>
    <!-- required fields -->
    <div class="form-group row">
      <label for="status" class="col-xl-3 col-form-label  required">{{
        'cours.status' | translate
      }}</label>
      <div class="col-xl-9">
        <input
          #status="ngModel"
          [ngModel]="
            'global.enums.registrationStatus.' + inscriptionCopy.status
              | translate
          "
          class="form-control"
          id="status"
          name="status"
          required
          type="text"
          [placeholder]="'cours.status' | translate"
          disabled
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="method" class="col-xl-3 col-form-label  required">{{
        'cours.create.rate' | translate
      }}</label>
      <div class="col-xl-9">
        <select
          #method="ngModel"
          [(ngModel)]="inscriptionCopy.method"
          class="form-control custom-select"
          id="method"
          name="method"
          (ngModelChange)="onMethodChange($event)"
        >
          <option
            *ngFor="let method of PricingMethod | keyvalue"
            [ngValue]="method.value"
          >
            {{
              'global.enums.pricingMethod.' + method.value | translate
            }}</option
          >
        </select>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      class="mr-1 mb-1 btn btn-outline-secondary"
      type="button"
      (click)="bsModalRef.hide()"
    >
      {{ 'global.cancel' | translate }}
    </button>
    <button
      class="mr-1 btn btn-labeled btn-success mb-1"
      type="submit"
      [disabled]="isSaveDisabled"
    >
      <span class="btn-label"><i class="fa fa-save"></i></span>
      {{ 'global.save' | translate }}
    </button>
  </div>
</form>
